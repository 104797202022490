import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/brand/logo-resize.png";

interface FormState {
  redirect: string | null;
  email: string;
  token: string;
  name:string;
  errors: {
    email: string;
  };
}
class EmailActivation extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      redirect: null,
      token: window.location.href.split("=")[1],
      email: "",
      name:localStorage.getItem("action") || "",
      errors: {
        email: "",
      },
    };
  }


  componentDidMount() {
    this.activteAccount();
  }

  activteAccount() {
    const { token , name } = this.state;
    if(token && name){
    fetch("https://api.lotusppc.com/Users/ActivateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, name }),
    })
      .then((response) => response.json())
      .then((responceData) => {
        if (responceData === "User account activated successfully") {
          toast("You have successfully activated your email")
        } else {
          toast(" Email activation failed ")
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Something went wrong")
      });
    }
  }



  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    
    return (
      <DefaultLayout>
        <div className="contanier-type1">
          <form>
          <div className="text-center">
              <img src={logo} alt="logo" />
            </div>
            <div className="mb-3">
              <h3>You have successfully activated your email.</h3>
              <p>Please click the link below to proceed.</p>
              <p>
                <Link to={"/sign-in"}>click to Login</Link>
              </p>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}
export default EmailActivation;
