// faqSideTab.tsx
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./faqSideTab.css";

interface FaqSideTabProps {
  setFaqData: (
    data: any,
    category: string,
    totalPages: number,
    currentPage: number
  ) => void;
}

const FaqSideTab: React.FC<FaqSideTabProps> = ({ setFaqData }) => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const handleNavLinkClick = async (
    category: string,
    categoryIcon: React.ReactElement
  ) => {
    const apiUrl = `https://api.lotusppc.com/FAQ?Filters.Category=${encodeURIComponent(
      category
    )}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      const faqData = data.result.data || [];
      setFaqData(
        faqData,
        category,
        data.result.lastPage || 1,
        data.result.currPage || 1
      );

      // Move navigation and setActiveCategory inside the try block
      navigate(`/faq?category=${encodeURIComponent(category)}`);
      setActiveCategory(category);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  return (
    <div className="faq-side-tab">
      <ul>
        {[
          {
            category: "Products & Services",
            icon: <i className="faq-product-icon" />,
          },
          { category: "Get Started", icon: <i className="faq-flag-icon" /> },
          {
            category: "Subscription & Payment",
            icon: <i className="faq-receipt-icon" />,
          },
          {
            category: "Data Security",
            icon: <i className="faq-security-icon" />,
          },
          {
            category: "Troubleshooting",
            icon: <i className="faq-setting-icon" />,
          },
        ].map((item) => (
          <li key={item.category}>
            <NavLink
              to=""
              onClick={() => handleNavLinkClick(item.category, item.icon)}
              className={`faq-side-tab${
                activeCategory === item.category ? " is active" : ""
              }`}
            >
              {item.icon}
              {item.category}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FaqSideTab;
