import { LinearProgress } from "@mui/material";
import React from "react";

interface props {
  data: any;
}
const Block: React.FunctionComponent<props> = (props: any) => {
  const { data } = props;
  const keyword = data?.map((item: any, index: number) => {
    return (
      <div key={index} className="block-header">
        <div>
          <div>{item.lable}</div>
          <div>{item.range}</div>
        </div>
        <div>
          {item.data.map((dataItem: any, dataIndex: any) => (
            <div
              key={dataIndex}
              className="d-flex justify-content-between align-items-center"
            >
              <div>
                <LinearProgress
                  variant="determinate"
                  value={dataItem.value}
                  style={{ backgroundColor: dataItem.paletteLight }}
                  className="progressBar"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: dataItem.paletteDark,
                    },
                  }}
                />
              </div>
              <div className="d-flex align-items-center gap-1">
                <div
                  className="box-color"
                  style={{ backgroundColor: dataItem.paletteDark }}
                ></div>
                {dataItem.valueData}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  });
  return <div className="block-card">{keyword}</div>;
};

export default Block;
