import React, { useState, useEffect, useRef } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import ReactCountryFlag from "react-country-flag";
let emailId;
const DropdownBasic = ({
  options,
  value,
  onChange,
  onChangeHandler,
  defaultValue,
}) => {
  const [dropdownWidth, setDropdownWidth] = useState("200px");
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDownMenu(false);
    }
  };
  const showHideDropDownMenu = (event) => {
    event.stopPropagation();
    setShowDropDownMenu(!showDropDownMenu);
  };
  return (
    <div className="DateRangeFilter">
      <div className="time-line" style={{ width: dropdownWidth }}>
        <div
          className={`input-group ${showDropDownMenu ? "clicked" : ""}`}
          style={{ width: dropdownWidth }}
        >
          <div className="custom-dropdown" style={{ width: dropdownWidth }} >
            <span
              className="input-group-text no-arrow"
              style={{ width: dropdownWidth }}
              onClick={showHideDropDownMenu}
              id="inputGroup-sizing-sm"
            >
              <div className="dropdown-header custom-dropdown-header">
                <span className="dateselection date-text-con">

                  {value=== "" ? defaultValue: value}
                </span>
                <span
                  className={`arrow ${
                    showDropDownMenu ? "up greenArrow" : "down"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L15 13"
                      stroke={showDropDownMenu ? "var(--baseColor)" : "#6b7280"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </span>
            {showDropDownMenu && options && options.length > 0 && (
              <div className="dashboard-calendar dropdown-options" ref={dropdownRef}>
                {options.map((item, i) => (
                  <div
                    className="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChangeHandler(defaultValue, item);
                      onChange(defaultValue, item);
                      showHideDropDownMenu(e);
                    }}
                  >
                    {item?.profile}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownBasic;
