import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/brand/logo-resize.png";
interface FormState {
  redirect: string | null;
 newPassword: string;
  showPassword: boolean;
  showPassword2: boolean;
  confirmNewPassword: string;
  token: string;
  name: string;
  errorMessage: string;
  email:string;
  successMessage: string;
  errors: {
   newPassword: string;
    confirmNewPassword:string;
  };
}
class SetNewPassword extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      redirect: null,
      token: window.location.href.split("=")[1],
      newPassword: "",
      confirmNewPassword:"",
      name:localStorage.getItem("action") || "",
      email:localStorage.getItem("userEmail")||"",
      showPassword: false,
      showPassword2: false,
      errorMessage: "",
      successMessage: "",
      errors: {
       newPassword: "",
        confirmNewPassword:""
      },
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };
  componentDidMount() {
    this.activteAccount();
  }

  activteAccount() {

    const { token , name } = this.state;

    fetch("https://api.lotusppc.com/Users/ActivateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, name }),
    })
      .then((response) => response.json())
      .then((responceData) => {
        console.log(responceData);
        
        if (responceData === "Password reset successfully") {
          // toast("You Successfully activated your email")
        } else {
          toast(" Email activation failed ")
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Something went wrong")
      });
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {newPassword,email,confirmNewPassword  } = this.state;
    console.log(confirmNewPassword);
    console.log(newPassword);
    

    // Perform validation
    const errors: FormState["errors"] = {
     newPassword: "",
      confirmNewPassword: "",
    };
    if (!newPassword) {
      errors.newPassword = "*Password is required";
    }
    if (!confirmNewPassword) {
      errors.confirmNewPassword = "*Confirm password is required";
    }

    if (newPassword !== confirmNewPassword) {
      errors.confirmNewPassword = "*Passwords do not match.";
    }

    this.setState({ errors });

    if (newPassword) {
      fetch("https://api.lotusppc.com/Users/ResetPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword,email ,confirmNewPassword }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.success) {
            this.setState({ successMessage: "Successfully password changed." });
            this.setState({
              redirect: "/sign-in",
            });
            toast("Successfully password changed")
          } else {
            this.setState({ errorMessage: responseData.message });
            toast(" Reset password failed ")
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast("Something went wrong")

        });
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };
  togglePasswordVisibility2 = () => {
    this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    const { newPassword, errors, showPassword , confirmNewPassword, showPassword2 } = this.state;
    const isFormValid = newPassword !== "";
    return (
      <DefaultLayout>
        <div className="contanier-type1">
          <form onSubmit={this.onSubmit}>
          <div className="text-center">
              <img src={logo} alt="logo" />
            </div>
            <h3 >Set New Password</h3>
            <p>Enter your new password to reset your password</p>
            {this.state.errorMessage && (
              <p className="text-center text-red">{this.state.errorMessage}</p>
            )}
            {this.state.successMessage && (
              <p className="text-center text-green">
                {this.state.successMessage}
              </p>
            )}
            <div className="mb-4 password-cont">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                name="newPassword"
                value={newPassword}
                onChange={this.handleInputChange}
              />
              <span
                className="show-password"
                onClick={this.togglePasswordVisibility}
              >
                {showPassword ? (
                  <i className="fas fa-eye-slash"></i>
                ) : (
                  <i className="fas fa-eye"></i>
                )}
              </span>
              {errors.newPassword && (
                <p className="validation-error">{errors.newPassword}</p>
              )}
            </div>
            <div className="mb-4 password-cont">
              <input
                id="confirmNewPassword"
                type={showPassword2 ? "text" : "password"}
                className="form-control"
                placeholder="Confirm Password"
                name="confirmNewPassword"
                value={confirmNewPassword}
                onChange={this.handleInputChange}
              />
              <span
                className="show-password"
                onClick={this.togglePasswordVisibility2}
              >
                {showPassword2 ? (
                  <i className="fas fa-eye-slash"></i>
                ) : (
                  <i className="fas fa-eye"></i>
                )}
              </span>
              {errors.confirmNewPassword && (
                <p className="validation-error">{errors.confirmNewPassword}</p>
              )}
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary login ">
               
                <div className="button-text"> Reset Password </div>
              </button>
            </div>
            <div className="new-create-account">
              <p>
                Back to <Link to={"/sign-in"}>Log in</Link>
              </p>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}
export default SetNewPassword;
