import React, { useState, useEffect, useRef } from "react";
import "./DateRangeFilter.css";
import { DatePicker, DateRangePicker } from "rsuite";
import CalenderIcon from "../../../assets/images/icons/calendar-icon.svg";
let emailId;
const DateRangeFilter = ({ options, value, onChange, datechangeCallback }) => {
  const [dropdownWidth, setDropdownWidth] = useState("200px");
  const current = new Date();

const endDate = new Date();

  const dropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDownMenu(false);
    }
  };
  const getDefaultDateRange = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);
    return [startDate, endDate];
  };
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  const [selectedDateRanges, setSelectedDateRanges] = useState(
    value |
      {
        dateRange: "Last30Days",
        startDate: null,
        endDate: null,
      }
  );
  const [selectedItem, setSelectedItem] = useState({
    name: "Last 30 days",
    value: "Last30Days",
    mostCommonlyUsed: true,
  });
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dropDownWidth, setDropDownWidth] = useState(200);

  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const handleOnChange = (event, item) => {
    setSelectedItem(item);
    let dateRanges = {
      dateRange: item.value,
      startDate: null,
      endDate: null,
    };
    const defaultDateRange = JSON.stringify(dateRanges);
    localStorage.setItem(`defaultDate_${emailId}`, defaultDateRange);
    setDropdownWidth(item.value === "Custom" ? "300px" : "200px");
    if (item.value === "Custom") {
      setShowDropDownMenu(false);
      //    setDatePickerOpen(true);
    } else {
      setSelectedDateRanges(dateRanges);
      setShowDropDownMenu(false);
      onChange(dateRanges);
    }
  };

  const handleDateRangeChange = async (selectedDates, event) => {
    console.log(selectedDates);
    if (selectedDates && selectedDates.length > 0) {
      const endDate = selectedDates[1].toISOString().slice(0, 10);
      const startDate = selectedDates[0].toISOString().slice(0, 10);
      const dateRanges = {
        dateRange: "Custom",
        endDate: endDate,
        startDate: startDate,
      };
      const dateRangesData = {
        dateRange: "Custom",
        endDate: endDate,
        startDate: startDate,
        startDateRange: selectedDates[0].toString(),
        endDateRange: selectedDates[1].toString(),
      };
      setDateRange(selectedDates);
      setSelectedDateRanges(dateRanges);
      setShowDropDownMenu(false);
      onChange(dateRanges);
      const defaultDateRange = JSON.stringify(dateRangesData);
      localStorage.setItem(`defaultDate_${emailId}`, defaultDateRange);
      datechangeCallback(selectedDates)
    }
  };

  const showHideDropDownMenu = (event) => {
    event.stopPropagation();

    setShowDropDownMenu(!showDropDownMenu);
  };

  // useEffect(() => {
  //   const defaultDate = localStorage?.getItem(`defaultDate_${emailId}`);
  //   emailId = localStorage?.getItem("email");
  //   const defaultDateRange = JSON?.parse(defaultDate);
  //   if (
  //     defaultDateRange &&
  //     defaultDateRange?.endDateRange &&
  //     defaultDateRange?.startDateRange
  //   ) {
  //     const getDefaultDateRangeValues = () => {
  //       const endDate = new Date(defaultDateRange?.endDateRange) || new Date();
  //       const startDate =
  //         new Date(defaultDateRange?.startDateRange) || new Date();
  //       // startDate.setDate(endDate.getDate() - 30);
  //       return [startDate, endDate];
  //     };
  //     setDateRange(getDefaultDateRangeValues());
  //   }
  //   if (options) {
  //     setSelectedItem(options.find((item) => item.value === value.dateRange));
  //   }
  //   setDropdownWidth(
  //     defaultDateRange?.dateRange === "Custom" ? "300px" : "200px"
  //   );
  // }, [value, options]);

  useEffect(() => {
    console.log(options);
    console.log(value);
    console.log(selectedDateRanges);
    if (options && selectedDateRanges) {
      console.log(selectedDateRanges);
      setSelectedItem(
        options.find((item) => item.value === selectedDateRanges.dateRange)
      );
    }

    if(value==="Last30Days"){
      const startDate = new Date(current);
     startDate.setDate(current.getDate() - 30);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="Yesterday"){
      const startDate = new Date(current);
     startDate.setDate(current.getDate() - 1);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="LastWeek"){
      const startDate = new Date(current);
     startDate.setDate(current.getDate() - 7);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="LastMonth"){
      const startDate = new Date(current);
      startDate.setMonth(current.getMonth() - 1);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="ThisWeek"){
      const startDate = new Date(current);
      const day = current.getDay(); 
      startDate.setDate(current.getDate() - day);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="ThisMonth"){
      const startDate = new Date(current);
      startDate.setDate(1);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="Last7Days"){
      const startDate = new Date(current);
     startDate.setDate(current.getDate() - 7);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }
    else if(value==="Last14Days"){
      const startDate = new Date(current);
     startDate.setDate(current.getDate() - 14);
       const data = [startDate, endDate];
     datechangeCallback(data)
    }


  }, [selectedDateRanges, options]);

  return (
    <div className="DateRangeFilter">
      <div className="time-line" style={{ width: dropdownWidth }}>
        <div
          className={`input-group ${showDropDownMenu ? "clicked" : ""}`}
          style={{ width: dropdownWidth }}
        >
          <div className="custom-dropdown" style={{ width: dropdownWidth }}>
            <span
              className="input-group-text no-arrow"
              style={{ width: dropdownWidth }}
              onClick={showHideDropDownMenu}
              id="inputGroup-sizing-sm"
            >
              <div className="dropdown-header custom-dropdown-header">
                <span className="date-left-icon">
                  <img
                    src={CalenderIcon}
                    alt="calendar icon"
                    className={showDropDownMenu ? "bright-icon" : "normal-icon"}
                  />
                </span>
                <span className="date-text-con">
                  <span className="dateselection date-text-con">
                    {/* {selectedItem && */}
                    {selectedItem && selectedItem?.value === "Custom" ? (
                      <span
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <DateRangePicker
                          showOneCalendar
                          placeholder="Select a timeline"
                          value={dateRange}
                          onChange={handleDateRangeChange}
                          // open={datePickerOpen}
                          format="yyyy-MM-dd"
                          ranges={[]}
                        />
                      </span>
                    ) : (
                      <>{selectedItem?.name}</>
                    )}
                  </span>
                </span>
                <span
                  className={`arrow ${
                    showDropDownMenu ? "up greenArrow" : "down"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L15 13"
                      stroke={showDropDownMenu ? "var(--baseColor)" : "#6b7280"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </span>
            {showDropDownMenu && options && options.length > 0 && (
              <div className="dashboard-calendar dropdown-options" ref={dropdownRef}>
                {options.map((item, i) => (
                  <div
                    className="dropdown-item"
                    onClick={(event) => handleOnChange(event, item)}
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
