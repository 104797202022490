import React, { useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import { Row, Col } from "react-bootstrap";
import "./index.css";

import AddRulesApplyto from "../../views/AutomationRules/CreateRules/components/step-forms/applyto";
import AddRulesIf from "../../views/AutomationRules/CreateRules/components/step-forms/if";
import AddRulesFor from "../../views/AutomationRules/CreateRules/components/step-forms/for";
import AddRulesThen from "../../views/AutomationRules/CreateRules/components/step-forms/then";
import AddRulesUntil from "../../views/AutomationRules/CreateRules/components/step-forms/until";
import AddRulesTimeline from "../../views/AutomationRules/CreateRules/components/step-forms/timeline";

import InfoIcon from "../../assets/images/icons/info-circle-icon.svg";
import Select, { components } from "react-select";
import { Box, CircularProgress } from "@mui/material";

const CreateRuleWizard = (props) => {
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  const [curStep, setCurStep] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [wizardData, setWizardData] = useState(props.wizardData);
  const [marketplace, setMarketplace] = useState(props.marketplace);

  useEffect(() => {
    console.log(marketplace);
    if (props.rulesMetaData) {
      let applyToDetails = props.rulesMetaData.applyToDetails;
      if (applyToDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        let marketplaceList = applyToDetails.marketPlaces;
        let newMarketplaceList = [];
        if (marketplaceList.length > 0) {
          for (let i = 0; i < marketplaceList.length; i++) {
            let obj = {
              value: marketplaceList[i].countryCode,
              label: marketplaceList[i].country,
            };
            newMarketplaceList.push(obj);
          }
        }
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
    setMarketplace(props.marketplace);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    setWizardData(props.wizardData);
  }, [props.wizardData]);

  /* Apply to wizard */

  const backClick = () => {
    if (curStep !== 0) {
      setCurStep(curStep - 1);
    }
  };
  const nextClick = () => {
    if (curStep !== 6) {
      setCurStep(curStep + 1);
    }
  };
  const handleCallback = (childData) => {
    props.parentCallback(childData);
  };
  const parentWizardData = (childData) => {
    props.wizardCallback(childData);
  };
  const submitAddRule = () => {
    let submit = {
      type: "submit",
    };
    // setIsLoading(true);
    props.parentCallback(submit);
  };

  return (
    <>
      <div className="wizard-container">
        <div className="wizard-stepper-container">
          <div className="stepper-wrapper"></div>
          <Stepper activeStep={curStep}>
            <Step children={"Target"} />
            <Step children={"Action"} />
            <Step children={"Limit"} />
            <Step children={"Apply to"} />
          </Stepper>
        </div>
        <hr />
        <div className="wizard-step-container">
          {curStep === 0 && (
            <AddRulesIf
              rulesMetaData={rulesMetaData}
              metaData={metaData}
              parentCallback={handleCallback}
              wizardDataCallback={parentWizardData}
              wizardData={wizardData}
            />

          )}
          {curStep === 1 && (
            <AddRulesThen
              rulesMetaData={rulesMetaData}
              metaData={metaData}
              parentCallback={handleCallback}
              wizardDataCallback={parentWizardData}
              wizardData={wizardData}
            />

          )}
          {curStep === 2 && (
            <AddRulesUntil
              rulesMetaData={rulesMetaData}
              metaData={metaData}
              parentCallback={handleCallback}
              wizardDataCallback={parentWizardData}
              wizardData={wizardData}
            />
          )}
          {curStep === 3 && (
            <AddRulesApplyto
              rulesMetaData={rulesMetaData}
              metaData={metaData}
              parentCallback={handleCallback}
              wizardDataCallback={parentWizardData}
              wizardData={wizardData}
              connectedMarketplace={marketplace}
            />

          )}

        </div>
      </div>
      <div className="wizard-nav-btn-container flex-row-reverse">
        <div className="wizard-nav-btn">
          {curStep !== 3 ? (
            <button onClick={nextClick} className="green">
              Next
            </button>
          ) : (
            <>
              {props.isLoading ? (
                <button onClick={submitAddRule} className="green">
                  <div
                    className="d-flex aligh-items-baseline"
                    // style={{ cursor: "wait" }}
                  >
                    Creating...&nbsp;&nbsp;&nbsp;
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress style={{ color: "black" }} size={20} />
                    </Box>
                  </div>
                </button>
              ) : (
                <button onClick={submitAddRule} className="green">
                  Add Rule
                </button>
              )}
            </>
          )}
        </div>
        {/* <div>
          <img src={InfoIcon} alt="" />
        </div> */}
        <div className="wizard-nav-btn">
          {curStep !== 0 ? (
            <button onClick={backClick} className="outline">
              Back
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default CreateRuleWizard;
