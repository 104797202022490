import React, { useState } from 'react';
import InfoCircleIcon from "../../assets/images/icons/info-circle-icon.svg";

const InfoButton = ({ infoText }) => {
    //const [isInfoVisible, setIsInfoVisible] = useState(false);

    //const handleInfoClick = () => {
    //    setIsInfoVisible(!isInfoVisible);
    //};

    return (
        <span className="mx-2" title={infoText}>
            < img src={InfoCircleIcon} alt="more action icon" />
        </span >
    );
};

export default InfoButton;
