import React from "react";
import Logo from "../../assets/brand/logo.svg";
import "./index.css";
import Footer from "../../shared/Footer";
interface Props {
  children: React.ReactNode;
}
const DefaultLayout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className="">
      <div className="header">
        <div className="login-log">{/* <img src={Logo} alt="logo" /> */}</div>
      </div>
      <div className="default-inner">
        <main>{props.children}</main>
      </div>
      <div className="footer-login">
        <Footer />
      </div>
    </div>
  );
};
export default DefaultLayout;
