import React from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
export default function Navbar() {
  return (
    <div className="my-account-navbar ">
      <ul>
        <li>
          <NavLink to="/my-account/profile">
            <div className="myProfileLogo">
              {/* <i className="profile-logo" /> */}
              <span className="profile-span">Profile</span>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/my-account/billing">
            <div className="bill">
              {/* <i className="profileBilling-logo" /> */}
              <span className="profile-span">Billing</span>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/my-account/notification">
            {/* <i className="profileNotification-logo" /> */}
            <span className="profile-span">Notification</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/my-account/amazon-connection">
            {/* <i className="amazonConnection-logo" /> */}
            <span className="profile-span">Amazon Connection</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
