import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
const SignInWithGoogle = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const responseGoogle = (response) => {
    const { profileObj, tokenObj } = response;
    const { email, givenName, familyName } = profileObj;
    const { accessToken, idToken } = tokenObj;

    const apiData = {
      email: email,
      firstName: givenName,
      lastName: familyName,
      provider: "google",
      authToken: response.accessToken,
      idToken: response.tokenId,
    };

    console.log(apiData);

    fetch("https://api.lotusppc.com/Users/OAuthLogin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiData),
    })
      .then((response) => response.json())
      .then((responceData) => {
        console.log("API Response:", responceData);

        if (responceData.success) {
          localStorage.setItem("userName", responceData.result.user.userName);
          localStorage.setItem("email", responceData.result.user.email);
          localStorage.setItem("userToken", responceData.result.accessToken);
          localStorage.setItem("planName", responceData.result.user.planName);
          localStorage.setItem(
            "planStatus",
            responceData.result.user.planStatus
          );
          localStorage.setItem(
            "planExpDate",
            responceData.result.user.planEXPDate
          );
          if (responceData.result.user.kdpSync) {
            navigate("/Dashboard");
          } else {
            navigate("/onboarding");
          }
        } else {
          console.error("API Error:", responceData.message);
        }
      });
  };

  const responseErrorGoogle = (response) => {
    if (response.error === "popup_closed_by_user") {
      alert("Sign-in process canceled by the user.");
    } else {
      console.error("Google Error:", response);
    }
  };

  return (
    <div>
      <GoogleLogin
        clientId="732317224628-p81vrbgvh2amf4r3s4bra4m0kv4pkk57.apps.googleusercontent.com"
        buttonText="Continue with Google"
        onSuccess={responseGoogle}
        onFailure={responseErrorGoogle}
        cookiePolicy={"single_host_origin"}
        scope="profile email openid"
      />
    </div>
  );
};

export default SignInWithGoogle;
