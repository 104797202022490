import React, { Component, RefObject } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Col, Row } from "react-bootstrap";
import Navbar from "../Navbar/navbar";
import "./amazonConnection.css";
import AmazonConnectionTable, {
  AmazonConnectionTableRef,
} from "../Table/amazonConnectionTable";
import { text } from "stream/consumers";
import { colors } from "@mui/material";
import { Link, redirect } from "react-router-dom";
import MyConnection from "../Connection/myConnection";
let subscribe: string | null;

interface AmazonConnectionProps {}
subscribe = localStorage.getItem("planName");

interface AmazonConnectionState {
  amazonConnectionTableRef: RefObject<AmazonConnectionTableRef>;
}
export default class AmazonConnection extends Component<
  AmazonConnectionProps,
  AmazonConnectionState
> {
  constructor(props: AmazonConnectionProps) {
    super(props);
    this.state = {
      amazonConnectionTableRef: React.createRef<AmazonConnectionTableRef>(),
    };
    this.handlelocalStorage();
  }

  handleButtonClick = () => {
    console.log("Button clicked in AmazonConnection");
    this.state.amazonConnectionTableRef.current?.handleButtonClick();
  };
  handlelocalStorage = () => {
    subscribe = localStorage.getItem("planName");
  };
  render() {
    return (
      <DashboardLayout>
        <div className="container-fluid my-accout-profile">
          <Row className="justify-content-center">
            <Navbar />
            <Col md={12} className="mt-4">
              <div className="amazon-conection container">
                <Row>
                  <Col md={12}>
                    <div className="amazonConnectionCard">
                      <div className="amazonConectionHeading">
                      </div>

                      <Row>
                        <MyConnection />    
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </DashboardLayout>
    );
  }
}
