import React, { Component, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Row, Col, Tabs, Tab, Spinner, Container } from "react-bootstrap";
import "./index.css";
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "../../assets/images/icons/link-icon.svg";
import CalenderIcon from "../../assets/images/icons/calendar-icon.svg";
import { Link } from "react-router-dom";
import Moment from "moment";
import moment from "moment";
import BooksTable from "./table/books-table";
import AuthorsTable from "./table/author-table";
import MarketplaceTable from "./table/marketplace-table";
import BestSeller from "./bestSeller/bestSeller";
import { Modal } from "antd";
import Synckdpextension from "./Synckdpextension";
import SyncDataDaily from "./SyncDataDaily";
import MasterDataService from "../../services/MasterDataService";
import DashboardService from "../../services/DashboardService";
import RoyaltiesGross from "../../components/Dashboard/RoyaltiesGross";
import Metrics from "../../components/Dashboard/Metrics";
import DonutPie from "../../components/Dashboard/DonutPie";
import Donut from "../../components/Dashboard/Donut";
import Funnel from "../../components/Dashboard/Funnel";
import MultiSeriesAreaChart from "../../components/Dashboard/MultiSeriesAreaChart";
import WorldMapChart from "../../components/Dashboard/WorldMapChart";
import HorizontalChart from "../../components/Dashboard/HorizontalChart";
import HeatMapChart from "../../components/Dashboard/HeatMapChart";
import DateRangeFilter from "../../components/Common/DateRangeFilter/DateRangeFilter";
import Block from "../../components/Block/Block";
import AsinTable from "../../components/AsinTable/AsinTable";
let emailId;
interface Metric {
  metricName: string;
  value: number;
  avgvalue: number;
}
type Marketplace = {
  name: string;
  value: string;
};

type BookFormat = {
  name: string;
  value: string;
};

export default class Dashboard extends Component {
  filterData: any = [];
  current = new Date();
  startDate = new Date();
  endDate = new Date();
  startDateFilter =
    Moment(this.startDate).format("YYYY-MM-DD") + "T00:00:01.064Z";
  /*private dateRangeContainerRef: React.RefObject<HTMLDivElement>;*/
  endDateFilter = Moment(this.endDate).format("YYYY-MM-DD") + "T23:50:59.064Z";
  constructor(props) {
    super(props);
    /*   this.dateRangeContainerRef = React.createRef();*/
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);
    this.startDate.setDate(this.endDate.getDate() - 30);
  }

  state = {
    isSyncNew: false,
    ispopup: false,
    showDateRangeDropdown: false,
    dateRangeSelectedOption: "",
    getDateRange: ["Yeartodate", "Lastyear", "Lifetime"],
    issearchText: {
      titlesearch: "",
      campainsearch: "",
      authorsearch: "",
      booksearch: "",
      marketsearch: "",
    },
    dummyData: {
      keyword: [
        {
          lable: "Keywords: best performing",
          range: "Last 1 day/days best performing keywords",
          data: [
            {
              id: 1,
              value: 20,
              valueData: "First Aid Kit",
              paletteDark: "#f9a31c",
              paletteLight: "#fef5e6",
            },
            {
              id: 2,
              value: 30,
              valueData: "1st Aid Kit",
              paletteDark: "#758AFF",
              paletteLight: "#CACEFF",
            },
            {
              id: 3,
              value: 50,
              valueData: "Medical Dressings",
              paletteDark: "#57FDBC",
              paletteLight: "#D2F7E8",
            },
            {
              id: 4,
              value: 20,
              valueData: "AED",
              paletteDark: "#3A51DB",
              paletteLight: "#C5C8E9",
            },
            {
              id: 5,
              value: 50,
              valueData: "Eye Wash",
              paletteDark: "#47FFE0",
              paletteLight: "#C9F9F1",
            },
          ],
        },
      ],
      campainsBest: [
        {
          lable: "Campains: best performing",
          range: "Last 1 day/days best performing campains",
          data: [
            {
              id: 1,
              value: 20,
              valueData: "B0457R3T",
              paletteDark: "#f9a31c",
              paletteLight: "#fef5e6",
            },
            {
              id: 2,
              value: 30,
              valueData: "05544RP",
              paletteDark: "#758AFF",
              paletteLight: "#CACEFF",
            },
            {
              id: 3,
              value: 50,
              valueData: "NJK793E",
              paletteDark: "#57FDBC",
              paletteLight: "#D2F7E8",
            },
            {
              id: 4,
              value: 20,
              valueData: "C766DDS3",
              paletteDark: "#3A51DB",
              paletteLight: "#C5C8E9",
            },
            {
              id: 5,
              value: 50,
              valueData: "B0457R3T",
              paletteDark: "#47FFE0",
              paletteLight: "#C9F9F1",
            },
          ],
        },
      ],
      campainsWorst: [
        {
          lable: "Campains: worst performing",
          range: "Last 1 day/days worst performing campains",
          data: [
            {
              id: 1,
              value: 20,
              valueData: "Bitcoin",
              paletteDark: "#f9a31c",
              paletteLight: "#fef5e6",
            },
            {
              id: 2,
              value: 30,
              valueData: "Ethereum",
              paletteDark: "#758AFF",
              paletteLight: "#CACEFF",
            },
            {
              id: 3,
              value: 50,
              valueData: "NioCoin",
              paletteDark: "#57FDBC",
              paletteLight: "#D2F7E8",
            },
            {
              id: 4,
              value: 20,
              valueData: "Litecoin",
              paletteDark: "#3A51DB",
              paletteLight: "#C5C8E9",
            },
            {
              id: 5,
              value: 50,
              valueData: "Bitcoin Cash",
              paletteDark: "#47FFE0",
              paletteLight: "#C9F9F1",
            },
          ],
        },
      ],
      topAsins: [
        {
          lable: "Top ASINs",
          range: "Last 1 day/days top products",
          data: [
            {
              id: 1,
              value: 48,
              valueData: "B0775MV9K2",
              paletteDark: "#f9a31c",
              paletteLight: "#fef5e6",
              percent: "48%",
            },
            {
              id: 2,
              value: 30,
              valueData: "D0745MK9VS",
              paletteDark: "#758AFF",
              paletteLight: "#CACEFF",
              percent: "30%",
            },
            {
              id: 3,
              value: 12,
              valueData: "SHSJ54RHCS",
              paletteDark: "#57FDBC",
              paletteLight: "#D2F7E8",
              percent: "12%",
            },
            {
              id: 4,
              value: 20,
              valueData: "K3LOP98TWO",
              paletteDark: "#3A51DB",
              paletteLight: "#C5C8E9",
              percent: "20%",
            },
            {
              id: 5,
              value: 51,
              valueData: "D0745MK9VS",
              paletteDark: "#47FFE0",
              paletteLight: "#C9F9F1",
              percent: "31%",
            },
          ],
        },
      ],
    },
    ispopupauthor: false,
    ispoupcampain: false,
    ispopupmarket: false,
    startDate: this.startDate,
    endDate: this.endDate,
    syncData: "",
    globalFilterFromData: [],
    filteredmarketData: [],
    filteredauthorData: [],
    bestSellerData: [],
    campainData: [],
    bookData: [],
    filteredbookData: [],
    bookDataList: [],
    campainDataList: [],
    filteredcampainData: [],
    titleData: [],
    titleDataList: [],
    marketDataList: [],
    authorDataList: [],
    filteredTitleData: [],
    marketData: [],
    authorData: [],
    salesAvgValue: "",
    salesMetricName: "",
    salesValue: "",
    budgetAvgValue: "",
    budgetMetricName: "",
    budgetValue: "",
    selectedValue: "",
    CPCAvgValue: "",
    CPCMetricName: "",
    CPCValue: "",
    orderAvgValue: "",
    orderMetricName: "",
    orderValue: "",
    searchKeyFilter: "",
    CRData1: "",
    CRData2: "",
    CRAvarageValue: "",
    CRPercentage: "",
    loading: false,
    categories: "",
    salesAvarageValue: "",
    salesPercentage: "",
    royaltiesDistributionValue: "",
    royaltiesDistributionPercentage: "",
    royaltiesProjectedValue: "",
    royaltiesProjectedPercentage: "",
    globalFilterDateRange: [this.startDate, this.endDate],
    spendChartData: [],
    RoyaltiesDistributionChart: [],
    SpenLoading: false,
    isModalVisible: false,
    isSyncDataVisible: true,
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    metrics: [] as Metric[],
    selectedMarketplace: "",
    selectedMetricCPC: {
      metricName: "",
      value: 0,
      avgvalue: 0,
    },
    selectedMetricBudget: {
      metricName: "",
      value: 0,
      avgvalue: 0,
    },
    selectedMetricSales: {
      metricName: "",
      value: 0,
      avgvalue: 0,
    },
    selectedMetricOrder: {
      metricName: "",
      value: 0,
      avgvalue: 0,
    },
    metricOptions: [] as string[],
    metricNames: [] as string[],
    seriesDataNames: [],
    selectedMetric: "",
    metaData: {
      data: {
        marketplaces: [] as Marketplace[],
        bookFormats: [] as BookFormat[],
      },
    },
    selectedBookFormat: "",
    ispopupbookformat: false,
    bookFormats: [] as BookFormat[],
    marketplaces: [] as Marketplace[],
    ispopupmarketplace: false,
    selectedChart: "",

    selectedMetricSpend: "",
    isClicked: false,
    showDateRangePicker: false,
    globalFilters: {
      searchText: "",
      authors: [],
      bookFormats: [],
      dateRanges: {
        dateRange: "Last30Days",
        startDate: null,
        endDate: null,
      },
      titles: [],
      campaigns: [],
      platforms: [],
      marketPlaces: [],
      adTypes: [],
      advancedFilters: [],
    },
    isDatePickerOpen: true,
    activeTab: "books",
    dateRangeOptions: [],
    // defaultMetricData: {} as any,
    storeDefaultMetricData: {} as any,
  };

  handleTabSelect = (tab) => {
    this.setState({ activeTab: tab });
  };

  //updateDateRange = (startDate: Date | null, endDate: Date | null) => {
  //    this.setState({
  //        globalFilters: {
  //            ...this.state.globalFilters,
  //            dateRanges: {
  //                ...this.state.globalFilters.dateRanges,
  //                startDate: startDate,
  //                endDate: endDate,
  //            },
  //        },
  //    });
  //};

  componentDidMount(): void {
    // this.getMetaData();
    // this.titleHandler();
    //const dateRangeLabel = "Custom"; // Replace this with your actual value

    //this.updateDateRange(this.state.startDate, this.state.endDate);
    // this.titleHandler();
    // this.getSyncStatus();
    // this.defaultDateData();
    //console.log("Date=" + this.state.globalFilterDateRange);
    const lastDisplayedTime = localStorage.getItem("lastDisplayedTime");
    const currentTime = new Date().getTime();
    const lastSyncDate = localStorage.getItem("lastSyncDate");
    emailId = localStorage.getItem("email");
    if (lastSyncDate) {
      this.setState({ syncData: lastSyncDate });
    }
    if (
      lastDisplayedTime &&
      currentTime - parseInt(lastDisplayedTime, 10) < 24 * 60 * 60 * 1000
    ) {
      this.setState({ isSyncDataVisible: false });
    }
    this.setState({
      RoyaltiesDistributionChart: [],
    });
    this.setState({
      titleDataList: [...this.state.filteredTitleData],
    });
    const allTypeButton = document.querySelector(
      ".filterBtnClick"
    ) as HTMLButtonElement;
    if (allTypeButton) {
      allTypeButton.click();
    }
    document.addEventListener("click", this.handleClickOutside);
    /*  document.body.addEventListener("click", this.handleBodyClick);*/
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    /*document.body.removeEventListener("click", this.handleBodyClick);*/
  }
  defaultDateData = () => {
    const defaultMetricData: any = localStorage?.getItem(
      `defaultSetting_${emailId}`
    );
    const Datas = JSON?.parse(defaultMetricData);
    this.setState({ storeDefaultMetricData: Datas });
    const defaultDate: any = localStorage?.getItem(`defaultDate_${emailId}`);
    const defaultDateRange = JSON?.parse(defaultDate);
    this.setState((prev: any) => {
      return {
        ...prev,
        globalFilters: {
          ...prev.globalFilters,
          dateRanges: {
            dateRange: defaultDateRange?.dateRange || "Last30Days",
            startDate: defaultDateRange?.startDate || null,
            endDate: defaultDateRange?.endDate || null,
          },
        },
      };
    });
  };
  defaultChange = (metricName, i) => {
    this.setState((prevState: any) => {
      const updatedStoreDefaultMetricData = {
        ...prevState.storeDefaultMetricData,
        [i]: metricName,
      };
      localStorage.setItem(
        `defaultSetting_${emailId}`,
        JSON.stringify(updatedStoreDefaultMetricData)
      );
      return { storeDefaultMetricData: updatedStoreDefaultMetricData };
    });
  };
  getSyncStatus = async () => {
    try {
      const lastResult = await DashboardService.getLastSyncData();

      if (lastResult === "Not Synced yet") {
        console.log("Data not synced yet");
        localStorage.setItem("lastUpdatedDate", "Not Synced yet");
        this.setState({ lastUpdatedDate: "Not Synced yet" });
      } else {
        const formattedDate = new Date(lastResult.lastKDPSync);

        // Get day and month names
        const dayName = formattedDate.toLocaleDateString("en-US", {
          weekday: "long",
        });
        const monthName = formattedDate.toLocaleDateString("en-US", {
          month: "long",
        });

        // Continue with the existing logic to set the formatted date in the state
        const month = (formattedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const day = formattedDate.getDate().toString().padStart(2, "0");
        const year = formattedDate.getFullYear();
        const hours = formattedDate.getHours().toString().padStart(2, "0");
        const minutes = formattedDate.getMinutes().toString().padStart(2, "0");
        const seconds = formattedDate.getSeconds().toString().padStart(2, "0");

        const ampm = parseInt(hours, 10) >= 12 ? "PM" : "AM";

        const displayHours = parseInt(hours, 10) % 12 || 12;

        // Combine the formatted date, day, month, and AM/PM
        const displayDate = `${dayName}, ${monthName} ${day}, ${year} ${displayHours}:${minutes}:${seconds} ${ampm}`;

        localStorage.setItem("lastUpdatedDate", displayDate);

        this.setState({ lastUpdatedDate: displayDate });
      }
    } catch (error) {
      console.error("Error fetching sync status:", error);
    }
  };

  lastAppSyncClick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  //handleBodyClick = (event) => {
  //    // Check if the click is outside the date range container
  //    const dateRangeContainer = document.querySelector(".date-range-container");
  //    if (dateRangeContainer && !dateRangeContainer.contains(event.target)) {
  //        // Clicked outside the date range container, close the date range picker
  //        this.setState({ showDateRangeDropdown: false });
  //    }
  //};

  selectPlan = (eve) => {
    const selectedPlan: any = document.querySelectorAll(".filterBtnClick");
    if (selectedPlan.length > 0) {
      const clearEle: any = document.querySelectorAll(
        ".filterBtnClick.selected"
      );
      for (var i = 0; i < clearEle.length; i++) {
        clearEle[i].classList.remove("selected");
      }
      for (let i = 0; i < selectedPlan.length; i++) {
        if (selectedPlan[i].contains(eve.target)) {
          selectedPlan[i].classList.add("selected");
        }
      }
    }
  };

  cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    for (let i = 0; i < eleRm.length; i++) {
      eleRm[i].classList.remove("show");
    }
  };
  handleSyncDataClose = () => {
    localStorage.setItem("lastDisplayedTime", new Date().getTime().toString());
    this.setState({ isSyncDataVisible: false });
  };

  // titleHandler = async () => {
  //   try {
  //     this.setState({
  //       orderValue: (
  //         <Spinner animation="border" role="status">
  //           <span className="visually-hidden">Loading...</span>
  //         </Spinner>
  //       ),
  //     });
  //     let userToken = localStorage.getItem("userToken");
  //     let AuthToken = "Bearer " + userToken;

  //     const responseOrder = await fetch(
  //       `https://api.lotusppc.com/Dashboard/GlobalFilterValue?SearchText`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: AuthToken,
  //         },
  //       }
  //     );
  //     const responseOrderData = await responseOrder.json();
  //     console.log(responseOrderData);
  //     this.setState(
  //       {
  //         titleData: responseOrderData.result.title,
  //         filteredTitleData: responseOrderData.result.title,
  //         titleDataList: [...responseOrderData.result.title],
  //       },
  //       () => {
  //         console.log("Data sent to API:", {
  //           titleDataList: this.state.titleDataList,
  //           campainDataList: this.state.campainDataList,
  //           authorDataList: this.state.authorDataList,
  //         });
  //       }
  //     );

  //     this.setState(
  //       {
  //         campainData: responseOrderData.result.campaginName,
  //         filteredcampainData: responseOrderData.result.campaginName,
  //         campainDataList: [...responseOrderData.result.campaginName],
  //       },
  //       () => {
  //         console.log("Data sent to API:", {
  //           titleDataList: this.state.titleDataList,
  //           campainDataList: this.state.campainDataList,
  //           authorDataList: this.state.authorDataList,
  //         });
  //       }
  //     );

  //     this.setState(
  //       {
  //         authorData: responseOrderData.result.author,
  //         filteredauthorData: responseOrderData.result.author,
  //         authorDataList: [...responseOrderData.result.author],
  //       },
  //       () => {
  //         console.log("Data sent to API:", {
  //           titleDataList: this.state.titleDataList,
  //           campainDataList: this.state.campainDataList,
  //           authorDataList: this.state.authorDataList,
  //         });
  //       }
  //     );
  //   } catch (error) {
  //     console.error(error);
  //     return error;
  //   }
  // };
  applyFilter = () => {
    this.setState({ titleDataList: [] });
    this.setState({ authorDataList: [] });
    this.setState({ campainDataList: [] });
    this.setState({ marketDataList: [] });
    this.setState({ bookDataList: [] });
    this.setState({ issearchText: "" });
    this.componentDidMount();
    this.cancelDropdown();
  };

  titleHandlerList = () => {
    this.setState({ ispopup: !this.state.ispopup });
  };
  authorHandlerList = () => {
    this.setState({ ispopupauthor: !this.state.ispopupauthor });
  };
  campainHandlerList = () => {
    this.setState({ ispoupcampain: !this.state.ispoupcampain });
  };
  bookFormatHandlerList = () => {
    this.setState({ ispopupbookformat: !this.state.ispopupbookformat });
  };
  marketplaceHandlerList = () => {
    this.setState({ ispopupmarketplace: !this.state.ispopupmarketplace });
  };
  markettextChangeHandler = (event) => {
    const searchText = event.target.value;
    this.setState({
      issearchText: { ...this.state.issearchText, marketsearch: searchText },
    });
    console.log(searchText);
    const filteredTitles = this.state.marketData.filter((title: any) =>
      title.toLowerCase().includes(searchText.toLowerCase())
    );
    this.setState({ filteredmarketData: filteredTitles });
  };
  marketselectallHandler = () => {};
  marketHandlerList = () => {
    this.setState({ ispopupmarket: !this.state.ispopupmarket });
    console.log(!this.state.ispopupmarket);
  };
  textChangeHandler = (type, event) => {
    if (type === "author") {
      const searchText = event.target.value;
      this.setState({
        issearchText: { ...this.state.issearchText, authorsearch: searchText },
      });
      console.log(this.state.issearchText);
      const filteredTitles = this.state.authorData.filter((title: any) =>
        title.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({ filteredauthorData: filteredTitles });
    } else if (type === "book") {
      const searchText = event.target.value;
      this.setState({
        issearchText: { ...this.state.issearchText, booksearch: searchText },
      });
      console.log(this.state.issearchText);
      const filteredTitles = this.state.bookData.filter((title: any) =>
        title.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({ filteredbookData: filteredTitles });
    } else if (type === "campain") {
      const searchText = event.target.value;
      this.setState({
        issearchText: { ...this.state.issearchText, campainsearch: searchText },
      });
      console.log(this.state.issearchText);
      const filteredTitles = this.state.campainData.filter((title: any) =>
        title.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({ filteredcampainData: filteredTitles });
    } else {
      const searchText = event.target.value;
      this.setState({
        issearchText: { ...this.state.issearchText, titlesearch: searchText },
      });
      console.log(this.state.issearchText);
      const filteredTitles = this.state.titleData.filter((title: any) =>
        title.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({ filteredTitleData: filteredTitles });
    }
  };
  searchHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  checkboxHandler = (type, id: string) => {
    if (type === "title") {
      let list: string[] = [...this.state.titleDataList];
      if (list.includes(id)) {
        const updatedList = list.filter((item) => item !== id);
        this.setState({ titleDataList: updatedList });
      } else {
        list.push(id);
        this.setState({ titleDataList: list });
      }
    } else if (type === "market") {
      let list: string[] = [...this.state.marketDataList];
      if (list.includes(id)) {
        const updatedList = list.filter((item) => item !== id);
        this.setState({ marketDataList: updatedList });
      } else {
        list.push(id);
        this.setState({ marketDataList: list });
      }
    } else if (type === "author") {
      let list: string[] = [...this.state.authorDataList];
      if (list.includes(id)) {
        const updatedList = list.filter((item) => item !== id);
        this.setState({ authorDataList: updatedList });
      } else {
        list.push(id);
        this.setState({ authorDataList: list });
      }
    } else if (type === "book") {
      let list: string[] = [...this.state.bookDataList];
      if (list.includes(id)) {
        const updatedList = list.filter((item) => item !== id);
        this.setState({ bookDataList: updatedList });
      } else {
        list.push(id);
        this.setState({ bookDataList: list });
      }
    } else {
      let list: string[] = [...this.state.campainDataList];
      if (list.includes(id)) {
        const updatedList = list.filter((item) => item !== id);
        this.setState({ campainDataList: updatedList });
      } else {
        list.push(id);
        this.setState({ campainDataList: list });
      }
    }
  };

  selectallHandler = (type: any) => {
    if (type === "title") {
      const { titleData, titleDataList } = this.state;
      const allTitlesSelected = titleDataList.length === titleData.length;
      if (allTitlesSelected) {
        this.setState({ titleDataList: [] });
      } else {
        this.setState({ titleDataList: [...titleData] });
      }
    } else if (type === "market") {
      const { marketData, marketDataList } = this.state;
      const allTitlesSelected = marketDataList.length === marketData.length;
      if (allTitlesSelected) {
        this.setState({ marketDataList: [] });
      } else {
        this.setState({ marketDataList: [...marketData] });
      }
    } else if (type === "author") {
      const { authorData, authorDataList } = this.state;
      const allTitlesSelected = authorDataList.length === authorData.length;
      if (allTitlesSelected) {
        this.setState({ authorDataList: [] });
      } else {
        this.setState({ authorDataList: [...authorData] });
      }
    } else if (type === "book") {
      const { bookData, bookDataList } = this.state;
      const allTitlesSelected = bookDataList.length === bookData.length;
      if (allTitlesSelected) {
        this.setState({ bookDataList: [] });
      } else {
        this.setState({ bookDataList: [...bookData] });
      }
    } else if (type === "campain") {
      const { campainData, campainDataList } = this.state;
      const allTitlesSelected = campainDataList.length === campainData.length;
      if (allTitlesSelected) {
        this.setState({ campainDataList: [] });
      } else {
        this.setState({ campainDataList: [...campainData] });
      }
    }
  };

  handleMetricChange = (selectedMetricName: string, metricType: string) => {
    const selectedMetric = this.state.metrics.find(
      (metric) => metric.metricName === selectedMetricName
    );

    if (selectedMetric) {
      const newState = {
        [`selectedMetric${metricType}`]: {
          metricName: selectedMetric.metricName,
          value: selectedMetric.value,
          avgvalue: selectedMetric.avgvalue,
        },
      };

      this.setState(newState);
      console.log("Selected Metric Name:", selectedMetricName);
      console.log(selectedMetric);
    }
  };

  getMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();

      const bookName = metaData?.bookFormats.map((item) =>
        item.name.replace(/[\s-]/g, "")
      );
      const marketName = metaData?.marketplaces.map((item) => item.value);
      // Get dateRange in meta
      const metaDateRangeOptions = metaData?.dateRanges.map((item) =>
        item.value.replace(/_/g, "")
      );

      this.setState({ dateRangeOptions: metaData?.dateRanges });

      this.setState({ getDateRange: metaDateRangeOptions }); //set value in state
      console.log("Date Range ", metaDateRangeOptions);
      // console.log(this.props.metricName);

      console.log("Data sent to API:", {
        marketDataList: this.state.marketDataList,
        bookDataList: this.state.bookDataList,
      });

      this.setState({
        marketData: marketName,
        filteredmarketData: marketName,
        marketDataList: [...marketName],
      });
      this.setState({
        bookData: bookName,
        filteredbookData: bookName,
        bookDataList: [...bookName],
      });
      console.log("Book names:", bookName);
      console.log("Marketplace names:", marketName);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  handleMouseDown = (e: React.MouseEvent<HTMLParagraphElement>) => {
    e.preventDefault();
    const { isClicked } = this.state;

    if (isClicked) {
      this.setState({ isClicked: false });
    } else {
      this.setState({ isClicked: true });
    }
  };
  //handleDateChange = (startDate, endDate, value) => {
  //    this.setState({
  //        dateRanges: {
  //            dateRanges: "Custom",
  //            startDate: startDate,
  //            endDate: endDate,
  //        },
  //    });
  //};
  //handleSelectChange = (dateRangeSelectedOption) => {
  //    this.setState({ dateRangeSelectedOption });
  //    console.log(dateRangeSelectedOption)
  //    if (dateRangeSelectedOption !== null && dateRangeSelectedOption !== 'Custom' && dateRangeSelectedOption !== "Time line selection") {

  //        this.setState({
  //            globalFilters: {
  //                ...this.state.globalFilters,
  //                dateRanges: {
  //                    ...this.state.globalFilters.dateRanges,
  //                    dateRange: dateRangeSelectedOption,
  //                    startDate: null,
  //                    endDate: null,
  //                },
  //            },
  //        });
  //    }

  //};
  handleClickOutside = (event) => {
    const dropdown = document.querySelector(".custom-dropdown");

    if (dropdown && !dropdown.contains(event.target)) {
      this.setState({
        showDateRangeDropdown: false,
      });
    }
  };
  //handleDateRangePickerClick = (event) => {
  //    const dateRangePickerContainer = document.querySelector(
  //        ".date-range-container"
  //    );

  //    if (
  //        dateRangePickerContainer &&
  //        dateRangePickerContainer.contains(event.target)
  //    ) {
  //        return;
  //    }

  //    this.handleClickOutside(event);
  //};

  handleCloseButtonClick = () => {
    this.setState({ isDatePickerOpen: false });
  };
  handleCloseDatePicker = () => {
    this.setState({
      isDatePickerOpen: false,
    });
  };
  handleDateRangeFilterChange = (value) => {
    this.setState({
      globalFilters: {
        ...this.state.globalFilters,
        dateRanges: value,
      },
    });
  };
  datechangecallback =()=>{
    
  }
  render() {
    // console.log();

    const { marketplaces, bookFormats } = this.state.metaData.data;
    const { isClicked, storeDefaultMetricData } = this.state;
    console.log(this.state.storeDefaultMetricData);
    const filteredOptions = this.state.getDateRange.filter(
      (option) => !["Yeartodate", "Lastyear", "Lifetime"].includes(option)
    );

    const handleClick = () => {
      this.setState(!isClicked);
    };
    const titleList = this.state.filteredTitleData.map((item, index) => {
      return (
        <ul className="p-0 m-1" key={index}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              id={item}
              checked={this.state.titleDataList.includes(item)}
              // className="form-check-input mx-5"
              className="mx-2 filter-checkbox"
              onChange={() => this.checkboxHandler("title", item)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <Tooltip
              placement="top-start"
              title={
                <React.Fragment>
                  <div>{item}</div>
                </React.Fragment>
              }
            >
              <li className="titleList-li">{item}</li>
            </Tooltip>
          </div>
          <div className="border-bottom" style={{ margin: "0px 10px" }}></div>
        </ul>
      );
    });
    const marketPlaceList = this.state.filteredmarketData.map((item, index) => {
      return (
        <ul className="p-0 m-0" key={index}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mx-2 filter-checkbox"
              checked={this.state.marketDataList.includes(item)}
              onChange={() => this.checkboxHandler("market", item)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <Tooltip
              placement="top-start"
              title={
                <React.Fragment>
                  <div>{item}</div>
                </React.Fragment>
              }
            >
              <li className="titleList-li">{item}</li>
            </Tooltip>
          </div>
        </ul>
      );
    });

    const authorList = this.state.filteredauthorData.map((item, index) => {
      return (
        <ul className="p-0 m-0" key={index}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mx-2 filter-checkbox"
              checked={this.state.authorDataList.includes(item)}
              onChange={() => this.checkboxHandler("author", item)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <Tooltip
              placement="top-start"
              title={
                <React.Fragment>
                  <div>{item}</div>
                </React.Fragment>
              }
            >
              <li className="titleList-li">{item}</li>
            </Tooltip>
          </div>
        </ul>
      );
    });
    const bookFormatList = this.state.filteredbookData.map((item, index) => {
      return (
        <ul className="p-0 m-0" key={index}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mx-2 filter-checkbox"
              checked={this.state.bookDataList.includes(item)}
              onChange={() => this.checkboxHandler("book", item)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <Tooltip
              placement="top-start"
              title={
                <React.Fragment>
                  <div>{item}</div>
                </React.Fragment>
              }
            >
              <li className="titleList-li">{item}</li>
            </Tooltip>
          </div>
        </ul>
      );
    });
    const campainplaceList = this.state.filteredcampainData.map(
      (item, index) => (
        <ul className="p-0 m-0" key={index}>
          <div className="d-flex align-items-center ">
            <input
              type="checkbox"
              checked={this.state.campainDataList.includes(item)}
              onChange={() => this.checkboxHandler("campain", item)}
              className="mx-2 filter-checkbox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <Tooltip
              placement="top-start"
              title={
                <React.Fragment>
                  <div>{item}</div>
                </React.Fragment>
              }
            >
              <li className="titleList-li">{item}</li>
            </Tooltip>
          </div>
        </ul>
      )
    );
    const dataBar = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      dataUnit: "USD",
      stacked: true,
      datasets: [
        {
          label: "Active User",
          barPercentage: 0.7,
          categoryPercentage: 0.7,
          backgroundColor: [
            "#FEF3E0",
            "#FEF3E0",
            "#FEF3E0",
            "#FEF3E0",
            "#FEF3E0",
            "#f9a31c",
          ],
          data: [8200, 7800, 9500, 5500, 9200, 9690],
        },
      ],
    };
    const { activeTab } = this.state;
    return (
      <DashboardLayout>
        <div className="main-cont-header dashboard-container">
          <div className="d-flex justify-content-between flex-wrap mb-4">
            <div>
              <div className="main-con-page-title-container">
                <div className="title">
                  <h3 className="page-title">Dashboard</h3>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap ">
            <div className="mt-2 me-3">
                <DateRangeFilter
                  options={this.state.dateRangeOptions}
                  onChange={this.handleDateRangeFilterChange}
                  value={this.state.globalFilters.dateRanges}
                  datechangeCallback ={this.datechangecallback}
                ></DateRangeFilter>
              </div>
              <div className="mt-2 ">
                <div className="download-report d-flex ">
                  <i className="dowload-icon-lotuspcc me-2"></i>
                  <span>Download Report</span>
                </div>
              </div>
             
            </div>
            <Modal
              visible={this.state.isModalVisible}
              onCancel={this.handleModalClose}
              footer={null}
            >
              <Synckdpextension />
            </Modal>
          </div>
        </div>
        {/* <br /> */}
        <div className="dashboard-container dashboard-page padding-lr-30">
          {/* <Col md={6}>
              <Row>
                <Col md={6}>
                  <RoyaltiesGross
                    filters={this.state.globalFilters}
                  ></RoyaltiesGross>
                </Col>
                <Col md={6}>
                  <DonutPie
                    defaultData="Pie0"
                    defaultMetric={storeDefaultMetricData?.Pie0 || "CTR"}
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></DonutPie>
                </Col>
              </Row>
            </Col> */}
          {/* <Col md={6}>
              <Row>
                <Col md={6}>
                  <DonutPie
                    defaultData="Pie1"
                    defaultMetric={storeDefaultMetricData?.Pie1 || "CR"}
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></DonutPie>
                </Col>
                <Col md={6}>
                  <Metrics
                    defaultData="Metric0"
                    defaultMetric={
                      storeDefaultMetricData?.Metric0 || "Royalties_Projected"
                    }
                    useTemplate="Line_ValueGrowth"
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
              </Row>
            </Col> */}

          <Row>
            <Col md={12} lg={6}>
              <Row>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"Total sales"}
                    useTemplate="Plain_ValueGrowth"
                    canSwitch={true}
                    data={dataBar}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"AD sales"}
                    useTemplate="Plain_ValueGrowth"
                    data={dataBar}
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
              </Row>
              <Row>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"Total units sold"}
                    data={dataBar}
                    useTemplate="Plain_ValueGrowth"
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"PPC units sold"}
                    data={dataBar}
                    useTemplate="Plain_ValueGrowth"
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
              </Row>
              <Row>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"ACOS"}
                    useTemplate="Plain_ValueGrowth"
                    data={dataBar}
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    data={dataBar}
                    defaultMetric={"ROAS"}
                    useTemplate="Plain_ValueGrowth"
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
              </Row>
              <Row>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"Impressions"}
                    data={dataBar}
                    useTemplate="Plain_ValueGrowth"
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
                <Col md={12} xxl={6} xl={6} lg={6}>
                  <Metrics
                    defaultData="Metric1"
                    defaultMetric={"Clicks"}
                    data={dataBar}
                    useTemplate="Plain_ValueGrowth"
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></Metrics>
                </Col>
              </Row>
            </Col>
            <Col md={12} xxl={6} xl={6} lg={6}>
              <Row>
                <Col md={12}>
                  <MultiSeriesAreaChart
                    defaultMetric1={"ACOS"}
                    defaultMetric2={"ROAS"}
                    canSwitch={true}
                    defaultChange={this.defaultChange}
                    filters={this.state.globalFilters}
                  ></MultiSeriesAreaChart>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Funnel
                    defaultChange={this.defaultChange}
                    defaultCategory={
                      storeDefaultMetricData?.Funnel || "Marketplace"
                    }
                    canSwitch={true}
                    filters={this.state.globalFilters}
                  ></Funnel>
                </Col>
              </Row>
            </Col>

            {/* <Col md={3}>
              <Metrics
                defaultData="Metric2"
                defaultMetric={storeDefaultMetricData?.Metric2 || "Clicks"}
                useTemplate="Plain_ValueGrowth"
                canSwitch={true}
                defaultChange={this.defaultChange}
                filters={this.state.globalFilters}
              ></Metrics>
            </Col> */}
            {/* <Col md={3}>
              <Metrics
                defaultData="Metric3"
                defaultMetric={storeDefaultMetricData?.Metric3 || "Orders"}
                useTemplate="Plain_ValueGrowth"
                canSwitch={true}
                defaultChange={this.defaultChange}
                filters={this.state.globalFilters}
              ></Metrics>
            </Col> */}
            {/* <Col md={3}>
              <Metrics
                defaultData="Metric4"
                defaultMetric={storeDefaultMetricData?.Metric4 || "ACOS"}
                useTemplate="Plain_ValueGrowth"
                canSwitch={true}
                defaultChange={this.defaultChange}
                filters={this.state.globalFilters}
              ></Metrics>
            </Col> */}
          </Row>
          <Row>
            <Col md={6}>
              <AsinTable data={this.state.dummyData.topAsins} />
            </Col>
            <Col md={6}>
              <Donut
                defaultChange={this.defaultChange}
                defaultMetric={storeDefaultMetricData?.Donut1 || "Spend"}
                defaultCategory={
                  storeDefaultMetricData?.Donut2 || "AdPortfolio"
                }
                defaultData="Donut"
                canSwitch={true}
                filters={this.state.globalFilters}
              ></Donut>
            </Col>
          </Row>
          <Row className="g-3">
            <Col md={12} lg={6} xxl={4}>
              <Block data={this.state.dummyData.keyword} />
            </Col>
            <Col md={12} lg={6} xxl={4}>
              <Block data={this.state.dummyData.campainsBest} />
            </Col>
            <Col md={12} lg={6} xxl={4}>
              <Block data={this.state.dummyData.campainsWorst} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {/* <MultiSeriesAreaChart
                defaultMetric1={storeDefaultMetricData?.chart1 || "CPC"}
                defaultMetric2={storeDefaultMetricData?.chart2 || "Spend"}
                canSwitch={true}
                defaultChange={this.defaultChange}
                filters={this.state.globalFilters}
              ></MultiSeriesAreaChart> */}
              {/* <Funnel
                defaultChange={this.defaultChange}
                defaultCategory={
                  storeDefaultMetricData?.Funnel || "Marketplace"
                }
                canSwitch={true}
                filters={this.state.globalFilters}
              ></Funnel> */}
            </Col>
            <Col md={6}>
              {/* <Donut
                defaultChange={this.defaultChange}
                defaultMetric={storeDefaultMetricData?.Donut1 || "Spend"}
                defaultCategory={
                  storeDefaultMetricData?.Donut2 || "Marketplace"
                }
                defaultData="Donut"
                canSwitch={true}
                filters={this.state.globalFilters}
              ></Donut> */}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {/* <HorizontalChart
                defaultChange={this.defaultChange}
                defaultCategory={
                  storeDefaultMetricData?.HorizontalChart1 || "Marketplace"
                }
                defaultMetric={
                  storeDefaultMetricData?.HorizontalChart2 || "Spend"
                }
                canSwitch={true}
                filters={this.state.globalFilters}
              ></HorizontalChart> */}
              {/* <WorldMapChart
                defaultChange={this.defaultChange}
                defaultMetric={storeDefaultMetricData?.Map || "Spend"}
                canSwitch={true}
                filters={this.state.globalFilters}
              ></WorldMapChart> */}
            </Col>
            {/* <Col md={6}>
              <Funnel
                defaultChange={this.defaultChange}
                defaultCategory={
                  storeDefaultMetricData?.Funnel || "Marketplace"
                }
                canSwitch={true}
                filters={this.state.globalFilters}
              ></Funnel>
            </Col> */}
          </Row>
          {/* <Row>
            <Col>
              <BestSeller />
            </Col>
          </Row> */}

          <Row>
            <Col md={6}>
              {/* <HeatMapChart
                defaultChange={this.defaultChange}
                defaultCategory={
                  storeDefaultMetricData?.HeatMap1 || "Marketplace"
                }
                defaultMetric={storeDefaultMetricData?.HeatMap2 || "Spend"}
                canSwitch={true}
                filters={this.state.globalFilters}
              ></HeatMapChart> */}
            </Col>
            <Col md={6}></Col>
          </Row>
          <Row>
            {/* <Col className="tabsContainer">
              <div
                className="tabsContainer-box"
                style={{ position: "relative" }}
              > */}
            {/* <Tabs
                  activeKey={activeTab}
                  onSelect={this.handleTabSelect}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                > */}
            {/* <Tab eventKey="books" title="PRODUCT">
                    {activeTab === "books" && (
                      <BooksTable
                        filterData={this.state.globalFilterFromData}
                        searchKey={this.state.searchKeyFilter}
                        filters={this.state.globalFilters}
                        filterDateRange={this.state.globalFilterDateRange}
                      />
                    )}
                  </Tab> */}
            {/* <Tab eventKey="authors" title="AUTHORS">
                    {activeTab === "authors" && (
                      <AuthorsTable
                        filterData={this.state.globalFilterFromData}
                        searchKey={this.state.searchKeyFilter}
                        filters={this.state.globalFilters}
                        filterDateRange={this.state.globalFilterDateRange}
                      />
                    )}
                  </Tab> */}
            {/* <Tab eventKey="marketplace" title="MARKETPLACE">
                    {activeTab === "marketplace" && (
                      <MarketplaceTable
                        filterData={this.state.globalFilterFromData}
                        searchKey={this.state.searchKeyFilter}
                        filters={this.state.globalFilters}
                        filterDateRange={this.state.globalFilterDateRange}
                      />
                    )}
                  </Tab> */}
            {/* </Tabs> */}
            {/* </div>
            </Col> */}
          </Row>
        </div>
      </DashboardLayout>
    );
  }
}
