import { LinearProgress } from "@mui/material";
import React from "react";
interface asin {
  data: any;
}
const AsinTable: React.FunctionComponent<asin> = (props: any) => {
  const { data } = props;
  const asinData = data.map((item: any, index: number) => {
    return (
      <div key={index} className="asin-header">
        <div>
          <div>{item.lable}</div>
          <div>{item.range}</div>
        </div>
        <div>
          {item.data.map((dataItem: any, dataIndex: any) => (
            <div
              key={dataIndex}
              className="d-flex justify-content-between align-items-center"
            >
              <div>
                <div>{dataItem.valueData}</div>
                <LinearProgress
                  variant="determinate"
                  value={dataItem.value}
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="progressBar"
                  sx={{
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: dataItem.paletteDark,
                    },
                  }}
                />
              </div>
              <div className="d-flex align-items-center gap-1">
                {dataItem.percent}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  });
  return <div className="asin-border">{asinData}</div>;
};

export default AsinTable;
