/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/copy-plus (1).svg";
import RemoveIcon from "../../../../../assets/images/icons/square-x (1).svg";
import MarketSelectData from "./MarketSelectData";
import Select, { components } from "react-select";
import { format } from "date-fns";
import Moment from "moment";
import BasicDropdownSelect from "../../../../../components/Select/Select";
import BasicLoader from "../../../../../components/BasicLoader";
import { InputNumber, TimePicker } from "antd";
import dayjs from "dayjs";
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" defaultChecked={isSelected} />
      {children}
    </components.Option>
  );
};
function AddRulesApplyto(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [durationChecked, setDurationChecked] = useState(false);
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const dateRangePickerRef = useRef(null);
  const [isDynamicDropDown, setIsDynamicDropDown] = useState({
    Campaign: [
      {
        id: 1,
        value: "Sponcered Product",
        isChecked: false,
      },
      { id: 2, value: "Sponcered Brand", isChecked: false },
      { id: 3, value: "Sponcered Display", isChecked: false },
    ],
    Targeting: [
      {
        id: 1,
        value: "Auto Campaign",
        isChecked: false,
      },
      { id: 2, value: "Manual Campaign - Keyword Targeting", isChecked: false },
      { id: 3, value: "Manual Campaign - Product Targeting", isChecked: false },
    ],
  });
  const [isDropDownData, setIsDropDownData] = useState({
    Analysis: [
      {
        id: 1,
        value: "7 Days",
      },
      { id: 2, value: "14 Days" },
      { id: 3, value: "21 Days" },
      { id: 4, value: "30 Days" },
      { id: 5, value: "60 Days" },
    ],
    Frequency: [
      {
        id: 1,
        value: "Daily",
      },
      { id: 2, value: "Weekly" },
      { id: 3, value: "Monthly" },
    ],
  });
  const [newSelectedOptions, setNewSelectedOptions] = useState();
  const [runContinuously, setRunContinuously] = useState(true);
  const [autoCampaignSelectAllStatus, setAutoCampaignSelectAllStatus] =
    useState(false);
  const [manualCampaignSelectAllStatus, setManualCampaignSelectAllStatus] =
    useState(false);
  const [autoCampaignCheckedAllData, setAutoCampaignCheckeAllData] =
    useState(false);
  const [manualCampaignCheckedAllData, setManualCampaignCheckedAllData] =
    useState(false);

  const [matchTypeCheckedlist, setMatchTypeCheckedlist] = useState([""]);

  const [matchTypeData, setMatchTypeData] = useState(false);

  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [actionCriteriaDetails, setActionCriteriaDetails] = useState([]);
  const [actionUpToCriteriaDetails, setActionUpToCriteriaDetails] = useState(
    []
  );
  const [applyTo, setApplyTo] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [marketPlacesDropdown, setMarketPlacesDropdown] = useState([]);
  const [matchTypes, setMatchTypes] = useState([]);
  const [matchTypesSelected, setMatchTypesSelected] = useState({
    autoCampaign: [],
    manualCampaign: { keywordMatch: [], productMatch: [] },
  });
  const [reportDuriationDetails, setReportDuriationDetails] = useState([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [forData, setForData] = useState("");
  const [formValues, setFormValues] = useState([
    {
      conditionFilter: "Ad Group",
      conditionName: "",
      conditionValue: "",
      Analysis: "",
      Frequency: "",
    },
  ]);
  const [selectedTime, setSelectedTime] = useState("");
  const [frequencyShow, setFrequencyShow] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    console.log(props.wizardData.applyToWizard);
    if (props.rulesMetaData) {
      let applyToDetails = props.rulesMetaData.applyToDetails;
      if (applyToDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setMatchTypesSelected(props.wizardData.applyToWizard.matchType);
        setActionCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
        setActionUpToCriteriaDetails(
          props.rulesMetaData.actionUpToCriteriaDetails
        );
        setApplyTo(applyToDetails.applyTo);
        console.log(applyToDetails.marketPlaces);

        let marketplaceList = applyToDetails.marketPlaces;
        let newMarketplaceList = [];
        console.log(marketplaceList);

        if (props.wizardData.applyToWizard.marketPlace?.length === 0) {
          if (marketplaceList?.length > 0) {
            for (let i = 0; i < marketplaceList?.length; i++) {
              let obj = {
                value: marketplaceList[i].country,
                label: marketplaceList[i].country,
              };

              if (0 < props.connectedMarketplace?.length) {
                if (
                  props.connectedMarketplace?.includes(
                    marketplaceList[i].countryCode
                  )
                ) {
                  newMarketplaceList.push(obj);
                }
              }
              setMarketPlacesDropdown(newMarketplaceList);
            }
          }
        } else {
          let selected = [];
          let applyToOptionDataMap = props.wizardData.applyToWizard.marketPlace;
          let applyToOptionData = applyToDetails.marketPlaces;
          console.log(applyToOptionDataMap);
          console.log(applyToOptionData);
          if (applyToOptionData) {
            for (let i = 0; i < applyToOptionData.length; i++) {
              let obj = {
                value: applyToOptionData[i].country,
                label: applyToOptionData[i].country,
                isChecked: applyToOptionDataMap?.includes(
                  applyToOptionData[i].country
                ),
              };
              if (0 < props.connectedMarketplace?.length) {
                if (
                  props.connectedMarketplace?.includes(
                    marketplaceList[i].countryCode
                  )
                ) {
                  selected.push(obj);
                }
              }

              setMarketPlacesDropdown(selected);
            }
          }
        }

        // console.log();
        setMatchTypes(applyToDetails.matchTypes);
        setReportDuriationDetails(props.rulesMetaData.reportDurationDetails);
        setTargetCriteriaDetails(props.rulesMetaData.targetCriteriaDetails);
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.applyToDetails) {
      if (props.metaData.text) {
        buildApplyToDropdownData(
          props.rulesMetaData.applyToDetails.applyTo,
          props.metaData
        );
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    if (props.wizardData.applyToWizard.applyTo.length > 0) {
      let applyToList = [];
      let applyToData = props.wizardData.applyToWizard.applyTo;
      if (applyToData) {
        for (let i = 0; i < applyToData.length; i++) {
          if (applyToData[i].expressions.operand) {
            let obj = {
              Analysis: applyToData[0]?.reportDuration?.dateRange,
              Frequency: applyToData[0]?.schedule?.value,
              conditionFilter: applyToData[i].expressions.operand,
              conditionName: applyToData[i].expressions.operator,
              conditionValue:
                applyToData[i].expressions.value[0].inputExpression.input,
            };
            applyToList.push(obj);
          }
        }
        if(applyToData[0]?.schedule?.value === "Weekly"){
          setFrequencyShow("Weekly");
          setSelectedDays(props.wizardData?.applyToWizard?.applyTo[0]?.schedule?.data)
        }
        if(applyToData[0]?.schedule?.value === "Monthly"){
          setFrequencyShow("Monthly");
          setSelectedDays(props.wizardData?.applyToWizard?.applyTo[0]?.schedule?.data)
        }
      }
      setFormValues(applyToList);
      const timeString = props.wizardData?.applyToWizard?.applyTo[0]?.schedule?.time;
      if (timeString) {
        setSelectedTime(timeString[0]);
      }
    

      console.log(props);
      console.log(timeString);
      if (applyToList.length < 1) {
        setFormValues([
          ...formValues,
          { conditionFilter: "", conditionName: "", conditionValue: "" },
        ]);
      }
    } else {
      if (formValues.length < 1) {
        setFormValues([
          ...formValues,
          { conditionFilter: "", conditionName: "", conditionValue: "" },
        ]);
      }
    }
  }, []);

  function wizardDataUpdate(type, data, newFormValues, updatedMatchTypes) {
    console.log(updatedMatchTypes);
    let applyTo = [];
    if (newFormValues) {
      for (let i = 0; i < newFormValues.length; i++) {
        let obj = {
          logicalAND: true,
          expressions: {
            operand: newFormValues[i].conditionFilter,
            operator: newFormValues[i].conditionName || "Equal",
            value: [
              {
                inputType: "List",
                inputExpression: {
                  operand: null,
                  input: newFormValues[i].conditionValue,
                },
              },
            ],
            unit: null,
          },
          reportDuration: {
            dateRange: newFormValues[0]?.Analysis,
            from: null,
            to: null,
          },
          schedule: {
            value: newFormValues[0]?.Frequency,
            data: selectedDays,
          time: [String(selectedTime)],
          },
        };
        applyTo.push(obj);
      }
    }
    let marketPlace;
    if (selectedOptions !== undefined) {
      marketPlace = selectedOptions
        ?.filter((item) => item)
        ?.map((item) => item.label);
    }

    let matchType = updatedMatchTypes;
    let formData = {
      type: "applyto",
      marketPlace: data,
      applyTo: applyTo,
      matchType: matchType,
    };
    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }

  let handleChange = (i, e) => {
    const value = e.target.value;
    console.log(value);
    if (value === "Weekly") {
      setFrequencyShow("Weekly");
    } else if (value === "Monthly") {
      setFrequencyShow("Monthly");
    } else {
      setFrequencyShow("");
    }
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    wizardDataUpdate("", "", newFormValues, marketPlaces);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { conditionFilter: "", conditionName: "", conditionValue: "" },
    ]);
  };

  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      conditionValue: newFormValues[i].conditionValue,
    };
    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate("", "", newFormValues);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate("", "", newFormValues);
  };

  function buildApplyToDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType = listData[i]["filterConditionType"];
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setApplyTo(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = applyTo.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      let conditionData = applyTo[findIndex];
      let optionData = conditionData.conditionTypes;
      if (optionData.length > 0) {
        return optionData.map((options, i) => (
          <option key={i} value={options.value}>
            {options.name}
          </option>
        ));
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    if(selectedTime !== "" || selectedDays.length > 0){
      wizardDataUpdate("", "", formValues, marketPlaces);
    }
    
  }, [selectedDays , selectedTime])

  const handleTimeChange = (newTime) => {
    console.log(newTime);
    const formattedTime = dayjs(newTime).format("hh:mm A");
    console.log(formattedTime);
    setSelectedTime(formattedTime);
  };

  const handleMonthChangeInterval = (e) => {
    const value = [String(e)];
    setSelectedDays(value);
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handleCheckboxChange = (selectedDay) => {
    if (selectedDays.includes(selectedDay)) {
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays.filter((day) => day !== selectedDay)
      );
    } else {
      setSelectedDays((prevSelectedDays) => [...prevSelectedDays, selectedDay]);
    }
    console.log(selectedDays);
  };

  return (
    <>
      <div className="wizard-step">
        <h5>Apply to</h5>
        {!metaDataLoading && !ruleDataLoading ? (
          <>
            <div className="card">
              <Row>
                <Col md={12}>
                  <form>
                    {formValues.map((element, index) => (
                      <div className="form-row mb-3" key={index}>
                        <div className="form-field-group">
                          <div className="applyto-form-field width-100p">
                            <select
                              className="form-select focus-border-green width-100p"
                              name="conditionFilter"
                              value={element.conditionFilter || ""}
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                            >
                              {applyTo.map((list, i) => (
                                <option value={list.name}>
                                  {list.displayName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-field width-100p ps-3">
                            <select
                              className="form-select  focus-border-green width-150p"
                              name="conditionName"
                              value={element.conditionName || ""}
                              onChange={(e) => handleChange(index, e)}
                            >
                              <SubSelectOptions
                                index={index}
                                eleData={element}
                              />
                            </select>
                          </div>
                          <div className="form-field width-100p">
                            <input
                              className="form-control width-150p"
                              type="number"
                              placeholder="enter keyword"
                              name="conditionValue"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </div>
                        <div className="form-action a-2">
                          <div
                            className="icon-box-white cursor"
                            onClick={() => duplicateFormFields(index)}
                          >
                            <img src={DuplicateIcon} alt="" />
                          </div>
                          {index !== 0 && (
                            <div
                              className="icon-box-gray cursor"
                              onClick={() => removeFormFields(index)}
                            >
                              <img src={RemoveIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </form>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link add-more-btn"
                      onClick={() => addFormFields()}
                    >
                      <i className="circle-add-icon"></i> Add more condition
                    </button>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Analysis Duration</h4>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={4}>
                  <form>
                    <div className="form-row">
                      <div className="form-field-group">
                        <div className="form-field width-40p">
                          <BasicDropdownSelect
                            data={isDropDownData.Analysis}
                            name="Analysis"
                            value={formValues[0].Analysis}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>

            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Frequency</h4>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="d-flex">
                    <form>
                      <div className="form-row ">
                        <div className="form-field-group">
                          <div className="form-field width-40p">
                            <BasicDropdownSelect
                              data={isDropDownData.Frequency}
                              name="Frequency"
                              value={formValues[0].Frequency}
                              handleChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div>
                      {frequencyShow === "Monthly" && (
                        <InputNumber
                          className="form-control ms-3"
                          style={{ width: "150px" }}
                          value={selectedDays[0] || 1}
                          onChange={handleMonthChangeInterval}
                          min={1}
                          max={31}
                        />
                      )}
                    </div>
                    <div>
                    {frequencyShow=== "Weekly" && (
                            <div className="me-2">
                            <Dropdown className="ms-3 form-select  mt-0">
                                <Dropdown.Toggle className="selectdays ">
                                  Select Days
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
          
                                  {days.map((list, i) => (
                                    <Dropdown.Item
                                      key={i}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCheckboxChange(list);
                                      }}
                                    >
                                      <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        checked={selectedDays.includes(list)}
                                        onchange={(e) => {
                                          e.stopPropagation();
                                          handleCheckboxChange(list);
                                        }}
                                      />{" "}
                                      {list}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                        
                        )}
                    </div>
                    <div className="ms-3">
                      {selectedTime !== "" && (
                        <TimePicker
                          style={{ height: "40px", width: "130px" }}
                          defaultValue={dayjs(selectedTime, "HH:mm A")}
                          onChange={handleTimeChange}
                          format={"hh:mm A"}
                        />
                      )}
                      {selectedTime === "" && (
                        <TimePicker
                          style={{ height: "40px", width: "130px" }}
                          value={null}
                          onChange={handleTimeChange}
                          format={"hh:mm A"}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <BasicLoader />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddRulesApplyto;
