import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link, Navigate, redirect } from "react-router-dom";
import SignInWithFacebook from "../../components/sign/SignInWithFacebook";
import FacebookIcon from "../../../src/assets/images/icons/logos_facebook.svg";
import SignInWithGoogle from "../../components/sign/GoogleSignIn";
interface FormState {
  errorMessage: string;
  redirect: string | null;
  email: string;
  password: string;
  showPassword: boolean;
  errors: {
    email: string;
    password: string;
  };
  loading: boolean;
}

class SignIn extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      errorMessage: "",
      redirect: null,
      email: "",
      password: "",
      showPassword: false,
      errors: {
        email: "",
        password: "",
      },
      loading: false,
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };
  handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    this.setState({ loading: true });

    const { email, password } = this.state;

    // Perform validation
    const errors = {
      email: !email
        ? "Email is required"
        : !this.validateEmail(email)
        ? "Invalid email address"
        : "",
      password: !password ? "Password is required" : "",
    };

    if (Object.values(errors).some((error) => error !== "")) {
      this.setState({ errors, loading: false });
      return;
    }

    try {
      const response = await fetch("https://api.lotusppc.com/Users/Login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, impersonateUser: true }),
      });

      const responceData = await response.json();

      if (!responceData.success) {
        console.log("error login", responceData);
        this.setState({ errorMessage: responceData.message, loading: false });
        return;
      }

      const { user, accessToken } = responceData.result;

      if (!user.isActive) {
        this.setState({
          errorMessage: "Please check email to activate your account.",
          loading: false,
        });
        return;
      }

      localStorage.setItem("userName", user.userName);
      localStorage.setItem("email", user.email);
      localStorage.setItem("userToken", accessToken);
      localStorage.setItem("planName", user.planName);
      localStorage.setItem("planStatus", "true");
      localStorage.setItem("planExpDate", user.planEXPDate);

      this.setState({ loading: false }, () => {
        this.setState({
          redirect: "/dashboard",
        });
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };

  // handleSubmit = async (event: React.FormEvent) => {
  //   this.setState({ loading: true });
  //   event.preventDefault();
  //   const { email, password } = this.state;

  //   // Perform validation
  //   const errors: FormState["errors"] = {
  //     email: "",
  //     password: "",
  //   };

  //   if (!email) {
  //     errors.email = "Email is required";
  //   } else if (!this.validateEmail(email)) {
  //     errors.email = "Invalid email address";
  //   }

  //   if (!password) {
  //     errors.password = "Password is required";
  //   }

  //   if (Object.values(errors).some((error) => error !== "")) {
  //     this.setState({ errors });
  //   } else {
  //     // Submit the form or perform other actions
  //     console.log("Form submitted");
  //   }
  //   try {
  //     const response = await fetch("https://api.lotusppc.com/Users/Login", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ email, password, impersonateUser: true }),
  //     });
  //     const responceData = await response.json();
  //     if (!responceData.success) {
  //       console.log("error login", responceData);
  //       this.setState({ errorMessage: responceData.message });
  //     } else if (!responceData.result.user.isActive) {
  //       this.setState({
  //         errorMessage: "Please check email to activate your account.",
  //       });
  //     } else {
  //       console.log("userName " + responceData.result.user.planStatus);
  //       console.log("email " + responceData.result.user.email);
  //       // console.log("response"+ responceData.result.user.);
  //       console.log("accessToken " + responceData.result.accessToken);

  //       localStorage.setItem("userName", responceData.result.user.userName);
  //       localStorage.setItem("email", responceData.result.user.email);
  //       localStorage.setItem("userToken", responceData.result.accessToken);
  //       localStorage.setItem("planName", responceData.result.user.planName);
  //       localStorage.setItem("planStatus", "true");
  //       // localStorage.setItem("planStatus", responceData.result.user.planStatus);
  //       localStorage.setItem(
  //         "planExpDate",
  //         responceData.result.user.planEXPDate
  //       );
  //       if (!responceData.result.user.planStatus) {
  //         this.setState({
  //           redirect: "/plan-expriy",
  //         });
  //       } else if (!responceData.result.user.isAWSConnected) {
  //         this.setState({
  //           redirect: "/onboarding",
  //         });
  //       } else if (!responceData.result.user.hasAdsPreferences) {
  //         this.setState({
  //           redirect: "/onboarding/success",
  //         });
  //       } else if (!responceData.result.user.kdpSync) {
  //         this.setState({
  //           redirect: "/onboarding/browser-extension",
  //         });
  //       } else if (
  //         responceData.result.user.kdpSync &&
  //         responceData.result.user.isAWSConnected
  //       ) {
  //         this.setState({
  //           redirect: "/dashboard",
  //         });
  //       } else {
  //         this.setState({
  //           redirect: "/onboarding",
  //         });
  //       }
  //       // if (
  //       //   responceData.result.user.kdpSync &&
  //       //   responceData.result.user.isAWSConnected
  //       // ) {
  //       //   this.setState({
  //       //     redirect: "/dashboard",
  //       //   });
  //       // } else {
  //       //   this.setState({
  //       //     redirect: "/onboarding",
  //       //   });
  //       // }
  //     }
  //     this.setState({ loading: false });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  validateEmail = (email: string) => {
    // Email validation logic (you can use a library or a custom regex pattern)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const { email, password, errors, showPassword } = this.state;
    const isFormValid = email !== "" && password !== "";

    return (
      <DefaultLayout>
        <div className="contanier-type1">
          <div className="auth-login-icon google-login-icon">
            {/*<img src={GoogleIcon} alt="google icon" />
            <span>Continue with Google</span>*/}
            <div className="googleicon">
              <SignInWithGoogle />
            </div>
          </div>
          {/* <div className="auth-login-icon facebook-login-icon mb-4">
            <div className="d-flex align-items-center justify-content-center custom-fb-icon">
              <img src={FacebookIcon} alt="facebook icon" className="me-3"/>
              <span>Continue with Facebook</span>
            </div> */}
          {/* <SignInWithFacebook/> */}
          {/* </div> */}
          <div className="mb-4"></div>
          <form onSubmit={this.handleSubmit}>
            <div className="separator mb-4">Or</div>
            {this.state.errorMessage && (
              <p className="text-center text-red">{this.state.errorMessage}</p>
            )}
            <div className="mb-3">
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={this.handleInputChange}
              />
              {errors.email && (
                <span className="validation-error">{errors.email}</span>
              )}
            </div>
            <div className="mb-0 password-cont">
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={this.handleInputChange}
              />
              <span
                className="show-password"
                onClick={this.togglePasswordVisibility}
              >
                {showPassword ? (
                  <i className="fas fa-eye-slash"></i>
                ) : (
                  <i className="fas fa-eye"></i>
                )}
              </span>
              {errors.password && (
                <span className="validation-error">{errors.password}</span>
              )}
            </div>
            <div className="mb-1">
              <p className="forgot-password text-right">
                <Link to={"/forgot-password"}>Forgot Password?</Link>
              </p>
            </div>
            {/* <div className="mb-4">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input login-checkbox"
                  id="customCheck1"
                />
                <label
                  className="custom-control-label ml-3"
                  htmlFor="customCheck1"
                >
                  Remember me
                </label>
              </div>
            </div> */}
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isFormValid}
              >
                {!this.state.loading ? (
                  <div className="button-text">Log in</div>
                ) : (
                  <div className="spinner-box">
                    <div className="pulse-container">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                )}
              </button>
            </div>
            <div className="new-create-account">
              <p className="text-center">
                No account? <Link to={"/sign-up"}>Sign Up</Link>
              </p>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}
export default SignIn;
