import React from "react";

interface template {
  data: any;
}
const Template: React.FunctionComponent<template> = (props: any) => {
  const { data } = props;

  return (
    <div className="template-card">
      <div>
        <p>{data.lable}</p>
        {/* <i className={data.icon}></i> */}
      </div>
      <div className="template-text">
        {data.criteria.map((item: any, index) => {
          return <span>{item.criteria}</span>;
        })}
      </div>
    </div>
  );
};

export default Template;
