import React from "react";

function BasicDropdownSelect(props) {
  const { data, name, handleChange, value } = props;
  return (
    <select
      style={{
        display: "flex",
        width: "200px",
        height: "40px",
        padding: "8px 12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "72px",
        borderRadius: "4px",
        border: "1px solid var(--AiGray-300, #D1D5DB)",
      }}
      className="form-select  focus-border-green"
      name={name}
      onChange={(e) => handleChange(0, e)}
      value={value}
    >
      <option>--Select--</option>
      {data &&
        data.map((list, i) => (
          <option key={i} value={list.value}>
            {list.value}
          </option>
        ))}
    </select>
  );
}

export default BasicDropdownSelect;
