import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link, Navigate, redirect } from "react-router-dom";
import logo from "../../assets/brand/logo-resize.png";
import SignInWithFacebook from "../../components/sign/SignInWithFacebook";
import FacebookIcon from "../../../src/assets/images/icons/logos_facebook.svg";
import SignInWithGoogle from "../../components/sign/GoogleSignIn";
import { notification } from "antd";
import { toast } from "react-toastify";
interface FormState {
  errorMessage: string;
  redirect: string | null;
  email: string;
  password: string;
  showPassword: boolean;
  errors: {
    email: string;
    password: string;
  };
  loading: boolean;
}

class SignIn extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      errorMessage: "",
      redirect: null,
      email: "",
      password: "",
      showPassword: false,
      errors: {
        email: "",
        password: "",
      },
      loading: false,
    };
  }

  componentDidMount() {
    localStorage.clear()
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };
  
  handleSubmit = async (event: React.FormEvent) => {
    this.setState({ loading: true });
    event.preventDefault();
    const { email, password } = this.state;
    const errors: FormState["errors"] = {
      email: "",
      password: "",
    };
    if (!email) {
      errors.email = "Email is required";
    } else if (!this.validateEmail(email)) {
      errors.email = "Invalid email address";
    }

    if (!password) {
      errors.password = "Password is required";
    }
    if (Object.values(errors).some((error) => error !== "")) {
      this.setState({ errors, loading: false });
    } else {
      try {
        const response = await fetch("https://api.lotusppc.com/Users/Login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        const responseData = await response.json();
        localStorage.setItem("userToken", responseData.result.accessToken);
        if (!responseData.success) {
          console.log("Login error:", responseData.message);
          this.setState({
            errorMessage: responseData.message,
            loading: false,
          });
        } else {
          if(responseData.result.user.isEmailVerified === true){
          console.log("Login successful");
          const { userId } = responseData.result.user;
          localStorage.setItem("userId", userId);
          localStorage.setItem("userName" ,responseData.result.user.userName)
          localStorage.setItem("userEmail" ,responseData.result.user.email)
          localStorage.setItem("adsConnected", responseData.result.user.adsConnected)
          if(responseData.result.user.adsConnected === true){
            this.setState({
              redirect: "/dashboard",
              loading: false,
            });
          }
          else{
            this.setState({
              redirect: "/my-account/amazon-connection",
              loading: false,
            });
          }
  
         
        
        }
        else{
          this.setState({
            loading: false,
          });
          toast("This user email activation is not complete. Please activate your email after logging in."); 
        }
        }
      } catch (error) {
        console.error("Error:", error);
        toast("Invalid email or password");  
        this.setState({ loading: false });
      }
    }
  };


  validateEmail = (email: string) => {
    // Email validation logic (you can use a library or a custom regex pattern)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  forget= ()=>{
    localStorage.setItem("userEmail" , this.state.email)
    localStorage.setItem("action" , "ForgetPassword")
  }
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const { email, password, errors, showPassword } = this.state;
    const isFormValid = email !== "" && password !== "";

    return (
      <DefaultLayout>
        <div className="contanier-type1">
          <div className="text-center">
            <img src={logo} alt="logo" />
          </div>
          <div className="mb-3"></div>
          <form onSubmit={this.handleSubmit}>
            {/* <div className="separator mb-4">Or</div> */}
            {this.state.errorMessage && (
              <p className="text-center text-red">{this.state.errorMessage}</p>
            )}
            <div className="mb-3">
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={this.handleInputChange}
              />
              {errors.email && (
                <span className="validation-error">{errors.email}</span>
              )}
            </div>
            <div className="mb-0 password-cont">
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={this.handleInputChange}
              />
              <span
                className="show-password"
                onClick={this.togglePasswordVisibility}
              >
                {showPassword ? (
                  <i className="fas fa-eye-slash"></i>
                ) : (
                  <i className="fas fa-eye"></i>
                )}
              </span>
              {errors.password && (
                <span className="validation-error">{errors.password}</span>
              )}
            </div>
            <div className="mb-1">
              <p className="forgot-password">
                <Link to={"/forgot-password"} onClick={this.forget}>Forgot Password?</Link>
              </p>
            </div>

            <div className="d-grid mt-3">
              <button type="submit" className="btn btn-primary login">
                {!this.state.loading ? (
                  <div className="button-text">Sign in</div>
                ) : (
                  <div className="spinner-box">
                    <div className="pulse-container">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                )}
              </button>
            </div>
            <div className="new-create-account">
              <p className="text-center">
                No account? <Link to={"/sign-up"}>Sign Up</Link>
              </p>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}
export default SignIn;
