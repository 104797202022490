/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "../index.css";

import { Link } from "react-router-dom";
import { event } from "jquery";
import { IoTicket } from "react-icons/io5";
import Template from "../../../../components/Template/Template";
import { icon } from "@fortawesome/fontawesome-svg-core";
function AddRules(props) {
  const criteria = [
    {
      id: 1,
      lable: "Good ACOS Boost(LGD,DD,Aqua)",
      criteria: [{ criteria: "ROAS > 3.33%" }, { criteria: "Orders > = 2" }],
      icon: "chart-icon",
    },
    {
      id: 2,
      lable: "Low Impression Boost(Test)",
      criteria: [
        { criteria: "AdGroup Status = Enable" },
        { criteria: "Target Status = Enabled" },
        { criteria: "Impression <= 100" },
      ],
      icon: "down-icon",
    }

  
  ];
  let loadPanel = (panelName, event) => {
    props.parentCallback(panelName);
    event.preventDefault();
  };
  return (
    <>
      <div className="add-rule-container">
        <Row className="justify-content-between">
          <Col>
            <div className="d-flex justify-content-between">
              <div>
                <h4>Create New Rule</h4>
                <p>
                  You have the option to select a rule from our provided
                  template or devise your own custom rule.
                </p>
              </div>
              <div
                className="scratch"
                onClick={(eve) => loadPanel("AddNewRules", eve)}
              >
                <div className="d-flex justify-content-center">
                  <IoTicket fontSize={"25px"} />
                </div>
                <h5>Create From Blank</h5>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-gap-3 mt-3 mb-5">
          <h5>Our template</h5>
          {criteria.map((item: any, index) => {
            return (
              <Col md={6} xl={3} lg={4}>
                <Template data={item} />
              </Col>
            );
          })}
          {/* <Col md={4}>
            <div className="rule-box">
              <div className="box-header">Good ACOS Boost(LGD,DD,Aqua)</div>
              <div className="box-body">
                <span>ROAS {">"} 3.33%</span>
                <br />
                <span>Orders {">"} = 2</span>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="rule-box">
              <div className="box-header">Increase ROI</div>
              <div className="box-body"></div>
            </div>
          </Col>
          <Col md={4}>
            <div className="rule-box">
              <div className="box-header">Negative Targets</div>
              <div className="box-body"></div>
            </div>
          </Col> */}
          {/* <h5 className="mt-3">Or you can create your own rule</h5>
          <Col md={4}>
            <div
              className="rule-box"
              onClick={(eve) => loadPanel("AddNewRules", eve)}
            >
              <div className="box-header">Create Your Own Rule</div>
              <div className="box-body p-0">
                <p>You can create your own rules according to what you want.</p>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default AddRules;
