import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/sidebar-logo.png";
import Logo1 from "../../assets/images/sidebar-logo-resize.png";
import menuItems from "./menu.json";
import menuItemsBottom from "./menu-bottom.json";
import sideHrLine from "../../assets/images/side-menu-hr-line.svg";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log();

  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);

  const mobileViewSidebar = [
    {
      name: "Products",
      to: "/product",
      icon: "bookshelfs-icon",
      active: "product",
      hasSubMenu: false,
      submenuList: null,
    },
    {
      name: "Automation",
      to: "/automation-rules",
      icon: "automation-rule",
      active: "automation-rules",
      hasSubMenu: false,
      submenuList: null,
    },
    {
      name: "Campaign Manager",
      to: "/ads-campaign-manager",
      icon: "creation",
      active: "ads-campaign-manager",
      hasSubMenu: false,
      submenuList: null,
    },
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: "dashboard-icon",
      active: "dashboard",
      hasSubMenu: false,
      submenuList: null,
    },

    {
      name: "Sales",
      to: "#",
      icon: "manager",
      active: "sales",
      hasSubMenu: false,
      submenuList: null,
    },
    {
      name: "Inventory Forecasting",
      to: "#",
      icon: "inventory-icon",
      active: "inventory",
      hasSubMenu: false,
      submenuList: null,
    },
    {
      name: "Settings",
      to: "/my-account/profile",
      icon: "settings-icon",
      active: "my-account",
      submenuList: null,
    },
  ];

  function collapseMenu() {
    const ele = document.getElementById("sideMenubar");
    // Add logic to handle the opening/closing of the sidebar
    // ele.classList.toggle('open');
  }

  function toggleSubmenu(index: number, hasSubMenu: boolean | undefined) {
    if (hasSubMenu) {
      setOpenSubMenu((prevIndex) => (prevIndex === index ? null : index));
    }
  }


  return (
    <div>
      <div id="sideMenubar" className="sidebar" onClick={collapseMenu}>
        <div className="header-wrapper">
          <div className="brand-logo">
            <img src={Logo} alt="logo" className="logosmall" />
            <img src={Logo1} alt="logo" className="logolarge" />
          </div>
        </div>
        <div>
          <nav>
            <ul className="menus">
              {menuItems.map((menu, index) => {
                const submenuList = menu.submenuList;
                const isActive =
                  splitLocation[1] === menu.active ||
                  (menu.hasSubMenu && openSubMenu === index);

                return (
                  <li
                    className={
                      "menu-items" +
                      (isActive ? " active" : "") +
                      (menu.hasSubMenu ? " has-submenu" : "")
                    }
                    key={index}
                    onClick={() => toggleSubmenu(index, menu.hasSubMenu)}
                  >
                    {isActive && <div className="active-indicator"></div>}
                    <Link to={menu.to}>
                      <i className={menu.icon}></i>
                      <span>{menu.name} </span>
                      <span className="activeicons"> </span>
                    </Link>
                  </li>
                );
              })}
            </ul>

            <ul className="menus bottom ">
              <div className="hr-line-conainer">
                <img src={sideHrLine} alt="hr line" />
              </div>

              {menuItemsBottom.map((menu: any, index) => {
                const isActive: any = splitLocation[1] === "my-account";
                console.log(splitLocation[1]);

                return (
                  <li
                    className={
                      "menu-items" +
                      (isActive ? " active" : "") +
                      (menu.hasSubMenu ? " has-submenu" : "")
                    }
                    key={index}
                    onClick={() => toggleSubmenu(index, menu.hasSubMenu)}
                  >
                    {isActive && <div className="active-indicator"></div>}
                    <Link to={menu.to}>
                      <i className={menu.icon}></i>
                      <span>{menu.name}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>

      <div className="mobile-sidebar">
        <div>
          <ul className="mobile-sidebar-menu">
            {mobileViewSidebar.map((menu, index) => {
              const isActive =
              splitLocation[1] === menu.active ||
              (menu.hasSubMenu && openSubMenu === index);

              return (
                <li
                  className={
                    "mobile-sidebar-menuitems" +
                    (isActive ? " active" : "") +
                    (menu.hasSubMenu ? " has-submenu" : "")
                  }
                  key={index}
                >
                  <Link to={menu.to}>
                    <i className={menu.icon}></i>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
