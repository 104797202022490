import React, { useEffect, useState, ChangeEvent } from "react";
import { useLocation } from "react-router-dom";
import "./myConnection.css";
import {
  getAmazonConnection,
  getAdsConnection,
  postAmazonConnection,
} from "../../../services/CommanService";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import flags from "../../../components/Dashboard/flags";
import ReactCountryFlag from "react-country-flag";
import DropdownCountryBasic from "../../../components/Common/CountryDropdown";
import axios from "axios";

interface HeaderProps {
  setIsHeaderDataHandler: (value: any, data: any) => void;
}

const MyConnection: React.FunctionComponent = () => {
  const location = useLocation();
  const accessToken = localStorage.getItem("userToken");
  const [isRedirect, setIsRedirect] = useState<any>(null);
  const [selectedRegion, setSelectedRegion] = useState("Europe");
  const [sellerMarketPlace, setSellerMarketPlace] = useState("");
  const [vendorMarketPlace, setVendorMarketPlace] = useState("");
  const [ppcMarketPlace, setPpcMarketPlace] = useState("EU");
  const [marketPlaces, setMarketPlaces] = useState<string[]>([]);
  const [country, setCountry] = useState<string[]>([]);

  const [marketPlacesCode, setMarketPlacesCode] = useState<string[]>([]);
  const [connectionData, setConnectionData] = useState<any[]>([]);
  const queryParams = new URLSearchParams(location.search);
  const [rows, setRows] = useState<any[]>([]);
  const spapi_oauth_code = queryParams?.get("spapi_oauth_code");
  const selling_partner_id = queryParams?.get("selling_partner_id");
  const code = queryParams?.get("code");
  const name = queryParams?.get("name");
  const scope = queryParams?.get("scope");
  const state = queryParams?.get("state");
  const adsConnected = localStorage.getItem("adsConnected");
  const userID = localStorage.getItem("userId");

  // const connection = async (data: any) => {
  //   try {
  //     const response = await getAmazonConnection(data, accessToken);
  //     const responseData = await response.json();
  //     if (response.ok) {
  //       setIsRedirect(responseData.result);
  //     }
  //   } catch (error) {
  //     console.error("Error in getAmazonConnection:", error);
  //     throw error;
  //   }
  // };

  // const postConnection = async () => {
  //   const request = {
  //     oAuthCode: spapi_oauth_code,
  //     sellingPartnerId: selling_partner_id,
  //     state: state,
  //   };
  //   try {
  //     const response = await postAmazonConnection(request, accessToken);
  //     const responseData = await response.json();
  //     if (response.ok) {
  //       console.log(responseData);
  //       setConnectionData(responseData.result);
  //     }
  //   } catch (error) {
  //     console.error("Error in postAmazonConnection:", error);
  //     throw error;
  //   }
  // };

  // const adsConnection = async (data: any) => {
  //   try {
  //     const response = await getAdsConnection(data, accessToken);
  //     const responseData = await response.json();
  //     if (response.ok) {
  //       setIsRedirect(responseData.result);
  //     }
  //   } catch (error) {
  //     console.error("Error in getAdsConnection:", error);
  //     throw error;
  //   }
  // };

  // const postAdsConnectionData = async (code, scope, state, accessToken) => {
  //   console.log("Parameters:", code, scope, state, accessToken);
  //   const userId = localStorage.getItem("userId");
  //   const request = {
  //     userId: userId,
  //     oAuthCode: code,
  //     name: name,
  //     scope: scope,
  //     state: state,
  //   };
  //   try {
  //     const response = await fetch(
  //       "https://api.lotusppc.com/api/Amazon/AdsCallback",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //         body: JSON.stringify(request),
  //       }
  //     );

  //     const responseData = await response.json();

  //     if (response.ok) {
  //       console.log("Ads connection successfully posted:", responseData);
  //       setConnectionData(responseData.result);
  //     } else {
  //       console.error("Failed to post Ads connection data:", responseData);
  //       throw new Error(response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error posting Ads connection data:", error);
  //     throw error;
  //   }
  // };

  // useEffect(() => {
  //   console.log("Effect triggered with:", { code, scope, state });
  //   if (code && scope && state) {
  //     postAdsConnectionData(code, scope, state, accessToken)
  //       .then(() => {
  //         console.log("postAdsConnectionData successfully called");
  //       })
  //       .catch((error) => {
  //         console.error("Failed to call postAdsConnectionData:", error);
  //       });
  //   } else {
  //     console.warn("Not all required parameters are available.");
  //   }
  // }, [code, scope, state]);

  // useEffect(() => {
  //   if (isRedirect) {
  //     window.open(isRedirect, "_blank");
  //   }
  // }, [isRedirect]);

  // useEffect(() => {
  //   if (spapi_oauth_code && state && selling_partner_id) {
  //     postConnection();
  //   }
  // }, [spapi_oauth_code, state, selling_partner_id]);

  const handleSetData = (value: any, data: any) => {
    // Implementation of setIsHeaderDataHandler
    console.log("Setting data with value:", value, "and data:", data);
  };

  // Props object that adheres to HeaderProps interface
  const props: HeaderProps = {
    setIsHeaderDataHandler: handleSetData,
  };

  useEffect(() => {
    if (userID) {
      axios
        .get(`https://api.lotusppc.com/Users/${userID}`)
        .then((res) => {
          console.log(res.data.result.connection[0].adsConnect.adsProfiles);
          setRows(res.data.result.connection[0].adsConnect.adsProfiles);
          const ConnectedValue =
            res.data.result.connection[0].adsConnect.adsProfileIds;
          const value = ConnectedValue.map((data) => data.profileId);
          setConnectionData(value);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.lotusppc.com/MarketPlace/RegionBasedMarketPlace?Region=${selectedRegion}`
        );
        const data = await response.json();
        if (data.success) {
          const data1 = data.marketPlaces;
          const value = data1.map((data) => ({
            countryName: data.split(" (")[0],
            countryCode: data.split(" (")[1].replace(")", ""),
          }));
          console.log(value);
          // setPpcMarketPlace(data1[0]);
          setMarketPlacesCode(marketPlacesCode);
          setMarketPlaces(data1);
        } else {
          console.error("Failed to fetch marketplaces:", data.message);
        }
      } catch (error) {
        console.error("Error fetching marketplaces:", error);
      }
    };

    fetchData();
  }, [selectedRegion]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;

    if (name === "regionSelect") {
      setSelectedRegion(value);
    } else if (name === "sellerMarketPlaceSelect") {
      setSellerMarketPlace(value);
    } else if (name === "vendorMarketPlaceSelect") {
      setVendorMarketPlace(value);
    } else if (name === "ppcMarketPlaceSelect") {
      console.log(value);
      setPpcMarketPlace(value);
    }
  };

  const handleIconClick = (marketPlace: string) => {
    console.log(marketPlace);
    const match = marketPlace.match(/\((.*)\)/);
    const countryCode = match ? match[1] : "";
    const userId = localStorage.getItem("userId");
    if (marketPlace) {
      fetch(
        `https://api.lotusppc.com/api/Amazon/AuthorizationURL?UserId=${userId}&Region=${marketPlace}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          window.location.href = data.result.connectUrl;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleSwitchToggle = async (row, e) => {
    console.log("Row data:", row);
    console.log(e.target.checked);
    const { checked } = e.target;
    if (checked) {
      setConnectionData((prevData) => [...prevData, String(row.profileId)]);
    } else {
      setConnectionData((prevData) =>
        prevData.filter((id) => id !== String(row.profileId))
      );
    }
    console.log(connectionData);

    const userId = localStorage.getItem("userId");
    const accessToken = localStorage.getItem("userToken");

    const requestData = {
      userId: userId,
      profileId: String(row.profileId),
      profileName: row.name,
      countryCode: row.countryCode,
      type: row.type,
      accountId: row.accountId,
      isActive: e.target.checked,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestData),
    };
    try {
      const response = await fetch(
        "https://api.lotusppc.com/Users/ConnectedProfiles",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log("Data received from API:", responseData);

      const updatedRows = connectionData.map((r) =>
        r.profileId === row.profileId
          ? { ...r, dataConnections: requestData.isActive }
          : r
      );
      // setConnectionData(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns: GridColDef[] = [
    {
      field: "countryCode",
      headerName: "Country Code",
      width: 150,
   
      renderCell: (params) => (
        <div>
          {params.row.countryCode === "UK" ? (
            <ReactCountryFlag
              countryCode="GB"
              style={{
                width: "23px",
                height: "23px",
                borderRadius: "5px",
              }}
              svg
            />
          ) : (
            <ReactCountryFlag
              countryCode={params.row.countryCode}
              style={{
                width: "23px",
                height: "23px",
                borderRadius: "5px",
              }}
              svg
            />
          )}

          <span className="ms-2">{params.row.countryCode}</span>
        </div>
      ),
    },
    { field: "name", headerName: "Name", minWidth: 190, flex: 1 },
    { field: "profileId", headerName: "Profile ID", minWidth: 190, flex: 1 },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div>{capitalizeFirstLetter(params.row.type)}</div>
      ),
    },
    {
      field: "dataConnections",
      headerName: "Data Connections",
      width: 150,
      renderCell: (params) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckChecked_${params.row.id}`}
            checked={connectionData.includes(String(params.row.profileId))}
            onChange={(e) => handleSwitchToggle(params.row, e)}
          />
        </div>
      ),
    },
  ];

  const onChange = () => {};

  // useEffect(() => {
  //   const updatedRows = connectionData.map((data, index) => ({
  //     id: index,
  //     accountId: data.accountId,
  //     countryCode: data.countryCode,
  //     name: data.name,
  //     profileId: data.profileId,
  //     type: data.type,
  //     dataConnections: data.dataConnections,
  //   }));
  //   setRows(updatedRows);
  // }, [connectionData]);

  return (
    <div className="myConnetion">
      <div>
        <div>
          <div className="d-flex  justify-content-between">
            <div>
              <h5>Connect With Amazon Account</h5>
            </div>
            <div>
              {/* <select
                name="regionSelect"
                className="p-1 rounded select-market"
                value={selectedRegion}
                onChange={handleSelectChange}
              >
                <option value="Europe">Europe</option>
                <option value="North America">North America</option>
                <option value="Far East">Far East</option>
              </select> */}
            </div>
          </div>

          <div className="Ads mb-3">
            <div>
              <h6 className="mt-3">Connect Your PPC Account</h6>
              <div className="ads">
                <div>
                  <div>
                    <span>
                      You can connect your Amazon PPC Account. Not connected yet
                    </span>
                  </div>
                  <div>
                    <div className="d-flex justify-content-evenly align-items-center flex-wrap mt-2 ">
                      <div>
                        <select
                          name="ppcMarketPlaceSelect"
                          className="p-1 rounded connection-select me-3"
                          value={ppcMarketPlace}
                          onChange={handleSelectChange}
                        >
                          <option value="EU">Europe</option>
                          <option value="NA">North America</option>
                          <option value="FE">Far East</option>
                        </select>
                      </div>
                      <div>
                        <i
                          className="amazon-connection"
                          onClick={() => handleIconClick(ppcMarketPlace)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {adsConnected === "true" && (
              <Col md={12} className="mt-3">
                <DataGrid
                  rows={rows.map((row, index) => ({ ...row, id: index }))}
                  columns={columns}
                  checkboxSelection={false}
                  hideFooter={true}
                  rowHeight={50}
                />
              </Col>
            )}
          </div>

          {/* <h6>Connect Your Seller Account</h6>
          <div className="Seller">
            <div>
              <div>
                <span>
                  You can connect your Amazon Seller Account. Not connected yet
                </span>
              </div>
              <div>
                <div className="d-flex justify-content-evenly align-items-center gap-3">
                  <div>
                    <select
                      name="sellerMarketPlaceSelect"
                      className="p-1 rounded connection-select"
                      value={sellerMarketPlace}
                      disabled
                      onChange={handleSelectChange}
                    >
                      {marketPlaces.length > 0 ? (
                        marketPlaces.map((marketPlace, index) => {
                          // const match = marketPlace.match(/\((.*)\)/);
                          // const countryCode = match ? match[1] : "";
                          return (
                            <option key={index} value={marketPlace}>
                              {marketPlace.split(" (")[0]}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No options available</option>
                      )}
                    </select>
                  </div>
                  <div>
                    <i
                      className="amazon-connection"
                      onClick={() => handleIconClick(sellerMarketPlace)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div>
          <h6>Connect Your Vendor Account</h6>
          <div className="Vendor">
            <div>
              <div>
                <span>
                  You can connect your Amazon Vendor Account. Not connected yet
                </span>
              </div>
              <div>
                <div className="d-flex justify-content-evenly align-items-center gap-3">
                  <div>
                    <select
                      name="vendorMarketPlaceSelect"
                      className="p-1 rounded"
                      value={vendorMarketPlace}
                      disabled
                      onChange={handleSelectChange}
                    >
                      {marketPlaces.length > 0 ? (
                        marketPlaces.map((marketPlace, index) => {
                          // const match = marketPlace.match(/\((.*)\)/);
                          // const countryCode = match ? match[1] : "";
                          return (
                            <option key={index} value={marketPlace}>
                              {marketPlace}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No options available</option>
                      )}
                    </select>
                  </div>
                  <div>
                    <i
                      className="amazon-connection"
                      onClick={() => handleIconClick(vendorMarketPlace)}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MyConnection;
