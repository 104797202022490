import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../index.css";
import LinkIcon from "../../../../../assets/images/icons/link-icon.svg";
import BackArrow from "../../../../../assets/images/icons/back-arrow-icon.svg";
import ThreeDots from "../../../../../assets/images/icons/more-action-icon.svg";
import DeleteIcon from "../../../../../assets/images/icons/trash-icon.svg";
// import Moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import InfoIcon from "../../../../../assets/images/icons/info-circle-icon.svg";
import same from "../../../../../assets/images/icons/same.png";
import individual from "../../../../../assets/images/icons/Individual.png";
import individualcampaign from "../../../../../assets/images/icons/individualcampaign.png";
import { getWidth } from "rsuite/esm/DOMHelper";
import { wrap } from "module";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, CircularProgress } from "@mui/material";
interface isAutoData {
  isAutoState?: any;
}
const ManualCampaign: React.FunctionComponent<isAutoData> = ({
  isAutoState,
}) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const params = useParams();
  const navigate = useNavigate();
  const CreateFormData = {
    campaignType: "ManualCampaign",
    campaignName: "",
    asins: null,
    matchType: "SingleCampaignWithSingleAdgroup",
    placement: {
      topOfSearch: "",
      productDetailPages: "",
      restOfSearch: "",
    },
    settings: {
      portfolio: null,
      startDate: `${year}-${month}-${day}`,
      endDate: `${year}-${month}-${day}`,
      campaignBiddingStrategy: "",
      dailyBudget: "",
    },
    marketPlaces: [],
    negativeKeywordTargeting: null,
    dynamicBidding: {
      defaultBid: "",
      targetingBid: "",
    },
    targets: [
      {
        targetType: "productTargeting",
      },
      {
        targetType: "keywordTargeting",
      },
    ],
    postToAmazon: true,
  };

  const matchTypesList = [
    { id: 1, nameid: "broad", title: "Broad", isChecked: true, name: "" },
    { id: 2, nameid: "phrase", title: "Phrase", isChecked: true, name: "" },
    {
      id: 3,
      nameid: "exact",
      title: "Exact",
      isChecked: true,
      name: "",
    },
  ];
  const isDefaultError = {
    campaignBiddingStrategy: true,
    dailyBudget: true,
    targetingBid: true,
    defaultBid: true,
    marketPlaces: true,
  };
  const [iscreateForm, setIsCreateForm] = useState<any>(CreateFormData);
  const [isMatchList, setIsMatchList] = useState<any>(matchTypesList);
  const [isError, setIsError] = useState<any>(isDefaultError);
  const [isSelectedCampaign, setIsSelectedCampaign] = useState(1);
  const [isSelected, setIsSelected] = useState(null);
  const [isAdKeywords, setIsAdKeywords] = useState<any>([]);
  const [isAdKeywordType, setIsAdKeywordType] = useState<any>([]);
  const [isProductTargeting, setIsProductTargeting] = useState<any>([]);
  const [isKeywordTargeting, setIsKeywordTargeting] = useState<any>([]);
  const [isAddKeyWordTypeManualList, setIsAddKeyWordTypeManualList] =
    useState("product");
  const [isAddTempAdKeywords, setIsAddTempAdKeywords] = useState("");
  const [isCategoryResultList, setIsCategoryResultList] = useState<any>([]);
  const [isCategorySearch, setIsCategorySearch] = useState("");
  const [isMarketPlace, setIsMarketPlace] = useState([]);
  const [isTabChange, setIsTabChange] = useState("keywordTargeting");
  const [isMarketPlacesSelected, setIsMarketPlacesSelected] = useState<any>([]);
  const [isCategoryList, setIsCategoryList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isimgstate, setIsImgState] = useState(1);
  const [isPortfolio, setIsPortfolio] = useState([]);
  const [isHovered, setIsHovered] = useState(null);
  //   const [isAddKeyWordCheck, setIsAddKeyWordCheck] = useState("category");
  const [isDate, setIsDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  //////////////FUNCTION///////////////
  useEffect(() => {
    if (params.keyword === "productTargeting")
      setIsTabChange("productTargeting");
  }, []);

  useEffect(() => {
    getMarketplaceData();
    const encodedString: any = params.asin;
    const decodedString = decodeURIComponent(encodedString);
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        asins: decodedString.split(","),
      };
    });
    if (params.id) {
      const id: any = "id";
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          [id]: params.id,
        };
      });
      getCampainById(params.id);
    }
  }, []);

  useEffect(() => {
    getPortfolioData();
  }, [isMarketPlacesSelected]);
  const handleMouseOver = (i) => {
    setIsHovered(i);
  };

  const handleMouseOut = () => {
    setIsHovered(null);
  };
  const getPortfolioData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.lotusppc.com/AdsManager/Portfolios";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        mrketplaces: isMarketPlacesSelected,
      }),
    });

    try {
      const responceData = await response.json();
      setIsPortfolio(responceData?.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getMarketplaceData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.lotusppc.com/AmazonConnection";
    const requestOptions = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (response.ok) {
        let result = responceData.result.filter(
          (item: any) => item.dataConnections
        );
        setIsMarketPlace(result);
      } else {
        // toast("Unable to create");
      }
      // setRedirect("/onboarding");
    } catch (error) {
      console.error("Error fetching data:", error);
      // toast("Error On Creating");
    }
  };
  const getCampainById = async (id: any) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.lotusppc.com/AdsManager/" + id;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });

    try {
      const responceData = await response.json();
      setIsMarketPlacesSelected({
        profile: responceData.result.marketPlaces.map(
          (item, index) => item.profileId
        ),
        countryCode: responceData.result.marketPlaces.map(
          (item, index) => item.countryCode
        ),
      });
      setIsSelected(responceData.result.settings.campaignBiddingStrategy);
      setIsSelectedCampaign(
        responceData.result.matchType === "MultipleCampaigns" ? 0 : 1
      );
      setIsImgState(
        responceData.result.matchType === "SingleCampaignWithMultipleAdgroup"
          ? 0
          : 1
      );
      setIsProductTargeting(
        responceData.result.targets.find(
          (target: any) => target.targetType === "productTargeting"
        )?.targetData || []
      );
      setIsKeywordTargeting(
        responceData.result.targets.find(
          (target: any) => target.targetType === "keywordTargeting"
        )?.targetData || []
      );
      setIsMatchList((prev: any) => {
        return prev?.map((item, index) => {
          if (item.nameid === "broad") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.broad,
            };
          } else if (item.nameid === "phrase") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.phrase,
            };
          } else if (item.nameid === "exact") {
            return {
              ...item,
              name: responceData.result.dynamicBidding.exact,
            };
          }
          return item;
        });
      });

      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          campaignName: responceData.result.campaignName.expression,
          placement: responceData.result.placement,
          marketPlaces: responceData.result.marketPlaces,
          settings: responceData.result.settings,
          dynamicBidding: {
            ...prev.dynamicBidding,
            defaultBid: responceData.result.dynamicBidding.defaultBid,
            targetingBid: responceData.result.dynamicBidding.targetingBid,
          },
          targets: prev.targets?.map((item, index) =>
            item.targetType === "productTargeting"
              ? {
                  ...item,
                  targetData:
                    responceData.result.targets.find(
                      (target: any) => target.targetType === "productTargeting"
                    )?.targetData || [],
                }
              : {
                  ...item,
                  targetData:
                    responceData.result.targets.find(
                      (target: any) => target.targetType === "keywordTargeting"
                    )?.targetData || [],
                }
          ),
        };
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const marketplacesList = (item: any) => {
    let updateArray: any;
    setIsMarketPlacesSelected((prevList) => {
      if (prevList.some((element) => element.profileId === item.profilId)) {
        updateArray = prevList.filter(
          (itemList: any) => itemList.profileId !== item.profilId
        );
      } else {
        updateArray = [
          ...prevList,
          { profileId: item.profilId, countryCode: item.marketplace },
        ];
      }
      return updateArray;
    });

    setIsCreateForm((prevForm: any) => ({
      ...prevForm,
      marketPlaces: updateArray,
    }));
  };
  const marketplaces = isMarketPlace?.map((item: any, index) => (
    <div
      key={index}
      style={{ cursor: "pointer" }}
      className={`marketplace-item ${
        isMarketPlacesSelected.some(
          (element: any) => element.profileId === item.profilId
        )
          ? "marketplace-selected"
          : null
      }`}
      onClick={() => marketplacesList(item)}
    >
      {item.marketplace === "US" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : item.marketplace === "CA" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : item.marketplace === "ES" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : item.marketplace === "FR" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : item.marketplace === "DE" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : item.marketplace === "IT" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : item.marketplace === "AU" ? (
        <>
          <div className="d-flex align-items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
              alt="US Flag"
              className="flag-icons"
            />
            &nbsp;
            {item.marketplace}
          </div>
        </>
      ) : (
        <> {item.marketplace}</>
      )}
    </div>
  ));
  const handleChange = (e: any) => {
    setIsCreateForm((prev: any) => {
      const campaignName = e.target.value;
      return {
        ...prev,
        campaignName: campaignName,
      };
    });
  };

  const handleChanges = (value: any) => {
    if (value === 0) {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          matchType: "MultipleCampaigns",
        };
      });
    }
    setIsSelectedCampaign(value);
  };

  const isimgChange = (value: any) => {
    setIsImgState(value);
    if (value === 2) {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          matchType: "SingleCampaignWithMultipleAdgroup",
        };
      });
    } else {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          matchType: "SingleCampaignWithSingleAdgroup",
        };
      });
    }
  };
  const placementChange = (e: any, type: any) => {
    const value = e.target.value;
    if (!isNaN(value) && 0 <= value && value < 900) {
      if (type === "topOfSearch") {
        setIsCreateForm((prev: any) => {
          const value = e.target.value;
          return {
            ...prev,
            placement: {
              ...prev.placement,
              topOfSearch: parseInt(value),
            },
          };
        });
      } else if (type === "productDetailPages") {
        setIsCreateForm((prev: any) => {
          const value = e.target.value;
          return {
            ...prev,
            placement: {
              ...prev.placement,
              productDetailPages: parseInt(value),
            },
          };
        });
      } else {
        setIsCreateForm((prev: any) => {
          const value = e.target.value;
          return {
            ...prev,
            placement: {
              ...prev.placement,
              restOfSearch: parseInt(value),
            },
          };
        });
      }
    }
  };
  const handleOptionClick = (value: any) => {
    setIsSelected(value);
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        settings: {
          ...prev.settings,
          campaignBiddingStrategy: value,
        },
      };
    });
  };
  const settingChange = (e: any, type: any) => {
    const value = e.target.value;
    if (type === "dailyBudget") {
      if (!isNaN(value) && 0 <= value && value < 900) {
        setIsCreateForm((prev: any) => {
          return {
            ...prev,
            settings: {
              ...prev.settings,
              dailyBudget: parseFloat(e.target.value),
            },
          };
        });
      }
    } else {
      setIsCreateForm((prev: any) => {
        return {
          ...prev,
          settings: {
            ...prev.settings,
            portfolio: e.target.value,
          },
        };
      });
    }
  };
  const handleChangeDate = (type: any, date: any) => {
    if (type === "start") {
      setIsCreateForm((prev: any) => {
        const currentDate = date;
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        return {
          ...prev,
          settings: {
            ...prev.settings,
            startDate: `${year}-${month}-${day}`,
          },
        };
      });
      setIsDate((prev: any) => {
        return {
          ...prev,
          startDate: date,
        };
      });
    } else {
      setIsCreateForm((prev: any) => {
        const currentDate = date;
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        return {
          ...prev,
          settings: {
            ...prev.settings,
            endDate: `${year}-${month}-${day}`,
          },
        };
      });
      setIsDate((prev: any) => {
        return {
          ...prev,
          endDate: date,
        };
      });
    }
  };
  const handleTabSelect = (eventkey: any) => {
    setIsTabChange(eventkey);
  };
  //   const addKeyWordType = (e: any) => {
  //     console.log(e.target.value);

  //     // setIsAdKeywordType(e.target.value);
  //   };
  const addKeyWordType = (e: any) => {
    let keywordList: any = [...isAdKeywordType];
    if (keywordList.includes(e.target.value)) {
      const updatedList = keywordList.filter(
        (itemList: any) => itemList !== e.target.value
      );
      setIsAdKeywordType(updatedList);
    } else {
      keywordList.push(e.target.value);
      setIsAdKeywordType(keywordList);
    }
  };

  useEffect(() => {
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        targets: prev.targets?.map((item: any) =>
          item.targetType === "productTargeting"
            ? { ...item, targetData: isProductTargeting }
            : item
        ),
      };
    });
  }, [isProductTargeting]);
  useEffect(() => {
    setIsCreateForm((prev: any) => {
      return {
        ...prev,
        targets: prev.targets?.map((item: any) =>
          item.targetType === "keywordTargeting"
            ? { ...item, targetData: isKeywordTargeting }
            : item
        ),
      };
    });
  }, [isKeywordTargeting]);
  const adkeyWordSubmit = () => {
    let tempValues: any;
    let keywords: any[] = [isAddTempAdKeywords];
    let keywordValues = keywords
      .flat()[0]
      ?.split("\n")
      .filter((keyword) => keyword !== "");
    if (isTabChange !== "keywordTargeting") {
      tempValues = keywordValues?.map((item, index) => ({
        matchtype: [isAddKeyWordTypeManualList],
        targetText: item,
        name: null,
        isDeleted: false,
      }));

      if (isCategoryList?.length > 0) {
        tempValues = isCategoryList?.map((item: any, index: number) => ({
          matchtype: [isAddKeyWordTypeManualList],
          targetText: item.id,
          name: item.name,
          isDeleted: false,
        }));
      }

      setIsProductTargeting((prev: any) => {
        return [...prev, ...tempValues].flat();
      });

      setIsAddTempAdKeywords("");
    } else {
      // if()isKeywordTargeting, setIsKeywordTargeting
      tempValues = keywordValues?.map((item, index) => ({
        matchtype: isAdKeywordType,
        targetText: item,
        name: null,
        isDeleted: false,
      }));

      setIsKeywordTargeting((prev: any) => {
        return [...prev, ...tempValues];
      });
      setIsAddTempAdKeywords("");
    }
    setIsCategoryResultList((prev: any) => {
      const updateArray = prev?.map((item: any, index: number) => ({
        ...item,
        isChecked: false,
      }));
      return updateArray;
    });
  };

  const removeFormFields = (id: any) => {
    if (isTabChange !== "keywordTargeting") {
      setIsProductTargeting((prev: any) => {
        const updateArray = prev?.map((item: any, index: number) =>
          index === id ? { ...item, isDeleted: !item.isDeleted } : item
        );
        return updateArray;
      });
    } else {
      setIsKeywordTargeting((prev: any) => {
        const updateArray = prev?.map((item: any, index: number) =>
          index === id ? { ...item, isDeleted: !item.isDeleted } : item
        );
        return updateArray;
      });
    }
  };
  const removeAllAdkeyWords = (type) => {
    if (type === "keyword") {
      setIsKeywordTargeting([]);
    } else {
      setIsProductTargeting([]);
    }
    setIsAdKeywords([]);
  };
  const keywordChange = (e: any, type: any) => {
    const value = e.target.value;
    if (type === "defaultBid") {
      setIsError((prev) => ({
        ...prev,
        defaultBid: isNaN(value) || parseFloat(value) !== 0.1 ? true : false,
      }));
    } else {
      setIsError((prev) => ({
        ...prev,
        targetingBid: isNaN(value) || parseFloat(value) !== 0.1 ? true : false,
      }));
    }
    if (!isNaN(value) && Math.abs(value - 0.1) && value < 900) {
      if (type === "defaultBid") {
        setIsCreateForm((prev: any) => {
          return {
            ...prev,
            dynamicBidding: {
              ...prev.dynamicBidding,
              defaultBid: parseFloat(value),
            },
          };
        });
      } else {
        setIsCreateForm((prev: any) => {
          return {
            ...prev,
            dynamicBidding: {
              ...prev.dynamicBidding,
              targetingBid: parseFloat(value),
            },
          };
        });
      }
    }
  };

  const keywordChangedynamic = (e: any) => {
    const value = e.target.value;
    if (!isNaN(value) && 0 <= value && value < 900) {
      setIsMatchList((prev: any) => {
        const updateArray = prev?.map((item: any, index) =>
          item.nameid === e.target.name
            ? { ...item, name: parseFloat(value) }
            : item
        );
        return updateArray;
      });
    }
  };

  const keywordChangeHandler = (e: any, id: any) => {
    setIsMatchList((prev: any) => {
      const updateArray = prev?.map((item: any, index: number) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      );
      return updateArray;
    });
  };

  const addKeyWordTypeTargeting = (e: any) => {
    setIsAddKeyWordTypeManualList(e.target.value);
  };
  const searchTextHandler = async () => {
    setIsLoading(true);
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = `https://api.lotusppc.com/AdsManager/Categories?text=${isCategorySearch}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.status === 200) {
        const responceData = await response.json();
        setIsLoading(false);
        setIsCategoryResultList(responceData.result);
      }
    } catch (err) {}
  };
  useEffect(() => {
    setIsCategoryList((prev: any) => {
      const categoryList = isCategoryResultList
        .filter((item: any) => item.isChecked)
        ?.map((item: any) => ({ name: item.name, id: item.id }));
      setIsAddTempAdKeywords(categoryList?.map((item, index) => item.name));
      return categoryList;
    });
  }, [isCategoryResultList]);
  const CategoryHandler = (id: any) => {
    setIsCategoryResultList((prev: any) => {
      const updatedCategoryResultList = prev?.map((item: any) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      );
      return updatedCategoryResultList;
    });
  };

  const CategoryShowingList = isCategoryResultList?.map((item: any, index) => {
    return (
      <div key={index}>
        <div className="d-flex align-items-center my-2">
          <input
            onChange={() => CategoryHandler(item.id)}
            type="checkbox"
            className="form-check-input mx-1"
            checked={item.isChecked}
            id={item.id}
          />
          <span className="category-text">{item.name}</span>
        </div>
      </div>
    );
  });
  const submitHandler = async (e: any) => {
    e.preventDefault();
    const validationCheck = {
      dailyBudget: !!iscreateForm?.settings?.dailyBudget,
      targetingBid: !!iscreateForm?.dynamicBidding?.targetingBid,
      defaultBid: !!iscreateForm?.dynamicBidding?.defaultBid,
      campaignBiddingStrategy:
        !!iscreateForm?.settings?.campaignBiddingStrategy,
      marketPlaces: iscreateForm?.marketPlaces?.length > 0,
    };
    setIsError(validationCheck);

    const errorValidation = Object.values(validationCheck).every(
      (value) => value === true
    );
    if (errorValidation) {
      let productTargetingkeyword: any;
      let keywordTargetingkeyword: any;

      if (!params.id) {
        productTargetingkeyword = isProductTargeting.filter(
          (item: any, index) => item.isDeleted === false
        );
        keywordTargetingkeyword = isKeywordTargeting.filter(
          (item: any, index) => item.isDeleted === false
        );
      } else {
        productTargetingkeyword = isProductTargeting.filter(
          (item, index) => item
        );
        keywordTargetingkeyword = isKeywordTargeting.filter(
          (item, index) => item
        );
      }
      const updatedPlacement = {
        ...iscreateForm.placement,
        topOfSearch: iscreateForm.placement.topOfSearch || 0,
        productDetailPages: iscreateForm.placement.productDetailPages || 0,
        restOfSearch: iscreateForm.placement.restOfSearch || 0,
      };
      const updateArray = isMatchList
        .filter((item: any) => item.isChecked && item.name)
        .reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item.nameid]: item.name,
          };
        }, {});

      const productTargeting = iscreateForm.targets
        .filter((item: any, index) => item.targetType === "productTargeting")
        .some((item: any, index) => 0 < item.targetData?.length);
      const keywordTargeting = iscreateForm.targets
        .filter((item: any, index) => item.targetType === "keywordTargeting")
        .some((item: any, index) => 0 < item.targetData?.length);

      let updateTargets: any;
      if (productTargeting && keywordTargeting) {
        updateTargets = iscreateForm.targets
          ?.map((item: any, index) =>
            item.targetType === "productTargeting"
              ? { ...item, targetData: productTargetingkeyword }
              : item.targetData || item.targetType === "keywordTargeting"
              ? { ...item, targetData: keywordTargetingkeyword }
              : item.targetData
          )
          .filter(
            (item: any, index) =>
              item.targetType === "productTargeting" ||
              item.targetType === "keywordTargeting"
          );
      } else if (productTargeting) {
        updateTargets = iscreateForm.targets
          ?.map((item: any, index) =>
            item.targetType === "productTargeting"
              ? { ...item, targetData: productTargetingkeyword }
              : item.targetData
          )
          .filter((item, index) => item.targetType === "productTargeting");
      } else if (keywordTargeting) {
        updateTargets = iscreateForm.targets
          ?.map((item: any, index) =>
            item.targetType === "keywordTargeting"
              ? { ...item, targetData: keywordTargetingkeyword }
              : item.targetData
          )
          .filter((item, index) => item.targetType === "keywordTargeting");
      }

      const updatedForm = {
        ...iscreateForm,
        targets: updateTargets,
        placement: updatedPlacement,
        dynamicBidding: {
          ...iscreateForm.dynamicBidding,
          ...updateArray,
        },
      };

      if (params.id) {
        await updateDataCampaign(updatedForm);
      } else {
        await postDataCampaign(updatedForm);
      }
    }
  };
  const resetData = () => {
    setIsCreateForm(CreateFormData);
    setIsMatchList(matchTypesList);
    setIsSelected(null);
    setIsAdKeywords([]);
    setIsAddTempAdKeywords("");
    setIsProductTargeting([]);
    setIsKeywordTargeting([]);
    setIsMarketPlacesSelected(null);
  };
  const selectedAllAdkeyWords = (e) => {
    setIsMatchList((prevState: any) => {
      const updatedList = prevState?.map((listItem) => ({
        ...listItem,
        isChecked: !listItem.isChecked,
      }));
      return updatedList;
    });
  };
  const postDataCampaign = async (updatedForm: any) => {
    setIsSubmitLoading(true);
    let campaigns: any;
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.lotusppc.com/AdsManager/Ads";
    if (isAutoState) {
      campaigns = [isAutoState, updatedForm];
    } else {
      campaigns = [updatedForm];
    }
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ campaigns }),
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      if (response.ok) {
        navigate("/ads/ads-creation");
        toast("Created Sucessfully");
        resetData();
      } else {
        if (responseData.success) {
          navigate("/ads/ads-creation");
          toast("Created Successfully");
          resetData();
          let result = responseData.result;
          setIsMarketPlace(result);
        } else {
          if (responseData && responseData.validationErrors) {
            let errorMessage = "";

            if (
              responseData.validationErrors[
                "Campaigns[0].DynamicBidding.DefaultBid"
              ]
            ) {
              errorMessage +=
                responseData.validationErrors[
                  "Campaigns[0].DynamicBidding.DefaultBid"
                ][0] + "\n";
            }

            if (
              responseData.validationErrors["Campaigns[0].Settings.StartDate"]
            ) {
              errorMessage +=
                responseData.validationErrors[
                  "Campaigns[0].Settings.StartDate"
                ][0] + "\n";
            }

            if (
              responseData.validationErrors["Campaigns[0].Settings.EndDate"]
            ) {
              errorMessage +=
                responseData.validationErrors[
                  "Campaigns[0].Settings.EndDate"
                ][0] + "\n";
            }

            toast(errorMessage || "Unknown error occurred.");
          } else {
            toast("Unknown error occurred.");
          }
        }
      }
      setIsSubmitLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating");
    }
  };
  const updateDataCampaign = async (updateArray: any) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.lotusppc.com/AdsManager/Ads";
    const campaigns = updateArray;
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(campaigns),
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };
    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (response.ok) {
        navigate("/ads/ads-creation");
        toast("Updated Successfully");
        resetData();
      } else {
        toast("Error On Updated");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating");
    }
  };
  const removematchtypeHandler = (id: any, e: any) => {
    setIsMatchList((prevState: any) => {
      const updatedList = prevState?.map((item: any, index) =>
        item.id === id ? { ...item, name: "" } : item
      );
      return updatedList;
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      searchTextHandler();
    }
  };
  return (
    <>
      <Form onSubmit={submitHandler}>
        <Container fluid>
          <Row>
            <Col md={8}>
              <p className="set-dynamic pt-3">
                Set dynamic campaign names. You can check the alternative
                placeholders{" "}
                <Tooltip
                  title={
                    <div className="popup-custom-box">
                      <p>Alternative Placeholders:</p>
                      <ul>
                        <li>&#10100;ASIN&#10101;</li>
                        <li>&#10100;Layer&#10101;</li>
                        <li>&#10100;Match Type&#10101;</li>
                        <li>&#10100;Tag&#10101;</li>
                        <li>&#10100;Targeting Type&#10101;</li>
                      </ul>
                    </div>
                  }
                  placement="bottom"
                >
                  <span className="here-tooltip"> here</span>
                </Tooltip>
              </p>

              <div className="form-group">
                <h6 className="campaign-name-text">Campaign Name</h6>
                <div className="placeholder-input-wrapper-manual">
                  <input
                    disabled={params.id ? true : false}
                    // disabled={this.state.CreateFormData.id ? true : false}
                    // disabled
                    className="custom-input-main-manual"
                    name="createCampaignFormData.campaignName"
                    onChange={handleChange}
                    value={iscreateForm.campaignName}
                  />
                </div>
              </div>
              <h6 className="my-3 campaign-name-text">
                MatchType Options
                <img src={InfoIcon} alt="" />
              </h6>
              <div className="d-flex flex-wrap">
                <div
                  style={{ marginRight: "20px" }}
                  className="d-flex align-items-baseline"
                >
                  <label>
                    <input
                      type="radio"
                      className="form-check-input mx-1"
                      name="matchTypeOption"
                      disabled={params.id ? true : false}
                      value="1"
                      checked={isSelectedCampaign === 1}
                      onChange={(e) => handleChanges(1)}
                    />
                  </label>
                  <p className="set-dynamic">
                    Create each matchtype in the same campaign
                  </p>
                </div>
                <div className="d-flex align-items-baseline">
                  <label>
                    <input
                      type="radio"
                      className="form-check-input mx-1"
                      name="matchTypeOption"
                      value="0"
                      disabled={params.id ? true : false}
                      checked={isSelectedCampaign === 0}
                      onChange={(e) => handleChanges(0)}
                    />
                  </label>
                  <p className="set-dynamic">
                    {" "}
                    Create each matchtype in individual campaigns
                  </p>
                </div>
              </div>
              <h6 className="campaign-name-text">
                {" "}
                {isSelectedCampaign === 1
                  ? "Select 1 of these options below :"
                  : null}
              </h6>
              {/* {isSelectedCampaign === 1 ? (
                <>
                  {isimgstate === 1 ? (
                    <div className="d-flex" style={{ gap: "20px" }}>
                      <div>
                        <img
                          src={require("../../../../../assets/images/man-2-1.png")}
                          alt="alt-same"
                          onClick={() => isimgChange(1)}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(1) }
                            : null)}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../../../../../assets/images/man-1-1.png")}
                          alt="alt-individual"
                          onClick={() => isimgChange(2)}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(2) }
                            : null)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex"
                      style={{
                        gap: "20px",
                        cursor: params.id ? "not-allowed" : "pointer",
                      }}
                    >
                      <div>
                        <img
                          src={require("../../../../../assets/images/man-2-2.png")}
                          alt="alt-same"
                          onClick={() => isimgChange(1)}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(1) }
                            : null)}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../../../../../assets/images/man-1-2.png")}
                          alt="alt-same"
                          onClick={() => isimgChange(2)}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            cursor: params.id ? "not-allowed" : "pointer",
                          }}
                          {...(!params.id
                            ? { onClick: () => isimgChange(2) }
                            : null)}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <img
                    src={require("../../../../../assets/images/man-1.jpg")}
                    alt="alt-individualcampaign"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
              )} */}
              {isSelectedCampaign === 1 ? (
                <div
                  className="d-flex justify-content-between gap-3"
                  style={{ gap: "10px" }}
                >
                  <div
                    className={`img-layout ${isimgstate === 1 && "selected"}`}
                    onClick={() => isimgChange(1)}
                  >
                    <div className="d-flex justify-content-end mx-3">
                      <div
                        className={`input-round ${
                          isimgstate === 1 && "selectedInput"
                        }`}
                      ></div>
                    </div>
                    <div>
                      <div className="auto-img-border">
                        <div className="auto-border">Manual Campaign</div>
                        <div className="auto-border-line2"></div>
                        <div className="auto-border">Ad Group</div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className="horizontal-line-man"></div>
                        <div className="d-flex justify-content-around">
                          <div style={{ position: "relative" }}>
                            <div className="auto-border-item">Board</div>
                            <div className="auto-border-doubleline"></div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <div className="auto-border-item">Pharse</div>
                            <div></div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <div className="auto-border-item">Exact</div>
                            <div className="auto-border-doubleline"></div>
                          </div>
                          {/* <div style={{ position: "relative" }}>
                            <div className="auto-border-item">Complements</div>
                            <div className="auto-border-doubleline"></div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`img-layout ${isimgstate === 2 && "selected"}`}
                    onClick={() => isimgChange(2)}
                  >
                    <div className="d-flex justify-content-end mx-3">
                      <div
                        className={`input-round ${
                          isimgstate === 2 && "selectedInput"
                        }`}
                      ></div>
                    </div>
                    <div>
                      <div className="auto-img-border">
                        <div className="auto-border">Auto Campaign</div>
                        <div className="man-border-line1"></div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className="horizontal-line1-man"></div>
                        <div className="d-flex justify-content-around">
                          <div style={{ position: "relative" }}>
                            <div>
                              <div className="auto-border-item">Ad Group</div>
                              <div className="autoadgrp-border-doubleline"></div>
                            </div>
                            <div className="auto-border-item1">Board</div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <div>
                              <div className="auto-border-item">Ad Group</div>
                              {/* <div className="autoadgrp-border-doubleline"></div> */}
                            </div>
                            <div className="auto-border-item1">Pharse</div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <div>
                              <div className="auto-border-item">Ad Group</div>
                              <div className="autoadgrp-border-doubleline"></div>
                            </div>
                            <div className="auto-border-item1">Exact</div>
                          </div>
                          {/* <div style={{ position: "relative" }}>
                            <div>
                              <div className="auto-border-item">Ad Group</div>
                              <div className="autoadgrp-border-doubleline"></div>
                            </div>
                            <div className="auto-border-item1">Complements</div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Row>
                  <Col>
                    <div
                      className={`img-layout1 ${
                        isSelectedCampaign === 0 && "selected"
                      }`}
                    >
                      <div className="d-flex justify-content-end mx-3">
                        <div
                          className={`input-round ${
                            isSelectedCampaign === 0 && "selectedInput"
                          }`}
                        ></div>
                      </div>
                      <div className="d-flex justify-content-around">
                        <div style={{ position: "relative" }}>
                          <div className="autoadgrp-border-doubleline1"></div>
                          <div className="auto-center-item">
                            <div className="auto-border-item2">
                              Manual Campaign
                            </div>
                            <div className="auto-border-item2">Ad Group</div>
                            <div className="auto-border-item2">Broad</div>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div className="autoadgrp-border-doubleline1"></div>
                          <div className="auto-center-item">
                            <div className="auto-border-item2">
                              Manual Campaign
                            </div>
                            <div className="auto-border-item2">Ad Group</div>
                            <div className="auto-border-item2">Phrase</div>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div className="autoadgrp-border-doubleline1"></div>
                          <div className="auto-center-item">
                            <div className="auto-border-item2">
                              Manual Campaign
                            </div>
                            <div className="auto-border-item2">Ad Group</div>
                            <div className="auto-border-item2">Exact</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div>
                <p className="placement-icon">Placement</p>
              </div>
              <div
                className="d-flex justify-content-between flex-wrap"
                style={{ gap: "20px" }}
              >
                <div style={{ flexGrow: "1" }} className="mb-3">
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">
                      Top of search(firstPage)
                    </span>
                  </label>
                  <div className="d-flex mt-1">
                    <input
                      type="number"
                      className="custom-input"
                      placeholder="0"
                      name="topOfSearch"
                      aria-describedby="basic-addon1"
                      value={iscreateForm.placement?.topOfSearch}
                      onChange={(e) => placementChange(e, "topOfSearch")}
                    />
                    <span className="input-span" id="basic-addon1">
                      %
                    </span>
                  </div>
                  {/* {this.state.isError.topOfSearch === null ||
                           this.state.isError.topOfSearch ? null : (
                             <span className="text-danger">*requried</span>
                           )} */}
                </div>
                <div style={{ flexGrow: "1" }}>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">Product detail Pages</span>
                  </label>
                  <div className="mt-1 d-flex">
                    <input
                      type="number"
                      className="custom-input"
                      aria-label="Username"
                      name="productDetailPages"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      value={iscreateForm.placement?.productDetailPages}
                      onChange={(e) => placementChange(e, "productDetailPages")}
                    />
                    <span className="input-span" id="basic-addon1">
                      %
                    </span>
                  </div>
                </div>
                <div style={{ flexGrow: "1" }}>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">Rest of Search</span>
                  </label>
                  <div className="mt-1 d-flex">
                    <input
                      type="number"
                      className="custom-input"
                      aria-label="Username"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      name="restOfSearch"
                      value={iscreateForm.placement?.restOfSearch}
                      onChange={(e) => placementChange(e, "restOfSearch")}
                    />
                    <span className="input-span" id="basic-addon1">
                      %
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row> */}
          {/* <Row>
            <div>
              <p className="marketplace-icon">Select Marketplaces</p>
            </div>
            {!isError.marketPlaces && (
              <span className="text-danger">*requried</span>
            )}
            <Col md={8}>
              <div className="marketplaces-id">{marketplaces}</div>
            </Col>
          </Row> */}
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <p className="Setting-icon">Settings</p>
            </div>
            <Col md={8} lg={4}>
              <span className="placement-text">Campaign Bidding Strategy</span>
              <div className="setting-button flex-wrap flex-sm-nowrap">
                <div
                  className={`${isSelected === "Down only" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("Down only")}
                >
                  Down only
                </div>
                <div
                  className={`${isSelected === "Up&Down" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("Up&Down")}
                >
                  Up & Down
                </div>
                <div
                  className={`${isSelected === "Fixed" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("Fixed")}
                >
                  Fixed
                </div>
              </div>
              {!isError.campaignBiddingStrategy && (
                <span className="text-danger">*requried</span>
              )}
              <div className="mt-3">
                <label htmlFor="basic-url" className="form-label">
                  <span className="placement-text">Portfolio</span>
                </label>
                <select
                  className="form-select"
                  name="portfolio"
                  aria-label="Default select example"
                  // style={{ maxWidth: "330px" }}
                  onChange={(e) => settingChange(e, "portfolio")}
                  value={iscreateForm.settings.portfolio}
                >
                  <option value="">Choose Portfolio</option>
                  {isPortfolio?.map((list: any, i) => (
                    <option value={list.portfolioId} key={i}>
                      {list.portfolioName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={8} lg={4}>
              <label htmlFor="basic-url" className="form-label">
                <span className="placement-text">Daily Budget</span>
              </label>
              <div className="daily-budget">
                <span className="input-span-daily" id="basic-addon1">
                  $
                </span>
                <input
                  type="number"
                  name="dailyBudget"
                  className="custom-input-daily"
                  placeholder="0"
                  aria-describedby="basic-addon1"
                  value={iscreateForm.settings.dailyBudget}
                  onChange={(e) => settingChange(e, "dailyBudget")}
                />
              </div>

              {!isError.dailyBudget && (
                <span className="text-danger">*requried</span>
              )}
              <div className="Daily-Budget-dates mt-3">
                <div>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text"> Start</span>
                  </label>
                  <br />

                  <DatePicker
                    disabled={params.id ? true : false}
                    className="date-picker-autocampign"
                    selected={isDate.startDate}
                    value={iscreateForm.settings.startDate}
                    minDate={new Date()}
                    onChange={(e) => {
                      handleChangeDate("start", e);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="basic-url" className="form-label">
                    <span className="placement-text">End</span>
                  </label>
                  <br />
                  <DatePicker
                    minDate={new Date()}
                    className="date-picker-autocampign"
                    selected={isDate.endDate}
                    value={iscreateForm.settings.endDate}
                    onChange={(e) => {
                      handleChangeDate("end", e);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <hr
                style={{
                  border: "1px solid #E8EAED",
                  borderWidth: "3px 0",
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <p className="Targeting-icon">Targeting</p>
            </div>
            <Col md={8}>
              {params.keyword === "keywordTargeting" ? (
                <Tabs onSelect={handleTabSelect}>
                  <Tab
                    eventKey="KeywordsTargeting"
                    title="Keywords Targeting"
                    // disabled={
                    //   this.state.ishide === "keywordTargeting" ? false : true
                    // }
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <div className="my-3">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                placeholder="Add Keyword"
                                name="Broad"
                                id="inlineRadio3"
                                value="Broad"
                                checked={isAdKeywordType.some(
                                  (item) => item === "Broad"
                                )}
                                onChange={(e) => addKeyWordType(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio3"
                              >
                                Broad
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="Phrase"
                                id="inlineRadio4"
                                value="Phrase"
                                checked={isAdKeywordType.some(
                                  (item) => item === "Phrase"
                                )}
                                onChange={(e) => addKeyWordType(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio4"
                              >
                                Phrase
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="Exact"
                                id="inlineRadio4"
                                value="Exact"
                                checked={isAdKeywordType.some(
                                  (item) => item === "Exact"
                                )}
                                onChange={(e) => addKeyWordType(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio4"
                              >
                                Exact
                              </label>
                            </div>
                          </div>
                          <div className="add-keyword-container">
                            <textarea
                              name=""
                              placeholder="Enter Keyword"
                              id="addKeywordsText"
                              className="form-control mb-3 input-box-control-auto"
                              onChange={(e) =>
                                setIsAddTempAdKeywords(e.target.value)
                              }
                              style={{ width: "100%" }}
                              value={isAddTempAdKeywords}
                            ></textarea>
                            <button
                              type="button"
                              className="btn btn-outline mb-2 add-keyword-btn resize"
                              onClick={(e) => adkeyWordSubmit()}
                              // disabled={!this.state.adKeywordType}
                              style={{
                                background: "#00FF00",
                              }}
                            >
                              Add Keyword
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            className="create-campaing-form table-responsive border"
                            style={{ width: "100%" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Keywords</th>
                                  <th>Match Type</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {isKeywordTargeting?.map(
                                  (adKeyword: any, index) =>
                                    !adKeyword.isDeleted ? (
                                      <tr>
                                        <td>{adKeyword.targetText}</td>
                                        <td>
                                          {adKeyword?.matchtype.join(",")}
                                        </td>

                                        <td className="text-center more-action">
                                          <i
                                            onClick={() =>
                                              removeFormFields(index)
                                            }
                                          >
                                            <img src={DeleteIcon} alt="" />
                                          </i>
                                        </td>
                                      </tr>
                                    ) : null
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={12}
                          className="d-flex justify-content-between my-4 0 keyword-added"
                        >
                          <p>
                            {
                              isKeywordTargeting?.filter(
                                (item, index) => !item.isDeleted
                              )?.length
                            }
                            &nbsp;keywords added
                          </p>
                          <span
                            className="text-end remove-all-link"
                            onClick={() => removeAllAdkeyWords("keyword")}
                          >
                            Remove All
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              ) : params.keyword === "productTargeting" ? (
                <Tabs onSelect={handleTabSelect}>
                  <Tab
                    eventKey="productTargeting"
                    title="Product Targeting"
                    // disabled={
                    //   this.state.ishide === "productTargeting" ? false : true
                    // }
                  >
                    <Container>
                      <Row>
                        <Col md={12}>
                          <div className="mt-2">
                            <h6 className="target-types">Targeting Types</h6>
                          </div>
                          <div className="my-3">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                placeholder="Add Keyword"
                                name="negativeKeyword"
                                id="inlineRadio3"
                                value="product"
                                checked={isAddKeyWordTypeManualList.includes(
                                  "product"
                                )}
                                onChange={(e) => addKeyWordTypeTargeting(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio3"
                              >
                                Product
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="negativeKeyword"
                                id="inlineRadio4"
                                value="category"
                                checked={isAddKeyWordTypeManualList.includes(
                                  "category"
                                )}
                                onChange={(e) => addKeyWordTypeTargeting(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio4"
                              >
                                Category
                              </label>
                            </div>
                          </div>
                          <div
                            className="add-keyword-container d-flex"
                            style={{ gap: "20px" }}
                          >
                            {isAddKeyWordTypeManualList === "category" ? (
                              <>
                                <div className="manual-product-search">
                                  <div className="manual-border">
                                    <div className="merge-box">
                                      <input
                                        type="text"
                                        className="manual-product"
                                        value={isCategorySearch}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e: any) =>
                                          setIsCategorySearch(e.target.value)
                                        }
                                      />
                                      <div
                                        className="icon-search"
                                        onClick={searchTextHandler}
                                      >
                                        <i className="fa fa-search icon-color"></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <span className="mx-3">
                                      Result:{CategoryShowingList?.length}
                                    </span>
                                    <span className="mx-3">
                                      Selected:
                                      {isAddTempAdKeywords?.length}
                                    </span>
                                  </div>
                                  <div className="search-result">
                                    {isLoading ? (
                                      <div className="loading-screen">
                                        <Box sx={{ display: "flex" }}>
                                          <CircularProgress color="success" />
                                        </Box>
                                      </div>
                                    ) : (
                                      <> {CategoryShowingList}</>
                                    )}
                                  </div>
                                </div>
                                <textarea
                                  name=""
                                  id="addKeywordsText"
                                  placeholder="Enter Categories"
                                  className="form-control mb-3 input-box-control-auto resize"
                                  disabled
                                  onChange={(e) =>
                                    setIsAddTempAdKeywords(e.target.value)
                                  }
                                  // style={{ width: "100%" }}
                                  value={isAddTempAdKeywords}
                                ></textarea>
                                <button
                                  type="button"
                                  className="btn btn-outline mb-2 add-keyword-btn resize"
                                  onClick={(e) => adkeyWordSubmit()}
                                  // disabled={!this.state.adKeywordType}
                                  style={{
                                    background: "#00FF00",
                                  }}
                                >
                                  Add Categories
                                </button>
                              </>
                            ) : (
                              <>
                                <textarea
                                  name=""
                                  placeholder="Enter ASINs"
                                  id="addKeywordsText"
                                  className="form-control mb-3 input-box-control-auto"
                                  onChange={(e) =>
                                    setIsAddTempAdKeywords(e.target.value)
                                  }
                                  style={{ width: "100%" }}
                                  value={isAddTempAdKeywords}
                                ></textarea>
                                <button
                                  type="button"
                                  className="btn btn-outline mb-2 add-keyword-btn resize"
                                  onClick={(e) => adkeyWordSubmit()}
                                  // disabled={!this.state.adKeywordType}
                                  style={{
                                    background: "#00FF00",
                                  }}
                                >
                                  Add ASINs
                                </button>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            className="create-campaing-form table-responsive border"
                            style={{ width: "100%" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Categories / ASINs</th>
                                  <th>Match Type</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {isProductTargeting?.map(
                                  (adKeyword: any, index) =>
                                    !adKeyword.isDeleted ? (
                                      <tr>
                                        <td>
                                          {adKeyword.name ||
                                            adKeyword.targetText}
                                        </td>
                                        <td>{adKeyword?.matchtype}</td>
                                        <td className="text-center more-action">
                                          <i
                                            onClick={() =>
                                              removeFormFields(index)
                                            }
                                          >
                                            <img src={DeleteIcon} alt="" />
                                          </i>
                                        </td>
                                      </tr>
                                    ) : null
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={12}
                          className="d-flex justify-content-between my-4 0 keyword-added"
                        >
                          <p>
                            {
                              isProductTargeting?.filter(
                                (item, index) => !item.isDeleted
                              )?.length
                            }
                            &nbsp;Categories / ASINs added
                          </p>
                          <span
                            className="text-end remove-all-link"
                            onClick={() => removeAllAdkeyWords("product")}
                          >
                            Remove All
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              ) : (
                <>
                  <Tabs onSelect={handleTabSelect}>
                    <Tab
                      eventKey="keywordTargeting"
                      title="Keywords Targeting"
                      // disabled={
                      //   this.state.ishide === "keywordTargeting" ? false : true
                      // }
                    >
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className="my-3">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  placeholder="Add Keyword"
                                  name="Broad"
                                  id="inlineRadio3"
                                  value="Broad"
                                  checked={isAdKeywordType.some(
                                    (item) => item === "Broad"
                                  )}
                                  onChange={(e) => addKeyWordType(e)}
                                  //   onChange={(e) => this.addKeyWordType(e)}
                                  //   checked={this.state.adKeywordType.some(
                                  //     (item) => item === "Broad"
                                  //   )}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Broad
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="Phrase"
                                  id="inlineRadio4"
                                  value="Phrase"
                                  checked={isAdKeywordType.some(
                                    (item) => item === "Phrase"
                                  )}
                                  onChange={(e) => addKeyWordType(e)}
                                  //   checked={this.state.adKeywordType.some(
                                  //     (item) => item === "Phrase"
                                  //   )}
                                  //   onChange={(e) => this.addKeyWordType(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio4"
                                >
                                  Phrase
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="Exact"
                                  id="inlineRadio4"
                                  value="Exact"
                                  checked={isAdKeywordType.some(
                                    (item) => item === "Exact"
                                  )}
                                  onChange={(e) => addKeyWordType(e)}
                                  //   checked={this.state.adKeywordType.some(
                                  //     (item) => item === "Exact"
                                  //   )}
                                  //   onChange={(e) => this.addKeyWordType(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio4"
                                >
                                  Exact
                                </label>
                              </div>
                            </div>
                            <div className="add-keyword-container">
                              <textarea
                                name=""
                                placeholder="Enter keywords"
                                id="addKeywordsText"
                                className="form-control mb-3 input-box-control-auto"
                                onChange={(e) =>
                                  setIsAddTempAdKeywords(e.target.value)
                                }
                                style={{ width: "100%" }}
                                value={isAddTempAdKeywords}
                              ></textarea>
                              <button
                                type="button"
                                className="btn btn-outline mb-2 add-keyword-btn resize"
                                onClick={(e) => adkeyWordSubmit()}
                                disabled={isAdKeywordType.length === 0}
                                style={{
                                  background: "#00FF00",
                                }}
                              >
                                Add Keyword
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div
                              className="create-campaing-form table-responsive border"
                              style={{ width: "100%" }}
                            >
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Keywords</th>
                                    <th>Match Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isKeywordTargeting?.map(
                                    (adKeyword: any, index) =>
                                      !adKeyword.isDeleted ? (
                                        <tr>
                                          <td>{adKeyword.targetText}</td>

                                          <td>
                                            {adKeyword?.matchtype.join(",")}
                                          </td>
                                          <td className="text-center more-action">
                                            <i
                                              onClick={() =>
                                                removeFormFields(index)
                                              }
                                            >
                                              <img src={DeleteIcon} alt="" />
                                            </i>
                                          </td>
                                        </tr>
                                      ) : null
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={12}
                            className="d-flex justify-content-between my-4 0 keyword-added"
                          >
                            <p>
                              {
                                isKeywordTargeting?.filter(
                                  (item, index) => !item.isDeleted
                                )?.length
                              }
                              &nbsp;keywords added
                            </p>
                            <span
                              className="text-end remove-all-link"
                              onClick={() => removeAllAdkeyWords("keyword")}
                            >
                              Remove All
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    </Tab>
                    <Tab
                      eventKey="productTargeting"
                      title="Product Targeting"
                      // disabled={
                      //   this.state.ishide === "productTargeting" ? false : true
                      // }
                    >
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className="mt-2">
                              <h6 className="target-types">Targeting Types</h6>
                            </div>
                            <div className="my-3">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  placeholder="Add Keyword"
                                  name="negativeKeyword"
                                  id="inlineRadio3"
                                  value="product"
                                  checked={isAddKeyWordTypeManualList.includes(
                                    "product"
                                  )}
                                  onChange={(e) => addKeyWordTypeTargeting(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Product
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="negativeKeyword"
                                  id="inlineRadio4"
                                  value="category"
                                  checked={isAddKeyWordTypeManualList.includes(
                                    "category"
                                  )}
                                  onChange={(e) => addKeyWordTypeTargeting(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio4"
                                >
                                  Category
                                </label>
                              </div>
                            </div>
                            <div
                              className="add-keyword-container d-flex"
                              style={{ gap: "20px" }}
                            >
                              {isAddKeyWordTypeManualList === "category" ? (
                                <>
                                  <div className="manual-product-search">
                                    <div className="manual-border">
                                      <div className="merge-box">
                                        <input
                                          type="text"
                                          className="manual-product"
                                          value={isCategorySearch}
                                          onKeyPress={handleKeyPress}
                                          onChange={(e: any) =>
                                            setIsCategorySearch(e.target.value)
                                          }
                                        />
                                        <div
                                          className="icon-search"
                                          onClick={searchTextHandler}
                                        >
                                          <i className="fa fa-search icon-color"></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <span className="mx-3">
                                        Result:{CategoryShowingList?.length}
                                      </span>
                                      <span className="mx-3">
                                        Selected:
                                        {isAddTempAdKeywords?.length}
                                      </span>
                                    </div>
                                    <div className="search-result">
                                      {isLoading ? (
                                        <div className="loading-screen">
                                          <Box sx={{ display: "flex" }}>
                                            <CircularProgress color="success" />
                                          </Box>
                                        </div>
                                      ) : (
                                        <> {CategoryShowingList}</>
                                      )}
                                    </div>
                                  </div>
                                  <textarea
                                    name=""
                                    id="addKeywordsText"
                                    placeholder="Enter Categories"
                                    className="form-control mb-3 input-box-control-auto resize"
                                    disabled
                                    onChange={(e) =>
                                      setIsAddTempAdKeywords(e.target.value)
                                    }
                                    // style={{ width: "100%" }}
                                    value={isAddTempAdKeywords}
                                  ></textarea>
                                  <button
                                    type="button"
                                    className="btn btn-outline mb-2 add-keyword-btn resize"
                                    onClick={(e) => adkeyWordSubmit()}
                                    // disabled={!this.state.adKeywordType}
                                    style={{
                                      background: "#00FF00",
                                    }}
                                  >
                                    Add Categories
                                  </button>
                                </>
                              ) : (
                                <>
                                  <textarea
                                    name=""
                                    placeholder="Enter ASINs"
                                    id="addKeywordsText"
                                    className="form-control mb-3 input-box-control-auto"
                                    onChange={(e) =>
                                      setIsAddTempAdKeywords(e.target.value)
                                    }
                                    style={{ width: "100%" }}
                                    value={isAddTempAdKeywords}
                                  ></textarea>
                                  <button
                                    type="button"
                                    className="btn btn-outline mb-2 add-keyword-btn resize"
                                    onClick={(e) => adkeyWordSubmit()}
                                    // disabled={!this.state.adKeywordType}
                                    style={{
                                      background: "#00FF00",
                                    }}
                                  >
                                    Add ASINs
                                  </button>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div
                              className="create-campaing-form table-responsive border"
                              style={{ width: "100%" }}
                            >
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Categories / ASINs</th>
                                    <th>Match Type</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isProductTargeting?.map(
                                    (adKeyword: any, index) =>
                                      !adKeyword.isDeleted ? (
                                        <tr key={index}>
                                          <td>
                                            {adKeyword.name ||
                                              adKeyword.targetText}
                                          </td>
                                          <td>{adKeyword?.matchtype}</td>
                                          <td className="text-center more-action">
                                            <i
                                              onClick={() =>
                                                removeFormFields(index)
                                              }
                                            >
                                              <img
                                                src={DeleteIcon}
                                                alt="Delete"
                                              />
                                            </i>
                                          </td>
                                        </tr>
                                      ) : null
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={12}
                            className="d-flex justify-content-between my-4 0 keyword-added"
                          >
                            <p>
                              {
                                isProductTargeting.filter(
                                  (item, index) => !item.isDeleted
                                )?.length
                              }
                              &nbsp;Categories / ASINs added{" "}
                            </p>

                            <span
                              className="text-end remove-all-link"
                              onClick={() => removeAllAdkeyWords("product")}
                            >
                              Remove All
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    </Tab>
                  </Tabs>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div
                className="d-flex justify-content-between flex-wrap"
                style={{ columnGap: "10px" }}
              >
                <div style={{ flexGrow: "1" }}>
                  <div>
                    <h6 className="custom-input-text">Targeting Bid (X)</h6>
                  </div>
                  <div>
                    <input
                      // required
                      type="number"
                      name="targetingBid"
                      className="form-control"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      onChange={(e) => keywordChange(e, "targetingBid")}
                      value={iscreateForm.dynamicBidding.targetingBid}
                      min="0.2"
                      step="0.1"
                    />
                  </div>
                  {/* {this.state.isError.targetingBid === null ||
                             this.state.isError.targetingBid ? null : (
                               <span className="text-danger">*requried</span>
                             )} */}
                  {!isError.targetingBid && (
                    <span className="text-danger">
                      *Bid must be at least $0.02
                    </span>
                  )}
                </div>

                <div style={{ flexGrow: "1" }}>
                  <div>
                    <h6 className="custom-input-text">Default Bid</h6>
                  </div>
                  <div>
                    <input
                      // required
                      name="defaultBid"
                      type="number"
                      value={iscreateForm.dynamicBidding.defaultBid}
                      className="form-control"
                      placeholder="0"
                      aria-describedby="basic-addon1"
                      onChange={(e) => keywordChange(e, "defaultBid")}
                      min="0.2"
                      step="0.1"
                    />
                  </div>
                  {/* {this.state.isError.defaultBid === null ||
                             this.state.isError.defaultBid ? null : (
                               <span className="text-danger">*requried</span>
                             )} */}
                  {!isError.defaultBid && (
                    <span className="text-danger">
                      *Bid must be at least $0.02
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {isTabChange === "keywordTargeting" ? (
            <Row className="my-3">
              <Col md={8}>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="custom-input-text">Match Types</h6>
                  <span
                    className="text-end remove-all-link"
                    id="selectAll"
                    // checked={isMatchList.every((item: any) => item.isChecked)}
                    onClick={(e) => {
                      selectedAllAdkeyWords(e);
                    }}
                  >
                    {isMatchList.some((item: any) => !item.isChecked)
                      ? "Select All"
                      : "Deselect All"}
                  </span>
                </div>
              </Col>
              <Col md={8} className="my-3">
                <div
                  className="justify-content-between d-flex flex-wrap flex-sm-nowrap"
                  style={{ gap: "10px" }}
                >
                  {isMatchList?.map((item: any, index) => {
                    return (
                      <div style={{ flexGrow: "1" }}>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`checkbox-${item.id}`}
                            value="Negative exact"
                            checked={item.isChecked}
                            onChange={(e) => {
                              keywordChangeHandler(e, item.id);
                            }}
                          />
                          <label
                            className="form-check-label custom-input-text"
                            htmlFor="inlineRadio3"
                          >
                            {item.title}
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            className="custom-input"
                            type="number"
                            value={item.name}
                            name={item.nameid}
                            placeholder="0"
                            aria-describedby="basic-addon1"
                            onChange={keywordChangedynamic}
                          />
                          <span
                            className="input-span"
                            id="basic-addon1"
                            onClick={(e) => removematchtypeHandler(item.id, e)}
                          >
                            X
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
        <div className="form-button-group">
          <Link to={"/ads-creation"} className="btn btn-outline-primary">
            Back
          </Link>
          <button className="btn btn-primary" type="submit">
            {isSubmitLoading ? (
              <div
                className="d-flex aligh-items-center"
                style={{ cursor: "wait" }}
              >
                Submiting...&nbsp;&nbsp;&nbsp;
                <Box sx={{ display: "flex" }}>
                  <CircularProgress style={{ color: "black" }} size={20} />
                </Box>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};

export default ManualCampaign;
