import React, { Component } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Row, Col } from "react-bootstrap";
import "./index.css";

import LinkIcon from "../../assets/images/icons/link-icon.svg";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import format from "date-fns/format";
import MarketplaceTable from "./table/marketplace-table";
import AdGroupTable from "./table/adgroup-table";
import CampaignTable from "./table/campaign-table";
import PortfoliosTable from "./table/portfolio-table";
import ProductsTable from "./table/products-table";
import TargetingTable from "./table/targeting-table";
import SearchTermsTable from "./table/searchterms-table";
import MasterDataService from "../../services/MasterDataService";
import DateRangeFilter from "../../components/Common/DateRangeFilter/DateRangeFilter";
import { NavLink } from "react-router-dom";
import noConnection from "../../assets/images/no-connection.png";
import DropdownBasic from "../../components/Common/Dropdown";
let emailId;

interface ResultProps {
  searchResult: any;
}

class AdsCompaignManager extends Component<ResultProps> {
  filterData = [];
  current = new Date();
  startDate: any = new Date().setDate(this.current.getDate() - 30);
  endDate = new Date();
  state = {
    globalFilterFromData: [],
    searchKeyFilter: "",
    globalFilterDateRange: [this.startDate, this.endDate],
    metaData: [],
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    selectedStartDate: null,
    selectedEndDate: null,
    activeTab: "marketplace",
    selectedProfile: "Sponsored Product",
    filters: [],
    filtersForStatusTab: {
      pageNumber: 1,
      pageSize: 50,
      sortOptions: [],
      columnFilters: [],
      globalFilters: {
        searchText: "",
        authors: [],
        bookFormats: [],
        dateRanges: {
          dateRange: "Last30Days",
          startDate: null,
          endDate: null,
        },
        titles: [],
        campaigns: [],
        platforms: [],
        marketPlaces: [],
        adTypes: [],
        advancedFilters: [
          {
            logicalOperator: "And",
            conditions: [
              {
                logicalOperator: "And",
                operator: "Equal",
                value: "Enabled",
                secondValue: "",
                columnName: "Status",
              },
            ],
          },
        ],
      },
    },
    dateRangeOptions: [],
    dateRange: "Last30Days",
    adsConnection: localStorage.getItem("adsConnected"),
    selectedAccount: "",
  };

  constructor(props) {
    super(props);
    this.handleDateRangeFilterChange =
      this.handleDateRangeFilterChange.bind(this);
    this.setIsHeaderDataHandler = this.setIsHeaderDataHandler.bind(this);
  }
  setIsHeaderDataHandler(value, data) {
    console.log(value, data);
  }

  componentDidMount() {
    this.getMetaData();
    const { searchResult } = this.props;
    this.setState({ selectedAccount: searchResult });
    console.log(this.props.searchResult);
  }
  
  componentDidUpdate(prevProps: ResultProps) {
    if (this.props.searchResult !== prevProps.searchResult) {
      this.setState({ selectedAccount: this.props.searchResult });
    }
    console.log(this.props.searchResult);
  }

  handleCallback = (childData) => {
    this.setState({ filters: childData });
    this.filterData = childData;
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.setState({ searchKeyFilter: event.target.value });
      event.preventDefault();
    }
  };

  getMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();
      if (metaData) {
        this.setState({ dateRangeOptions: metaData?.dateRanges });
        this.setState({ metaData: metaData });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  handleTabSelect = (tab) => {
    if (
      tab === "portfolios" ||
      tab === "marketplace" ||
      tab === "searchterms"
    ) {
      this.setState((prev: any) => {
        return {
          ...prev,
          filters: {
            ...prev.filters,
            globalFilters: {
              ...prev.filters.globalFilters,
              advancedFilters: [],
            },
          },
        };
      });
    } else {
      this.setState((prev: any) => {
        return {
          ...prev,
          filters: {
            ...prev.filters,
            globalFilters: {
              ...prev.filters.globalFilters,
              advancedFilters: [
                {
                  logicalOperator: "And",
                  conditions: [
                    {
                      logicalOperator: "And",
                      operator: "Equal",
                      value: "Enabled",
                      secondValue: "",
                      columnName: "Status",
                    },
                  ],
                },
              ],
            },
          },
        };
      });
    }

    this.setState({ activeTab: tab });
  };

  handleDateRangeFilterChange = (value) => {
    console.log(value);
    this.setState({ dateRange: value.dateRange });
  };

  onChange = (i, e) => {
    console.log(i);
    console.log(e);
    this.setState({ selectedProfile: e.profile });
  };

  datechangecallback = (value1) => {
    console.log(value1);
    this.setState({ globalFilterDateRange: value1 });
  };

  render() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfThisMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const startOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth(),
      1
    );
    const endOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth() + 1,
      0
    );
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    return (
      <DashboardLayout>
        <div className="">
          {this.state.adsConnection === "true" ? (
            <div>
              <div className="main-cont-header">
                <Row className="page-header">
                  <Col>
                    <div className="main-con-page-title-container flex-wrap">
                      <div className="title">
                        <h3 className="page-title mt-2">Campaign Manager</h3>
                      </div>
                      <div className="filters me-3 mt-2">
                        <style>
                          {`
                          .rs-picker-daterange-header {
                            margin-top: 30px;
                          }
                        `}
                        </style>
                        <DateRangeFilter
                          options={this.state.dateRangeOptions}
                          onChange={this.handleDateRangeFilterChange}
                          value={this.state.dateRange}
                          datechangeCallback={this.datechangecallback}
                        />
                      </div>
                      <div className="filters mt-2 ">
                        <style>
                          {`
                          .rs-picker-daterange-header {
                            margin-top: 30px;
                          }
                        `}
                        </style>
                        <DropdownBasic
                          options={[
                            { profile: "Sponsored Product" },
                            // { profile: "Sponsored Brand" },
                            // { profile: "Sponsored Display" },
                          ]}
                          defaultValue={"Sponsored Product"}
                          value={this.state.selectedProfile}
                          onChange={this.onChange}
                          onChangeHandler={this.setIsHeaderDataHandler}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="main-content-container campaign-manager-content">
                <hr />
                <div className="dashboard-container padding-lr-30 campaign-manager pb-5 ">
                  <div className="tab-container  ">
                    <Tabs
                      activeKey={this.state.activeTab}
                      onSelect={this.handleTabSelect}
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="marketplace" title="Summary">
                        {this.state.activeTab === "marketplace" && (
                          <MarketplaceTable
                            checkBox={false}
                            tabName="Marketplaces"
                            isBulkOperationsEnabled={false}
                            filterData={this.state.filters}
                            metaData={this.state.metaData}
                            searchKey={this.state.searchKeyFilter}
                            filterDateRange={this.state.globalFilterDateRange}
                            condition={this.state.selectedAccount}
                            selectedProfiles={this.state.selectedProfile}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="portfolios" title="Portfolios">
                        {this.state.activeTab === "portfolios" && (
                          <PortfoliosTable
                            isBulkOperationsEnabled={false}
                            tabName="Portfolios"
                            filterData={this.state.filters}
                            metaData={this.state.metaData}
                            searchKey={this.state.searchKeyFilter}
                            filterDateRange={this.state.globalFilterDateRange}
                            condition={this.state.selectedAccount}
                            selectedProfiles={this.state.selectedProfile}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="campaign" title="Campaign">
                        {this.state.activeTab === "campaign" && (
                          <CampaignTable
                            isBulkOperationsEnabled={false}
                            tabName="Campaigns"
                            filterData={this.state.filters}
                            metaData={this.state.metaData}
                            searchKey={this.state.searchKeyFilter}
                            filterDateRange={this.state.globalFilterDateRange}
                            condition={this.state.selectedAccount}
                            selectedProfiles={this.state.selectedProfile}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="adgroup" title="Ad Group">
                        {this.state.activeTab === "adgroup" && (
                          <AdGroupTable
                            isBulkOperationsEnabled={false}
                            tabName="AdGroups"
                            filterData={this.state.filters}
                            metaData={this.state.metaData}
                            searchKey={this.state.searchKeyFilter}
                            filterDateRange={this.state.globalFilterDateRange}
                            condition={this.state.selectedAccount}
                            selectedProfiles={this.state.selectedProfile}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="products" title="Products">
                        {this.state.activeTab === "products" && (
                          <ProductsTable
                            isBulkOperationsEnabled={false}
                            tabName="Products"
                            filterData={this.state.filters}
                            metaData={this.state.metaData}
                            searchKey={this.state.searchKeyFilter}
                            filterDateRange={this.state.globalFilterDateRange}
                            condition={this.state.selectedAccount}
                            selectedProfiles={this.state.selectedProfile}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="serchterms" title="SearchTerms">
                        {this.state.activeTab === "serchterms" && (
                          <SearchTermsTable
                            //  isBulkOperationsEnabled={false}
                            tabName="SearchTerms"
                            filterData={this.state.filters}
                            metaData={this.state.metaData}
                            searchKey={this.state.searchKeyFilter}
                            filterDateRange={this.state.globalFilterDateRange}
                            condition={this.state.selectedAccount}
                            selectedProfiles={this.state.selectedProfile}
                          />
                        )}
                      </Tab>
                      <Tab eventKey="targeting" title="Targeting">
                      {this.state.activeTab === "targeting" && (
                        <TargetingTable
                          isBulkOperationsEnabled={false}
                          tabName="Targets"
                          filterData={this.state.filters}
                          metaData={this.state.metaData}
                          searchKey={this.state.searchKeyFilter}
                          filterDateRange={this.state.globalFilterDateRange}
                          condition={this.state.selectedAccount}
                          selectedProfiles={this.state.selectedProfile}
                        />
                      )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="main-cont-header bookself-container">
                <Row className="page-header">
                  <Col>
                    <div className="main-con-page-title-container">
                      <div className="title">
                        <h3 className="page-title">Campaign Manager</h3>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="main-content-container text-center align-content-center mt-0"
                style={{ height: "70vh" }}
              >
                <img
                  src={noConnection}
                  className="img-fluid no-connection"
                  width={300}
                  alt=""
                ></img>
                <h3>
                  Please connect your Ads Account to view your performance
                  report.
                </h3>
                <p className="mt-3">
                  Go to connection page{" "}
                  <NavLink to={"/my-account/amazon-connection"}>
                    Click here
                  </NavLink>
                </p>
              </div>
            </div>
          )}
        </div>
      </DashboardLayout>
    );
  }
}

export default AdsCompaignManager;
