import React, { useState, useEffect, useRef } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import ReactCountryFlag from "react-country-flag";
let emailId;
const DropdownCountryBasic = ({
  options,
  value,
  onChangeData,
  onChangeHandler,
  defaultValue,
}) => {
  const [dropdownWidth, setDropdownWidth] = useState("200px");
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const showHideDropDownMenu = (event) => {
    event.stopPropagation();
    setShowDropDownMenu(!showDropDownMenu);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
   
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  console.log(options);
  console.log(value);

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDownMenu(false);
    }
  };
  return (
    <div className="DateRangeFilter">
      <div className="time-line" style={{ width: dropdownWidth }}>
        <div
          className={`input-group ${showDropDownMenu ? "clicked" : ""}`}
          style={{ width: dropdownWidth }}
        >
          <div className="custom-dropdown" style={{ width: dropdownWidth }}>
            <span
              className="input-group-text no-arrow"
              style={{ width: dropdownWidth }}
              onClick={showHideDropDownMenu}
              id="inputGroup-sizing-sm"
            >
              <div className="dropdown-header custom-dropdown-header">
              <span className="dateselection date-text-con">
      {value.countryName ? (
        <div>
          {value.countryCode === "UK" ?(
                <ReactCountryFlag
                countryCode="GB"
                style={{
                  width: '25px',
                  height: '25px',
                  borderRadius: '5px',
                  marginRight: '10px',
                }}
                svg
              />
          ):(
            <ReactCountryFlag
            countryCode={value.countryCode}
            style={{
              width: '25px',
              height: '25px',
              borderRadius: '5px',
              marginRight: '10px',
            }}
            svg
          />
          )}
       
          {value.countryName}
        </div>
      ) : (
        <div>
          {defaultValue.length > 0 ? (
            <>
              {defaultValue[1] === "UK" ?(
                <ReactCountryFlag
                countryCode="GB"
                style={{
                  width: '25px',
                  height: '25px',
                  borderRadius: '5px',
                  marginRight: '10px',
                }}
                svg
              />
          ):(
            <ReactCountryFlag
            countryCode={defaultValue[1]}
            style={{
              width: '25px',
              height: '25px',
              borderRadius: '5px',
              marginRight: '10px',
            }}
            svg
          />
          )}
     
              {defaultValue[0]}
            </>
          ) : (
            defaultValue[0]
          )}
        </div>
      )}
    </span>
                <span
                  className={`arrow ${
                    showDropDownMenu ? "up greenArrow" : "down"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M5 13L10 8L15 13"
                      stroke={showDropDownMenu ? "var(--baseColor)" : "#6b7280"}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </span>
            {showDropDownMenu && options && options.length > 0 && (
              <div
                className="dashboard-calendar dropdown-options"
                ref={dropdownRef}
              >
                {options.map((item, i) => (
                  <div
                    className="dropdown-item"
                    onClick={(e) => {
                      onChangeData(defaultValue, item);
                      showHideDropDownMenu(e);
                      e.stopPropagation();
                    }}
                  >
                       {item?.countryCode === "UK" ?(
                <ReactCountryFlag
                countryCode="GB"
                style={{
                  width: '25px',
                  height: '25px',
                  borderRadius: '5px',
                  marginRight: '10px',
                }}
                svg
              />
          ):(
            <ReactCountryFlag
            countryCode={item?.countryCode}
            style={{
              width: '25px',
              height: '25px',
              borderRadius: '5px',
              marginRight: '10px',
            }}
            svg
          />
          )}
            
                    {item?.countryName}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownCountryBasic;
