import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

function Competitor() {
  return (
    <DashboardLayout>
      <div className="main-cont-header bookself-container">Competitor</div>
    </DashboardLayout>
  );
}

export default Competitor;
