import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link } from "react-router-dom";
import logo from "../../assets/brand/logo-resize.png";
import { toast } from "react-toastify";

interface FormState {
  email: any;
  userEmail:string;
  errors: {
    email: string;
  };
}
class SignupSuccess extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      email: "",
      userEmail:localStorage.getItem("userEmail")|| "",
      errors: {
        email: "",
      },
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };

  render() {

    return (
      <DefaultLayout>
        <div className="contanier-type1">
          <form >
            <div className="mb-3 ">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="Logo Small" />{" "}
              </div>
              <h3>Check your email to verify</h3>
              <p>
                Activation link sent to{" "}
                <span className="underline">{this.state.userEmail}</span>.
              </p>
              <p>
                Didn't receive the email? Please check spam folder
              </p>
            </div>
          </form>
        </div>
      </DefaultLayout>
    );
  }
}

export default SignupSuccess;
