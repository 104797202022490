import React, { Component, useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Alert, Col, Form, Row } from "react-bootstrap";
import avatarLogo from "../../../assets/images/icons/logo-resize.png";
import ProfileMailLogo from "../../../assets/images/icons/profile-mail-icon.svg";
import ProfilePhoneLogo from "../../../assets/images/icons/profile-phone-icon.svg";
import ProfileEditLogo from "../../../assets/images/icons/profile-edit-icon.svg";
import Navbar from "../Navbar/navbar";
import "./myProfile.css";
import { elements } from "chart.js";
import { toast } from "react-toastify";
import axios from "axios";
import { log } from "console";
import { isInputElement } from "react-router-dom/dist/dom";
interface ProfileState {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  username: any;
  currencyTypeSelection: any;
  pageReadestimate: string;
  pageReadestimateType: string;
  timeZoneSelection: any;
  timeZoneSelectiontType: any;
  id: string;
  showPassword: boolean;
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
  avatar: string;
  imageUplaod: string;
  profileme: any;
  userimg: any;
  selectedFile: any;
  currency: any;
  pageRead: any;
  ZoneSelection: any;
  userName: string;
  userEmail: string;
  isEditing: boolean;
  phonenumber: any;
  usernameprofile: any;
  errorStatement: string;
  errorShow: boolean;

  // errors: {
  //   newPassword: string;
  //   confirmPassword: string;
  // };
}

export default class MyProfile extends Component<{}, ProfileState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      username: "",
      currencyTypeSelection: [],
      pageReadestimate: "",
      pageReadestimateType: "",
      timeZoneSelection: "",
      id: String(localStorage.getItem("userId")),
      profileme: "",
      showPassword: false,
      newPassword: "",
      confirmPassword: "",
      avatar: "",
      imageUplaod: "",
      userimg: "",
      selectedFile: "",
      currency: "",
      pageRead: "",
      errorStatement: "",
      errorShow: false,
      ZoneSelection: "",
      timeZoneSelectiontType: "",
      userName: "",
      oldPassword: "",
      isEditing: true,
      phonenumber: "",
      usernameprofile: "",
      userEmail: String(localStorage.getItem("userEmail")),
    };
  }

  getProfile = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;

    try {
      const response = await fetch(
        `https://api.lotusppc.com/Users/${this.state.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
        }
      );

      const responseData = await response.json();
      const data = responseData.result;
      this.setState({ userName: data.userName });
      this.setState({ phonenumber: data.phoneNumber === null ? "" : data.phoneNumber });
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  componentDidMount() {
    this.getProfile();
    // this.getpageReadestimate();
    // this.getImageUpload();

    let profileName = localStorage.getItem("userName");
    this.setState({ profileme: profileName });
  }
  // getProfile = async () => {
  //   let userToken = localStorage.getItem("userToken");
  //   let AuthToken = "Bearer " + userToken;

  //   try {
  //     const response = await fetch("https://api.lotusppc.com/Users/Me", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: AuthToken,
  //       },
  //     });

  //     const responceData = await response.json();
  //     console.log(this.state.timeZoneSelectiontType);
  //     console.log(responceData.result.pageReadestimate);
  //     console.log(this.state.pageReadestimate);

  //     console.log("get=", responceData.result);
  //     this.setState({ name: responceData.result.name });
  //     this.setState({ email: responceData.result.email });
  //     this.setState({ currency: responceData.result.currencyTypeSelection });
  //     this.setState({
  //       pageReadestimateType: responceData.result.pageReadestimate,
  //     });
  //     this.setState({
  //       timeZoneSelectiontType: responceData.result.timeZoneSelection,
  //     });
  //     this.setState({ userName: responceData.result.userName });
  //     this.setState({ usernameprofile: responceData.result.userName });
  //     this.setState({ id: responceData.result.id });

  //     this.setState({ password: responceData.result.password });
  //     this.setState({ phoneNumber: responceData.result.phone });
  //     // this.setState({ phonenumber: responceData.result.phone });
  //     this.setState({ userimg: responceData.result.image });
  //     console.log(responceData.result.image);
  //   } catch (error) {
  //     console.error("Error fetching profile:", error);
  //   }
  // };

  handleEventCurrency = (e: { target: { value: any } }) => {
    this.setState({ currency: e.target.value });
  };
  handleEventpageRead = (e: { target: { value: any } }) => {
    this.setState({ pageRead: e.target.value });
  };
  handleEvent = (e: { target: { value: any } }) => {
    this.setState({ ZoneSelection: e.target.value });
    // this.setState({ timeZoneSelectiontType: e.target.value });
  };

  saveChangesClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const { userName, id, phonenumber } = this.state;
    const response = await fetch("https://api.lotusppc.com/Users/UpdateUser", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        userId: id,
        userName: userName,
        phoneNumber: phonenumber,
        isConnected:true,
        isProfileSelected:true
      }),
    });
    const responceData = await response.json();
    console.log("res=", responceData);
    if (response.status === 200) {
      toast("Profile updated successfully");
    }else{
      toast("Profile updated failed");
    }


  };

  handleEditClick = () => {
    const selectedPlan: any = document.querySelectorAll(".disableInput");
    if (selectedPlan.length > 0) {
      for (let i = 0; i < selectedPlan.length; i++) {
        selectedPlan[i].disabled = false;
        selectedPlan[i].classList.add("editable");
      }
    }

    const selectedPlan1: any = document.querySelectorAll(".profileEditBtn");
    if (selectedPlan.length > 0) {
      for (let i = 0; i < selectedPlan1.length; i++) {
        selectedPlan1[i].disabled = false;
        selectedPlan1[i].classList.add("editableBtnOn");
      }
    }
    this.setState({ isEditing: true });
  };

  handleEventPageReadestime = (e: any) => {};
  handleEventTimeZone = (e: any) => {};
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  // changePassword = async (event: React.FormEvent) => {
  //   const { newPassword, confirmPassword, oldPassword } = this.state;

  //   if (newPassword !== confirmPassword) {
  //     alert("New Password and Confirm Password Not Same");
  //   } else if (!newPassword || !confirmPassword || !oldPassword) {
  //     alert("New Password, Confirm Password, and Old Password are Required");
  //   } else {
  //     event.preventDefault();

  //     var data: any = document.getElementById("cancelPasswordBtn");

  //     let userToken = localStorage.getItem("userToken");
  //     let AuthToken = "Bearer " + userToken;

  //     const { id } = this.state;

  //     const response = await fetch(
  //       "https://api.lotusppc.com/Users/RestPassword",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: AuthToken,
  //         },
  //         body: JSON.stringify({
  //           userId: id,
  //           oldPassword:oldPassword,
  //           newPassword: newPassword,
  //           confirmNewPassword:confirmPassword
  //         }),
  //       }
  //     );
  //     console.log(oldPassword);
  //     console.log(confirmPassword);
  //     const responceData = await response.json();
  //     this.setState({ newPassword: "" });
  //     this.setState({ confirmPassword: "" });
  //     this.setState({ oldPassword: "" });

  //     data.click();
  //     toast("Password changed successfully");

  //     const selectedPlan: any = document.querySelectorAll(".disableInput");
  //     if (selectedPlan.length > 0) {
  //       for (let i = 0; i < selectedPlan.length; i++) {
  //         selectedPlan[i].disabled = true;
  //         selectedPlan[i].classList.remove("editable");
  //       }
  //     }

  //     const clearEle: any = document.querySelectorAll(
  //       ".profileEditBtn.editableBtnOn"
  //     );
  //     for (var i = 0; i < clearEle.length; i++) {
  //       clearEle[i].classList.remove("editableBtnOn");
  //     }
  //   }
  // };

  changepassword = () => {
    const { newPassword, confirmPassword, oldPassword, id } = this.state;
    var data: any = document.getElementById("cancelPasswordBtn");
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;

    const requestData = {
      userId: id,
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmPassword,
    };
    if (newPassword !== confirmPassword) {
      this.setState({
        errorStatement: "*New password and confirm password must be the same",
      });
    } else if (!newPassword || !confirmPassword || !oldPassword) {
      this.setState({
        errorStatement:
          "*New password, confirm password, and old password are required",
      });
    } else {
      axios
        .post("https://api.lotusppc.com/Users/ResetPassword", requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          data.click();
          if (res.data.success === true) {
            this.setState({ newPassword: "" });
            this.setState({ confirmPassword: "" });
            this.setState({ oldPassword: "" });
            this.setState({ errorStatement: "" });
            toast("Password changed successfully");
          } else {
            toast("Something went wrong");
          }
        })
        .catch((error) => {
          this.setState({ errorStatement: "" });
          console.log(error);
          if (error.response.status === 400) {
            toast(error.response.data.message);
          } else {
            toast("Something went wrong");
          }
        });
    }
  };


  passwordChangeCancelBtn() {}

  editCancelClick = () => {
    this.getProfile();
  };
  handleImageChange = (e: any) => {};

  handleImageUpload = async () => {
    console.log("avatar ", this.state.imageUplaod);
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const { id } = this.state;
    const response = await fetch(
      "https://api.lotusppc.com/Users/ProfileImage",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          id,
          image: this.state.imageUplaod,
        }),
      }
    );
    const responceData = await response.json();
    console.log("res=", responceData);
  };

  getpageReadestimate = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch(
      "https://api.lotusppc.com/MasterData/Profile_Page_Read_Estimate",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      }
    );
    const responceData = await response.json();
    console.log("pageReadestimate=", responceData.result.data.columns);

    const pageReadestimate: any = responceData.result.data.columns[0].rate;
    this.setState({
      pageReadestimate: pageReadestimate,
    });
    console.log("pageReadestimate ", this.state.pageReadestimate);
  };
  handleFileChange = async (e: { target: { files: any[] } }) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const selectedFile = e.target.files[0];

    try {
      if (selectedFile) {
        console.log("File selected:", selectedFile);

        const formData = new FormData();
        formData.append("file", selectedFile);

        const response = await axios.put(
          "https://api.lotusppc.com/Users/ProfileImage",
          formData,
          {
            headers: {
              Authorization: AuthToken,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Image uploaded:", response.data);
        this.setState({ userimg: response.data.result.image });

        // this.getProfile();
      } else {
        console.error("Please select a file to upload");
      }

      localStorage.removeItem("userImage");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  render() {
    const {
      id,
      newPassword,
      confirmPassword,
      showPassword,
      oldPassword,
      usernameprofile,
    } = this.state;
    const labelStyle = {
      color: "#00976d", // Set color for the label text
    };

    console.log("New Password:", newPassword);

    return (
      <DashboardLayout>
        <div className="container-fluid my-accout-profile">
          <Row className="justify-content-center">
            <Navbar />
            <Col md={8} className="mt-3">
              <div className="container profileCard">
                <div className="profileHeading"></div>
                <Row className="avartarheader mb-3 ">
                  <Col md={10}>
                    <Row>
                      <Col md={12} className="avatarNamediv">
                        <div className="avatarImg">
                          <img
                            src={avatarLogo}
                            alt="avatar"
                            width={90}
                            height={90}
                          />
                        </div>
                        <div className="nameDiv">
                          <h5 className="profile-name">
                            {" "}
                            {this.state.usernameprofile}{" "}
                          </h5>
                          <div className="emailPhoneField">
                            <div className="mailHead">
                              <div className="mail">
                                <img
                                  src={ProfileMailLogo}
                                  alt="mail-icon"
                                  width={20}
                                  height={20}
                                />
                                <p className="profile-email text-center">
                                  {" "}
                                  {this.state.userEmail}
                                </p>
                              </div>
                            </div>
                          </div>

                          <p>
                            <span className="uploadPictureLink text-center ">
                              <label htmlFor="fileInput" style={labelStyle}>
                                UploadPicture
                              </label>
                              <input
                                type="file"
                                id="fileInput"
                                onChange={(e: any) => this.handleFileChange(e)}
                                style={{ display: "none" }}
                              />
                            </span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="profile-form">
                  <Col md={6}>
                    <Row>
                      <Col md={12} className="mb-2">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control disableInput  "
                          value={this.state.userName}
                          onChange={(e: { target: { value: any } }) =>
                            this.setState({ userName: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className="sample mb-2">
                        <label>Password</label>
                        <div className="form-input">
                          <button
                            className="changePassword "
                            data-bs-toggle="modal"
                            data-bs-target="#changePasswordModel"
                          >
                            Change Password
                          </button>
                          <input
                            type="password"
                            value={"********"}
                            className="form-control "
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={12} className="mb-2">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          className="form-control disableInput  "
                          value={this.state.phonenumber}
                          onChange={(e: { target: { value: any } }) =>
                            this.setState({ phonenumber: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md={12} className="mb-2">
                        <label>Currency</label>
                        <select
                          className="form-select form-select-lg form-control drapDownDefaultSelectDarkColor disableInput"
                          aria-label=".form-select-lg example"
                          // value={this.state.currency}
                          onChange={(e: any) => this.handleEventCurrency(e)}
                        >
                          <option selected hidden>
                            {this.state.currency || "Select currency"}
                          </option>
                          {this.state.currencyTypeSelection.map(
                            (
                              list: { currencyCodes: any; countryCodes: any },
                              i: any
                            ) => (
                              <option value={list.currencyCodes}>
                                {list.countryCodes}
                              </option>
                            )
                          )}
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-2">
                        <label>TimeZone</label>
                        <select
                          className="form-select form-select-lg form-control drapDownDefaultSelectDarkColor  disableInput"
                          aria-label=".form-select-lg example"
                          // onChange={this.handleEventTimeZone}
                          onChange={(e: any) => this.handleEvent(e)}
                        >
                          <option selected hidden className="">
                            {this.state.timeZoneSelectiontType || "Select time"}
                          </option>
                          <option
                            key={1}
                            value={this.state.timeZoneSelection.EasternTimeZone}
                          >
                            {this.state.timeZoneSelection.EasternTimeZone}
                          </option>
                          <option
                            key={2}
                            value={this.state.timeZoneSelection.CentralTimeZone}
                          >
                            {this.state.timeZoneSelection.CentralTimeZone}
                          </option>
                          <option
                            key={3}
                            value={this.state.timeZoneSelection.WesternTimeZone}
                          >
                            {this.state.timeZoneSelection.WesternTimeZone}
                          </option>
                        </select>
             
                      </Col>
                    </Row> */}
                    <Row>
                      {/* <Col md={12} className="mb-2">
                        <label>Time Zone Selection</label>
                        <select
                          className="form-select form-select-lg form-control drapDownDefaultSelectDarkColor  disableInput"
                          aria-label=".form-select-lg example"
                          disabled
                          // onChange={this.handleEventTimeZone}
                          onChange={(e: any) => this.handleEvent(e)}
                        >
                          <option selected hidden className="">
                            {this.state.timeZoneSelectiontType || "Select time"}
                          </option>
                          <option
                            key={1}
                            value={this.state.timeZoneSelection.EasternTimeZone}
                          >
                            {this.state.timeZoneSelection.EasternTimeZone}
                          </option>
                          <option
                            key={2}
                            value={this.state.timeZoneSelection.CentralTimeZone}
                          >
                            {this.state.timeZoneSelection.CentralTimeZone}
                          </option>
                          <option
                            key={3}
                            value={this.state.timeZoneSelection.WesternTimeZone}
                          >
                            {this.state.timeZoneSelection.WesternTimeZone}
                          </option>
                        </select>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
                {this.state.isEditing && (
                  <div className="submitBtn mb-2">
                    <Col md={12} className="align-content-center">
                      <button
                        className="cancelBtn mt-2"
                        onClick={this.editCancelClick}
                      >
                        Cancel
                      </button>
                      <button
                        className="saveChangesBtn mt-2"
                        onClick={this.saveChangesClick}
                      >
                        Save Changes
                      </button>
                    </Col>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <div
            className="modal fade"
            id="changePasswordModel"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content changePasswordModel">
                <Row className="changePasswordHeading">
                  <Form id="passwordForm">
                    <Col md={12} className="changePasswordForm">
                      <div>
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "relative" }}
                        >
                          <h5>Change Password</h5>
                          <button
                            type="reset"
                            className="cancelPasswordBtn"
                            id="cancelPasswordBtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={this.passwordChangeCancelBtn}
                          >
                            X
                          </button>
                        </div>

                        <div
                          className="text-danger mb-3"
                          style={{ fontSize: "14px" }}
                        >
                          {this.state.errorStatement}
                        </div>
                      </div>{" "}
                      <div className="oldPassword">
                        <div className="form-input">
                          <label className="mb-2">Old Password</label>
                          <input
                            type="text"
                            placeholder="Enter old password"
                            className="form-control"
                            onChange={(e: { target: { value: any } }) =>
                              this.setState({ oldPassword: e.target.value })
                            }
                            value={oldPassword}
                          />
                        </div>
                      </div>
                      <div className="newPassword">
                        <div className="form-input">
                          <label className="mb-2">New Password</label>
                          <input
                            type="text"
                            placeholder="Enter new password"
                            className="form-control"
                            onChange={(e: { target: { value: any } }) =>
                              this.setState({ newPassword: e.target.value })
                            }
                            value={newPassword}
                          />
                        </div>
                      </div>
                      <div className="confirmPassword sample">
                        <label className="mb-2">Confirm Password</label>
                        <div className="form-input ">
                          <input
                            type={"text"}
                            placeholder="Enter confirm password"
                            className="form-control"
                            value={confirmPassword}
                            onChange={(e: { target: { value: any } }) =>
                              this.setState({ confirmPassword: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <div className="">
                      <button
                        type="button"
                        className="cangePasswordBtn mt-3"
                        onClick={this.changepassword}
                      >
                        Change Password
                      </button>
                    </div>
                  </Form>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}
