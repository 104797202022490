import React, { Component } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Col, Row } from "react-bootstrap";
import Navbar from "../Navbar/navbar";
import "./notification.css";
import { log } from "console";
import LogoIcon from "../../../assets/brand/logo-icon.svg";
import BasicLoader from "../../../components/BasicLoader";

interface NotificationState {
  notificationCategories: any[];
  isLoading: boolean;
  error?: string | null;
  id:string
  notificationData:any
}

export default class Notification extends Component<{}, NotificationState> {
  state: NotificationState = {
    notificationCategories: [],
    isLoading: true,
    error: null,
    id: String(localStorage.getItem("userId")),
    notificationData: []
  
  };

  componentDidMount() {
    this.getProfile();
  }
  
  getProfile = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
  
    try {
      const response = await fetch(
        `https://api.lotusppc.com/Users/${this.state.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      const data = responseData.result.notifications;
  
      console.log(data.length);
  
      if (Array.isArray(data) && data.length === 0) {
        console.log("ghhgg");
        this.setState({
          notificationData: {
            dailyReport: false,
            weeklyReport: false,
            monthlyReport: false,
            quarterlyReport: false,
            yearlyReport: false
          }
        });
      } else {
        console.log(data);
        this.setState({ notificationData: data });
      }
  
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  handleToggleEnable = (reportType, value) => {
    this.setState(
      (prevState) => ({
        notificationData: prevState.notificationData.map((item, index) => {
          if (index === 0) { 
            return {
              ...item,
              [reportType]: value,
            };
          }
          return item;
        }),
      }),
      () => this.updateNotificationSettings(reportType)
    );
  };


  updateNotificationSettings = async (reportType) => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const requestdata ={
      userId:this.state.id,
      notifications:this.state.notificationData,
      userName:null,
      phoneNumber:null,
      isConnected:true,
      isProfileSelected:true

    }
    console.log(requestdata);
    

    try {
      const response = await fetch(
        `https://api.lotusppc.com/Users/UpdateUser`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify(requestdata),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Notification settings updated:", responseData);
    } catch (error) {
      console.error("Error updating notification settings:", error);
    }
  };
  

  render() {
    const { notificationCategories, isLoading, error } = this.state;
    console.log(notificationCategories);

    return (
      <DashboardLayout>
        <div className="container-fluid my-account-notification">
        <Row>
        <Navbar />
        <Col md={12}>
          <div className="container notificationCard">
            <Col md={12}>
              <div className="row notificationContainer">
                <div className="col-md-6 Notification">
                  <div className="type-email">
                    <h6>Email Notifications</h6>
                    <p>
                      Get emails to find out what’s going on when you’re not
                      online.
                    </p>
                  </div>
                </div>
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="emailBodyContent">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="dailyReportSwitch"
                          checked={this.state.notificationData[0]?.dailyReport}
                          onChange={(e) => this.handleToggleEnable('dailyReport' ,e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="dailyReportSwitch"></label>
                      </div>
                      <div className="rightContent">
                        <h6>Daily Report</h6>
                        <p>
                          Get a daily summary to keep track of all activities and updates.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="emailBodyContent">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="weeklyReportSwitch"
                          checked={this.state.notificationData[0]?.weeklyReport}
                          onChange={(e) => this.handleToggleEnable('weeklyReport' ,e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="weeklyReportSwitch"></label>
                      </div>
                      <div className="rightContent">
                        <h6>Weekly Report</h6>
                        <p>
                          A weekly overview to help you stay informed about important developments.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="emailBodyContent">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="monthlyReportSwitch"
                          checked={this.state.notificationData[0]?.monthlyReport}
                          onChange={(e) => this.handleToggleEnable('monthlyReport' ,e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="monthlyReportSwitch"></label>
                      </div>
                      <div className="rightContent">
                        <h6>Monthly Report</h6>
                        <p>
                        A detailed monthly report that summarizes the key events and progress.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="emailBodyContent">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="quarterlyReportSwitch"
                          checked={this.state.notificationData[0]?.quarterlyReport}
                          onChange={(e) => this.handleToggleEnable('quarterlyReport' ,e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="quarterlyReportSwitch"></label>
                      </div>
                      <div className="rightContent">
                        <h6>Quarterly Report</h6>
                        <p>
                        Comprehensive quarterly insights into performance and achievements.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              
                <Row className="mt-2">
                  <Col md={6}>
                    <div className="emailBodyContent">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="yearlyReportSwitch"
                          checked={this.state.notificationData[0]?.yearlyReport}
                          onChange={(e) => this.handleToggleEnable('yearlyReport' ,e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="yearlyReportSwitch"></label>
                      </div>
                      <div className="rightContent">
                        <h6>Yearly Report</h6>
                        <p>
                        An annual review that highlights significant milestones and outcomes.
                        </p>
                      </div>
                    </div>
                  </Col>
                
                </Row>
                
              </div>
            </Col>
          </div>
        </Col>
      </Row>
        </div>
      </DashboardLayout>
    );
  }
}
