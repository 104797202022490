import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

function Inventory() {
  return (
    <DashboardLayout>
      <div className="main-cont-header bookself-container">Inventory</div>
    </DashboardLayout>
  );
}

export default Inventory;
