import React, { createContext, useEffect, useContext, useState } from "react";
import Sidebar from "../../shared/Sidebar";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { Container, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import "./index.css";
import DashboardService from "../../services/DashboardService";
import BasicLoader from "../../components/BasicLoader";
import { da } from "date-fns/locale";
interface Props {
  children: React.ReactNode;
}
const HeaderDataContext = createContext<any>(null);

export const useHeaderData = () => useContext(HeaderDataContext);

const DashboardLayout: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isHeaderData, setIsHeaderData] = useState<any>(null);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const handleRefresh = async () => {
    setIsRefreshing(true);
    await IsSyncCompleted();
  };
  // const planHandler = () => {
  //   let plan: any = localStorage.getItem("planStatus");
  //   let planStatus = JSON.parse(plan);
  //   if (!planStatus) {
  //     return navigate(`/plan-expriy`);
  //   }
  // };

  const IsSyncCompleted = async () => {
    setLoading(false);
    // try {
    //   if (!localStorage.getItem("userToken")) {
    //     return <Navigate to={"/sign-in"} />;
    //   }
    //   const lastSyncInfo = await DashboardService.getLastSyncData();
    //   if (
    //     lastSyncInfo &&
    //     lastSyncInfo.kdpSyncStatus &&
    //     lastSyncInfo.adsSyncStatus
    //   ) {
    //     setSyncCompleted(true);
    //   }
    // } catch (error) {
    //   console.error("Error fetching Last Sync Status:", error);
    // } finally {
    //   setLoading(false);
    //   setIsRefreshing(false);
    // }
  };

  useEffect(() => {
    // setIsRefreshing(true);
    IsSyncCompleted();
    // planHandler();
  }, []);
  const setIsHeaderDataHandler = (value, data) => {
    setIsHeaderData({ data: data, value: value });
  };

  return (
    <HeaderDataContext.Provider value={{ isHeaderData, setIsHeaderData }}>
      <Container className="admin-bg p-0 dashboard-layout" fluid>
        <div className="sidebar-main-container">
          <Sidebar />
        </div>
        <div className="maincont mt-3">
          {/* <Header  /> */}
          {loading ? (
            <main>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  minHeight: "500px",
                  marginTop: "140px",
                }}
                className="horizontalBarChart"
              >
                <BasicLoader />
                <br />
              </div>
            </main>
          ) : !syncCompleted ? (
            <main>{props.children}</main>
          ) : null}
        </div>
        <Footer />
      </Container>
    </HeaderDataContext.Provider>
  );
};
export default DashboardLayout;
