// NumberFormatter.js
import React from 'react';
const currencySymbols = {
  
    USD: '$',
    GBP: '£',
    EUR: '€',
    JPY: '¥',
    BRL: 'R$',
    CAD: 'CA$',
    INR: '₹',
    AUD: 'A$',
    MXN: 'Mex$'
};
const formatNumber = (value) => {
    if (value >= 1e6) {
        return (value / 1e6).toFixed(1) + 'M';
    } else if (value >= 1e3) {
        return (value / 1e3).toFixed(1) + 'K';
    } else {
        return value.toString();
    }
};
const GetCurrencySymbol = (currencyType) =>{

  if(currencySymbols.hasOwnProperty(currencyType))
  {
    return currencySymbols[currencyType];
  }
  return '$';
};

const NumberFormatter = ({ value , currencyType ='' }) => {
    const formattedValue = formatNumber(value);
    let prefix = '';
if(currencyType){
prefix = GetCurrencySymbol(currencyType);

}
    return <text title={value}>{prefix}{formattedValue}</text>;
};

export default NumberFormatter;
