import BaseService from './BaseService';

class UserService extends BaseService {
    static API_URL = '/Users';
    static cacheKeyPrefix_GetProfile = 'users_get_profile';

    static logErrorAndThrow(errorMessage: string, error: any): void {
        console.error(errorMessage, error);
        throw error;
    }

    static async UpdateProfilePreferences<T>(requestData = {}): Promise<any> {
        const mergedRequestData = { ...requestData };     

        return await BaseService.handleRequestNoCache<T>(
            this.API_URL,
            mergedRequestData,
            'Onboarding/ProfilePreferences',
            BaseService.put
        );
    }

    static async GetUserProfile<T>(requestData = {}): Promise<any> {
        const mergedRequestData = { ...requestData };

        return await BaseService.handleRequest<T>(
            this.cacheKeyPrefix_GetProfile,
            this.API_URL,
            mergedRequestData,
            'Profile',
            BaseService.get
        );
    }
    
}


export default UserService;
