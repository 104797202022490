import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { SHA256 } from "crypto-js";

class BaseService {
  private static baseUrl: string = "https://api.lotusppc.com"; // Set your base API URL here
  private static authToken: string | null = null;
  static cache = {};
  static cacheExpiry = 5 * 60 * 1000; // 5 minutes in milliseconds

  static generateStringKey = (jsonData) => {
    return SHA256(JSON.stringify(jsonData)).toString();
  };

  static setAuthToken(token: string | null): void {
    BaseService.authToken = token;
  }

  static async getAuthToken(): Promise<string | null> {
    // You can implement logic to refresh the token if needed

    if (!BaseService.authToken) {
      BaseService.authToken = localStorage.getItem("userToken");
    }

    return BaseService.authToken;
  }

  static getBaseUrl(): string {
    return BaseService.baseUrl;
  }

  private static async getHeaders(): Promise<Record<string, string>> {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    const authToken = await BaseService.getAuthToken();
    if (authToken) {
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    return headers;
  }

  private static async handleError(error: any): Promise<never> {
    console.error("Request failed:", error.message || error);
    throw new Error("Request failed");
  }

  private static async request<T>(
    method: string,
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const headers = await BaseService.getHeaders();
    const axiosConfig: AxiosRequestConfig = {
      method,
      url: `${BaseService.baseUrl}${url}`,
      headers,
      data,
      ...config,
    };

    try {
      const response = await axios(axiosConfig);
      return response?.data;
    } catch (error) {
      await BaseService.handleError(error);
      // Make sure to return a rejected promise here
      return Promise.reject(error);
    }
  }

  static async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return BaseService.request<T>("get", url, undefined, config);
  }

  static async getWithQueryParams<T>(
    url: string,
    queryParams: Record<string, any>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const fullUrl = `${url}?${new URLSearchParams(queryParams)}`;
    return BaseService.get<T>(fullUrl, config);
  }

  static async post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return BaseService.request<T>("post", url, data, config);
  }

  static async put<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return BaseService.request<T>("put", url, data, config);
  }

  static async patch<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return BaseService.request<T>("patch", url, data, config);
  }

  static async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return BaseService.request<T>("delete", url, undefined, config);
  }

  private static ongoingRequests: Record<string, Promise<any> | null> = {};

  private static isRequestOngoing(cacheKey: string): boolean {
    return Boolean(BaseService.ongoingRequests[cacheKey]);
  }

  private static getRequestPromise(cacheKey: string): Promise<any> {
    return BaseService.ongoingRequests[cacheKey]!;
  }

  private static createRequestPromise(
    cacheKey: string,
    apiUrl: string,
    cacheKeyPrefix: string,
    requestData: any,
    urlSuffix: string,
    requestFunction: (url: string, requestData: any) => Promise<any>
  ): Promise<any> {
    const requestPromise = this.handleResponse(
      cacheKeyPrefix,
      apiUrl,
      requestData,
      urlSuffix,
      requestFunction
    );

    BaseService.ongoingRequests[cacheKey] = requestPromise;

    return requestPromise;
  }

  private static clearRequestPromise(cacheKey: string): void {
    BaseService.ongoingRequests[cacheKey] = null;
  }

  static async handleRequestNoCache<T>(
    apiUrl: string,
    requestData: any,
    urlSuffix: string,
    requestFunction: (url: string, requestData: any) => Promise<any>
  ): Promise<T> {
    const url = `${apiUrl}/${urlSuffix}`;
    try {
      const response: { success: boolean; result: T; message?: string } =
        await requestFunction(url, requestData);

      if (response.success) {
        return response.result;
      } else {
        BaseService.logErrorAndThrow("API error:", response.message);
        throw new Error("API error");
      }
    } catch (error) {
      BaseService.logErrorAndThrow(
        `Error fetching data for ${urlSuffix}:`,
        error
      );
      throw error;
    }
  }

  // Common method to handle API responses and caching
  static async handleRequest<T>(
    cacheKeyPrefix: string,
    apiUrl: string,
    requestData: any,
    urlSuffix: string,
    requestFunction: (url: string, requestData: any) => Promise<any>
  ): Promise<T> {
    const cacheKey =
      cacheKeyPrefix + BaseService.generateStringKey(requestData);
    const cachedData = BaseService.cache[cacheKey];

    if (
      cachedData &&
      Date.now() - cachedData.timestamp < BaseService.cacheExpiry
    ) {
      console.log("Data found in cache:", cachedData.data);
      return cachedData?.data;
    }

    // Check if there's an ongoing request for the same data
    if (BaseService.isRequestOngoing(cacheKey)) {
      // Wait for the ongoing request to complete and return its result
      return BaseService.getRequestPromise(cacheKey);
    }

    // If not, create a new promise for the current request
    const requestPromise = BaseService.createRequestPromise(
      cacheKey,
      apiUrl,
      cacheKeyPrefix,
      requestData,
      urlSuffix,
      requestFunction
    );

    // Once the promise is settled, reset the ongoing promise to null
    requestPromise.finally(() => {
      BaseService.clearRequestPromise(cacheKey);
    });

    return requestPromise;
  }

  // Common method to handle API responses and caching
  private static async handleResponse<T>(
    cacheKeyPrefix: string,
    apiUrl: string,
    requestData: any,
    urlSuffix: string,
    requestFunction: (url: string, requestData: any) => Promise<any>
  ): Promise<T> {
    try {
      const cacheKey =
        cacheKeyPrefix + BaseService.generateStringKey(requestData);

      // Check if data is in cache and not expired
      const cachedData = BaseService.cache[cacheKey];
      if (
        cachedData &&
        Date.now() - cachedData.timestamp < BaseService.cacheExpiry
      ) {
        console.log("Data found in cache:", cachedData?.data);
        return cachedData?.data;
      }

      const url = `${apiUrl}/${urlSuffix}`;
      const response: { success: boolean; result: T; message?: string } =
        await requestFunction(url, requestData);

      if (response.success) {
        // Store data in cache with timestamp
        BaseService.cache[cacheKey] = {
          data: response.result,
          timestamp: Date.now(),
        };
        return response.result;
      } else {
        BaseService.logErrorAndThrow("API error:", response.message);
        throw new Error("API error"); // Add this line to ensure a value is always returned
      }
    } catch (error) {
      BaseService.logErrorAndThrow(
        `Error fetching data for ${urlSuffix}:`,
        error
      );
      throw error; // Add this line to ensure a value is always returned
    }
  }

  static logErrorAndThrow(errorMessage, error) {
    console.error(errorMessage, error);
    throw error;
  }

  static calculateDateRange(offsetDays = 30) {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - offsetDays);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(endDate.getDate() + 1);

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
  }
}

export default BaseService;
