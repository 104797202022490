import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import BackArrow from "../../../../assets/images/icons/back-arrow-icon.svg";
import BackArrow from "../../../assets/images/icons/back-arrow-icon.svg";
function LastAppSync() {
  const lastUpdatedDate = localStorage.getItem("lastUpdatedDate") || null;
  return (
    <Container fluid>
      <div className="main-cont-header bookself-container">
        <Row className="page-header">
          <Col>
            <div className="main-con-page-title-container">
              <div className="back-arrow-container">
                <Link to={"/ads-creation"}>
                  <i>
                    <img src={BackArrow} alt="refresh icon" />
                  </i>
                  Create Ads
                </Link>
              </div>
            </div>
          </Col>
          {/* <Col className="text-end last-sync">
            <span>Last App Sync :</span>
            <span className="time-summery">
              {lastUpdatedDatess || "Not available"}
            </span>
          </Col> */}
        </Row>
      </div>
    </Container>
  );
}

export default LastAppSync;
