import React, { Component } from "react";
import "./index.css";
import BellIcon from "../../assets/images/icons/bell-ring (4).svg";
import DropdownBasic from "../../components/Common/Dropdown";
import DropdownCountryBasic from "../../components/Common/CountryDropdown";
import ProfileDropIcon from "../../assets/images/icons/profile-down-arrow.svg";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { getName } from "country-list";

interface Profile {
  name: string;
  profileId: string;
}

interface Country {
  countryName: string;
  countryCode: string;
}

interface MenuItem {
  id: number;
  title: string;
  to: string;
  icon?: string;
}

interface HeaderProps {
  onSearch: (selectedAccount: string) => void;
}

interface State {
  userNames: string | null;
  userImage: string | null;
  marketPlace: any;
  profile: any;
  profilesId: string;
  userName: string;
  menu: MenuItem[];
  country: Country[];
  data: { profile: string }[];
  userId: string | null;
  username: string | null;
  profiledata: any;
  profileName: any;
  expanded: any;
}

class Header extends Component<HeaderProps, State> {
  constructor(props: HeaderProps) {
    super(props);
    this.setIsHeaderDataHandler = this.setIsHeaderDataHandler.bind(this);
    this.state = {
      userNames: localStorage.getItem("userName"),
      userImage: localStorage.getItem("userimg"),
      marketPlace: "",
      profile: [],
      profilesId: "",
      userName: "",
      profileName: "",
      expanded: false,
      menu: [
        { id: 1, title: "Profile", to: "/my-account/profile" },
        { id: 2, title: "Settings", to: "/my-account/billing" },
        { id: 3, title: "Notification", to: "/my-account/notification" },
        { id: 4, title: "Connections", to: "/my-account/amazon-connection" },
        { id: 5, title: "Sign out", to: "/sign-in", icon: "logout-logo" },
      ],
      country: [],
      data: [{ profile: "Profiles" }],
      userId: localStorage.getItem("userId"),
      username: localStorage.getItem("userName"),
      profiledata: [],
    };
  }
  // constructor(props: profileId) {
  //   super(props);
  //   this.setIsHeaderDataHandler = this.setIsHeaderDataHandler.bind(this);
  // }

  setIsHeaderDataHandler(value, data) {
    console.log(value, data);
  }

  componentDidMount() {
    this.getProfile();
  }

  getProfile = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;

    try {
      const response = await fetch(
        `https://api.lotusppc.com/Users/${this.state.userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
        }
      );

      const responseData = await response.json();
      const data = responseData.result.connection[0].adsConnect.adsProfiles;
      this.setState({ profiledata: data });
      console.log(data);

      const account = localStorage.getItem("account");
      this.props.onSearch(account ? account : data[0].profileId);
      // this.props.onSearch(data[0].profileId);
      const pfName = localStorage.getItem("profileName");
      this.setState({ profileName: pfName ? pfName : data[0].name });

      const uniqueProfileNames = Array.from(
        new Set(data.map((profile) => profile.name))
      );
      console.log(uniqueProfileNames);

      const profileValues = uniqueProfileNames.map((name) => ({
        profile: name,
      }));
      this.setState({ profile: profileValues });
      console.log(uniqueProfileNames[0]);

      const countryName =
        responseData.result.connection[0].adsConnect.adsProfiles;
      const countryNameValue = countryName
        .filter((item) => item.name === uniqueProfileNames[0])
        .map((country) => {
          if (country.countryCode !== "UK") {
            return {
              countryName: getName(country.countryCode),
              countryCode: country.countryCode,
            };
          } else {
            return {
              countryName: "United Kingdom",
              countryCode: country.countryCode,
            };
          }
        });
      const countryLocalValue: any = localStorage.getItem("country");
      const countryNameLocalValue: any = localStorage.getItem("countryName");
      this.setState({
        marketPlace: {
          countryCode: countryLocalValue
            ? countryLocalValue
            : countryNameValue[0].countryCode,
          countryName: countryNameLocalValue
            ? countryNameLocalValue
            : countryNameValue[0].countryName,
        },
      });

      this.setState({ country: countryNameValue });
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  onChange = (i, e) => {
    console.log(i);
    console.log(e);

    this.setState({
      marketPlace: { countryCode: e.countryCode, countryName: e.countryName },
    });
    localStorage.setItem("country", e.countryCode);
    localStorage.setItem("countryName", e.countryName);
    const selectedProfileId: Profile[] = this.state.profiledata.filter(
      (item: any) =>
        item.name === this.state.profileName &&
        item.countryCode === e.countryCode
    );
    localStorage.setItem("account", selectedProfileId[0].profileId);
    this.props.onSearch(selectedProfileId[0].profileId);
    console.log(selectedProfileId);
  };

  onChangeMarketPlace = (i, e) => {
    console.log(i);
    console.log(e.profile);
    this.setState({ profilesId: e.profile });

    const countryNameValue = this.state.profiledata
      .filter((item) => item.name === e.profile)
      .map((country) => {
        if (country.countryCode !== "UK") {
          return {
            countryName: getName(country.countryCode),
            countryCode: country.countryCode,
          };
        } else {
          return {
            countryName: "United Kingdom",
            countryCode: country.countryCode,
          };
        }
      });
    console.log(countryNameValue);

    this.setState({ country: countryNameValue });
    this.setState({ profileName: e.profile });
    localStorage.setItem("profileName", e.profile);
    this.setState({
      marketPlace: {
        countryCode: countryNameValue[0].countryCode,
        countryName: countryNameValue[0].countryName,
      },
    });
    localStorage.setItem("country", countryNameValue[0].countryCode);
    localStorage.setItem("countryName", countryNameValue[0].countryName);

    const selectedCountryCode = this.state.profiledata.filter(
      (item) => item.name === e.profile
    );
    const selectedProfileId: Profile[] = this.state.profiledata.filter(
      (item: any) =>
        item.name === e.profile &&
        item.countryCode === selectedCountryCode[0].countryCode
    );
    this.props.onSearch(selectedProfileId[0].profileId);
    localStorage.setItem("account", selectedProfileId[0].profileId);
    console.log(selectedProfileId);
  };

  handleSignOut = () => {
    localStorage.clear();
    window.location.href = "/sign-in";
  };
  handleDocumentClick(event) {}

  showHideDropDownMenu(event) {
    event.stopPropagation();
  }

  handleToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    return (
      <>
        <Navbar expand="lg" expanded={this.state.expanded}>
          <div className="d-flex justify-content-between">
          <div className="d-flex gap-1 flex-wrap">
            {this.state.profile.length >= 0 && (
              <DropdownBasic
                options={this.state.profile}
                defaultValue={this.state.profileName || "Profiles"}
                value={this.state.profilesId}
                onChange={this.onChangeMarketPlace}
                onChangeHandler={this.setIsHeaderDataHandler}
              />
            )}

            <DropdownCountryBasic
              options={this.state.country}
              defaultValue={
                [
                  this.state.country[0]?.countryName || "MarketPlaces",
                  this.state.country[0]?.countryCode,
                ] || ["MarketPlaces"]
              }
              value={this?.state?.marketPlace}
              onChangeData={this.onChange}
              onChangeHandler={this.setIsHeaderDataHandler}
            />
          </div>

          
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={this.handleToggle}
        />
        </div>

          <Navbar.Collapse
            id="navbarScroll"
            className={
              this.state.expanded ? "collapse-expanded" : "collapse-collapsed"
            }
          >
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <Nav className="justify-content-end">
              <Nav.Link
                href="#action1"
                className="mx-4 custom-switch-container"
              ></Nav.Link>
              <Nav.Link href="#action2" className="ms-2">
                <img src={BellIcon} alt="" />
              </Nav.Link>
              <div className="vr mx-4"></div>
              <NavDropdown
                className="ms-4"
                title={
                  <div>
                    <div className="profile-icon d-flex align-items-center">
                      <span className="profile-userName">
                        {this.state.username ? this.state.username : "LotusPPC"}
                      </span>
                      <span>
                        <img
                          src={ProfileDropIcon}
                          alt="drop icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            top: "2px",
                            left: "2px",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                }
                id="navbarScrollingDropdown"
              >
                {this.state.menu.map((item, index) => {
                  if (item.title === "Sign out") {
                    return (
                      <NavDropdown.Item
                        className="mt-1 navdropdown"
                        href="#"
                        key={index}
                        onClick={this.handleSignOut}
                      >
                        <i className={item.icon}></i>
                        <span className="menu-title">{item.title}</span>
                      </NavDropdown.Item>
                    );
                  }
                  return (
                    <NavDropdown.Item
                      className="mt-1 navdropdown"
                      href={item.to}
                      key={index}
                    >
                      <i className={item.icon}></i>
                      <span className="menu-title">{item.title}</span>
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </>
    );
  }
}

export default Header;
