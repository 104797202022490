// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Component } from "react";
// import DashboardLayout from "../../layouts/DashboardLayout";
// import { Row, Col } from "react-bootstrap";
// import "./index.css";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import ColumnFilter from "../../components/Filters/cloumn-filter";
// import ConditionFilter from "../../components/Filters/condition-filter";

// import BookshelfTable from "../../components/CusDataTable/bookshelf-table";
// import FilterIcon from "../../assets/images/icons/filter-icon.svg";
// import PauseIcon from "../../assets/images/icons/pause.svg";
// import ExportIcon from "../../assets/images/icons/export.svg";
// import TagIcon from "../../assets/images/icons/tag-icon.svg";
// import Moment from "moment";
// import { toast } from "react-toastify";
// import MasterDataService from "../../services/MasterDataService";
// import { confirmAlert } from "react-confirm-alert";
// import NotificationIcon from "../../assets/images/icons/tag-del-notification.png";
// import { Link } from "react-router-dom";

// class Bookshelfs extends Component {
//   filterData: any = [];
//   state = {
//     manageTagsInitial: [],
//     isConnection: false,
//     isLoading: false,
//     manageTags: [{ tag: "", initialTag: "", count: "" }],
//     manageTagsEdit: [],
//     globalFilterFromData: [],
//     searchKeyFilter: "",
//     globalFilterDateRange: [],
//     filterOptionData: [],
//     paginationList: [],
//     perPage: 50,
//     total: 0,
//     currPage: 1,
//     lastPage: 0,
//     nextPage: 0,
//     prevPage: 0,
//     dropdownDatas: [],
//     metaData: [],
//     lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
//     selectedTab: "Status",
//     statusTarget: "",
//     targeted: "",
//     datainput: "",
//     selectedRowid: [],
//     marketplace: "",
//     selctedunit: "",
//     setTagacos: "",

//     setexpenses: "",
//     SetTagsAdd: "",
//     apiLoading: true,
//     DataLoading: true,
//     countSelected: 0,
//     tablePageRefresh: false,
//     hideColumn: [],
//     hiddenColumns: [],
//     exporting: false,
//   };

//   constructor(props) {
//     super(props);
//     this.onChangeDateRangeSubmitGlobal =
//       this.onChangeDateRangeSubmitGlobal.bind(this);
//     console.log(props);

//     // this.child = React.createRef();
//   }
//   componentDidMount() {
//     this.getMetaData();
//     this.manageHandler();
//   }

//   handleNvEnter = (event) => {
//     console.log("Nv Enter:", event);
//   };
//   handleCallback = (childData) => {
//     console.log(childData);

//     this.setState({ globalFilterFromData: childData });
//     this.filterData = childData;
//   };
//   handleKeyDown = (event: any) => {
//     if (event.key === "Enter") {
//       this.setState({ searchKeyFilter: event.target.value });
//       event.preventDefault();
//     }
//   };
//   updateHideColumn = (hideArr) => {
//     console.log("Book Shelf Hide Column: ", hideArr);
//     this.setState({ hideColumn: hideArr });
//   };
//   applyColumns = () => {
//     this.setState({ hiddenColumns: this.state.hideColumn });
//     this.cancelDropdown();
//   };

//   onChangeDateRangeSubmitGlobal(e) {
//     console.log(Moment(e[0]).format("YYYY-MM-DD"));
//     console.log(e[1]);
//     let dateRange = e;
//     if (dateRange.length > 0) {
//       this.setState({ globalFilterDateRange: dateRange });
//     }
//   }

//   applyDataLength = (e) => {
//     console.log("dataSize: ", e.target.value);
//     this.setState({ perPage: parseInt(e.target.value) });
//   };

//   handleCallbackTotalData = (childData) => {
//     console.log("Child Bookshelf Table data: ", childData);
//     this.setState({ total: childData.total });
//     this.setState({ currPage: childData.currPage });
//     this.setState({ lastPage: childData.lastPage });
//     this.setState({ nextPage: childData.nextPage });
//     this.setState({ perPage: childData.perPage });
//     this.setState({ prevPage: childData.prevPage });
//     this.setState({ dropdownDatas: childData.dropdownDatas });
//   };
//   handleSelectedRowCallBack = (rowIds) => {
//     console.log("Selected Row Id's: ", rowIds);
//     this.setState({ selectedRowid: rowIds });
//     console.log("Selected Row Id's" + rowIds.length);
//     this.setState({ countSelected: rowIds.length });
//   };

//   applyPagination = (e, pageNo: any) => {
//     console.log("pagination no: ", pageNo);
//     this.setState({ currPage: pageNo });
//   };
//   handleChange = (event, value: number) => {
//     this.setState({ currPage: value });
//   };

//   getMetaData = async () => {
//     try {
//       const metaData = await MasterDataService.getMetaData();
//       this.setState({ metaData: metaData });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   cancelDropdown = () => {
//     const eleRm: any = document.getElementsByClassName("dropdown-menu");
//     for (let i = 0; i < eleRm.length; i++) {
//       eleRm[i].classList.remove("show");
//     }
//   };

//   selectTab = (tabName) => {
//     this.setState({ selectedTab: tabName });
//   };
//   handleBulkOperationStaus = (e) => {
//     console.log("status" + e.target.value);
//     this.setState({ statusTarget: e.target.value });
//   };
//   handleDailyoperation = (e) => {
//     console.log("Datastatus" + e.target.value);
//     this.setState({ targeted: e.target.value });
//   };
//   handleDailyoperation1 = (val) => {
//     console.log("Datastatus" + val.target.value);
//     this.setState({ datainput: val.target.value });
//   };
//   handleDailyoperation2 = (e) => {
//     console.log("Datastatus" + e.target.value);
//     this.setState({ datainput: e.target.value });
//   };
//   handleExportData = async () => {
//     this.setState({ exporting: true });
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     try {
//       const response = await fetch(
//         "https://api.lotusppc.com/BookShelf/Export",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             globalFilters: {
//               searchText: this.state.searchKeyFilter,
//               advancedFilters: this.state.globalFilterFromData,
//             },
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to export data");
//         this.setState({ exporting: false });
//       }
//       this.setState({ exporting: false });
//       const blob = await response.blob();
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.style.display = "none";
//       a.href = url;
//       a.download = "BookShelf exported_data.csv";
//       document.body.appendChild(a);
//       a.click();
//       window.URL.revokeObjectURL(url);

//       // Handle success
//       console.log("Export successful");
//     } catch (error) {
//       console.error("Error exporting data:", error);
//     }
//   };

//   handleAdds = async () => {
//     console.log("handleAdds=" + this.state.selectedRowid);

//     // if (!this.state.selectedRowid) {
//     //   return toast.info("Select rows to update");
//     // }
//     // if (!this.state.setTagacos) {
//     //   return toast.info("Select the Asin values");
//     // }
//     if (this.state.selectedRowid.length > 0 && this.state.setTagacos)
//       console.log("dataghsts=" + this.state.selectedRowid);
//     // this.setState({ setTagacos: "" });

//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     const response = await fetch(
//       "https://api.lotusppc.com/BookShelf/Bulkoperation",

//       {
//         method: "PATCH",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: AuthToken,
//         },
//         body: JSON.stringify({
//           asin: this.state.selectedRowid,
//           tags: [],
//           targetAcos: parseInt(this.state.setTagacos),
//           addAdExpenses: null,
//         }),
//         // abc
//       }
//     );
//     const responceData = await response;
//     console.log("best    =s    pathh ", responceData);
//     if (responceData.status) {
//       let result = responceData;
//       toast("Record updated successfully");

//       // fetchData();
//     } else {
//       toast("Unable to update");
//     }
//     this.setState({ apiLoading: !this.state.apiLoading });
//     this.setState({ setTagacos: "" });
//   };

//   handleAddExpenses = async () => {
//     console.log("handleAddExpenses" + this.state.selectedRowid);
//     if (this.state.selectedRowid.length > 0 && this.state.setexpenses) {
//       // this.setState({ setTagacos: "" });
//       // if (!this.state.setexpenses) {
//       //   return toast.info("Select rows to update");
//       // }
//       // if (!this.state.selectedRowid) {
//       //   return toast.info("Select the Asin values");
//       // }

//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       const response = await fetch(
//         "https://api.lotusppc.com/BookShelf/Bulkoperation",
//         //"https://api.lotusppc.com/BookShelf/" + clickedId + "/Tag",
//         {
//           method: "PATCH",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             asin: this.state.selectedRowid,
//             tags: [],
//             targetAcos: null,
//             addAdExpenses: parseInt(this.state.setexpenses),
//           }),
//           // abc
//         }
//       );
//       const responceData = await response;
//       console.log("best    =s    pathh ", responceData);
//       if (responceData.status) {
//         let result = responceData;
//         toast("Record Updated Successfully");
//         this.setState({ apiLoading: false });
//         // fetchData();
//       } else {
//         toast("Unable to update");
//       }
//     }
//   };
//   handleAddTags = async () => {
//     console.log("hi");

//     console.log("handleAddTags" + this.state.selectedRowid);
//     this.setState({ tagDataLoading: true });
//     if (this.state.selectedRowid.length > 0 && this.state.SetTagsAdd) {
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       const response = await fetch(
//         "https://api.lotusppc.com/BookShelf/Bulkoperation",
//         //"https://api.lotusppc.com/BookShelf/" + clickedId + "/Tag",
//         {
//           method: "PATCH",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             asin: this.state.selectedRowid,
//             tags: [this.state.SetTagsAdd],
//             targetAcos: null,
//             addAdExpenses: null,
//           }),
//           // abc
//         }
//       );
//       const responceData = await response;
//       console.log("best    =s    pathh ", responceData);
//       if (responceData.status) {
//         toast("Record Updated Successfully");
//         // fetchData();
//         this.setState({ apiLoading: false });
//       } else {
//         toast("Unable to update");
//       }
//       this.setState({ DataLoading: false });
//     }
//   };
//   manageHandler = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     const response = await fetch("https://api.lotusppc.com/Tag", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//     });
//     const responceData = await response.json();
//     const initialTags = responceData.result.map((tag) => ({
//       tag: tag.tag,
//       initialTag: tag.tag,
//       count: tag.count,
//     }));
//     this.setState({ manageTags: initialTags });
//     this.setState({ DataLoading: false });
//   };
//   removeManageTag = async (item: any, index: any) => {
//     var element: any = document.getElementById(index);
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     const response = await fetch("https://api.lotusppc.com/Tag", {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//       body: JSON.stringify({
//         tag: item,
//       }),
//     });

//     if (!response.ok) {
//       toast("Unable to Delete");
//     } else {
//       element.parentNode.removeChild(element);
//       toast("Record Deleted Successfully");
//     }

//     // this.setState({ apiLoading: true });
//     this.setState({ isLoading: !this.state.isLoading });
//   };

//   // remove = async (item: any, index: any) => {
//   //   confirmAlert({
//   //     customUI: ({ onClose }) => (
//   //       <div className="custom-ui">
//   //         <div className="logo-del-tag">
//   //           <img
//   //             src={NotificationIcon}
//   //             alt="Notification Icon"
//   //             style={{ width: "80px", height: "80px" }}
//   //           />
//   //         </div>
//   //         <h1>Are you sure you want to delete "{item}" tag?</h1>
//   //         <p>If you delete this, you will no longer be able to restore it.</p>
//   //         <div className="buttons-container">
//   //           <button onClick={onClose}>Cancel</button>
//   //           <button
//   //             onClick={() => {
//   //               this.removeManageTag(item, index);
//   //               onClose();
//   //             }}
//   //           >
//   //             Delete
//   //           </button>
//   //         </div>
//   //       </div>
//   //     ),
//   //   });
//   // };
//   refresh = () => {
//     this.setState({ isLoading: !this.state.isLoading });
//   };
//   handleEditTags = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;
//     const requestBody = {
//       item: this.state.manageTags.map(({ initialTag, tag }) => ({
//         oldTag: initialTag,
//         tags: tag,
//       })),
//     };
//     const response = await fetch("https://api.lotusppc.com/Tag", {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: AuthToken,
//       },
//       body: JSON.stringify({
//         item: requestBody.item,
//       }),
//     });

//     if (!response.ok) {
//       toast("Unable to Updated");
//     } else {
//       toast("Record Updated Successfully");
//       this.setState({ isLoading: !this.state.isLoading });
//     }
//   };
//   render() {
//     const manageTagsListEdit = this.state.manageTags.map((item, index) => {
//       return (
//         <div className="row-item-container" id={index.toString()}>
//           <div className="d-flex align-items-start">
//             <input
//               type="text"
//               className="form-control mb-1"
//               onChange={(e) => handleTagInputChange(e, index)}
//               value={item.tag}
//             />
//             <button
//               type="button"
//               className="btn btn-box"
//               onClick={() => this.removeManageTag(item.tag, index)}
//             >
//               <i className="fa fa-trash" aria-hidden="true"></i>
//             </button>
//           </div>{" "}
//           <p className="m-0 mb-3 p-0">this tag is {item.count}x Used</p>
//         </div>
//       );
//     });
//     const handleTagInputChange = (e, index) => {
//       const updatedTagsList = [...this.state.manageTags];
//       const newValue = e.target.value;
//       updatedTagsList[index].tag = newValue;
//       this.setState({ manageTags: updatedTagsList });
//     };

//     return (
//       <DashboardLayout>
//         {this.state.isConnection ? (
//           <React.Fragment>
//             <div className="main-cont-header bookself-container">
//               <Row className="page-header">
//                 <Col>
//                   <div className="main-con-page-title-container">
//                     <div className="title">
//                       <h3 className="page-title">Products</h3>
//                     </div>
//                   </div>
//                 </Col>
//                 {/* <Col className="text-end last-sync justify-content-end">
//           <span className="last-sync">
//             <div>Last App Sync</div>
//             <div className="lastspan">
//               {" "}
//               {this.state.lastUpdatedDate || "Not available"}
//             </div>
//           </span>
//         </Col> */}
//               </Row>
//             </div>
//             <div className="main-content-container">
//               <hr />
//               <div className="dashboard-container padding-lr-30 bookshelf-sticky">
//                 <Row>
//                   <Col md={7}>
//                     <Row>
//                       <Col md={4}>
//                         <form>
//                           <div className="search-filter-container d-flex align-items-center">
//                             <i className="fa fa-search search-icon-fa"></i>
//                             <input
//                               type="text"
//                               placeholder="Search"
//                               id="globalSearch"
//                               name="globalSearch"
//                               onKeyDown={this.handleKeyDown}
//                             />
//                           </div>
//                         </form>
//                       </Col>
//                       <Col md={4}>
//                         <div className="bulk-operation-container width-200 mx-2">
//                           <div className="dropdownContent width-200">
//                             <p
//                               id="dropdownMenuButton"
//                               data-bs-toggle="dropdown"
//                               aria-expanded="false"
//                             >
//                               Bulk Operation
//                               <i
//                                 className="fa fa-angle-down down-arrow-right mx-2"
//                                 aria-hidden="true"
//                               ></i>
//                               {/* <i
//                         className="fa fa-angle-up up-arrow-right"
//                         aria-hidden="true"
//                       ></i> */}
//                             </p>
//                             <ul
//                               className="dropdown-menu shadow rounded"
//                               aria-labelledby="dropdownMenuButton"
//                             >
//                               {/* <li>Add Tags</li> */}
//                               <li>
//                                 <button
//                                   className="changePassword btnBackgoundRemove disableInput"
//                                   data-bs-toggle="modal"
//                                   data-bs-target="#addTagModel1"
//                                 >
//                                   {" "}
//                                   Add Tags
//                                 </button>
//                               </li>
//                               <li>
//                                 <button
//                                   className="changePassword btnBackgoundRemove disableInput"
//                                   data-bs-toggle="modal"
//                                   data-bs-target="#addTagModel2"
//                                 >
//                                   {" "}
//                                   Set Target ACoS
//                                 </button>
//                               </li>
//                               <li>
//                                 <button
//                                   className="changePassword btnBackgoundRemove disableInput"
//                                   data-bs-toggle="modal"
//                                   data-bs-target="#addTagModel3"
//                                 >
//                                   {" "}
//                                   Add Expenses
//                                 </button>
//                               </li>
//                             </ul>
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                   </Col>
//                   <Col>
//                     <div className="filter-container">
//                       <Row>
//                         <Col md={12} className="padding-lr-10">
//                           <div className="filter-item filter-link-container dropdownContent">
//                             <p
//                               className="menu bookself"
//                               id="dropdownMenuButton1"
//                               data-bs-toggle="dropdown"
//                               data-bs-auto-close="outside"
//                               aria-expanded="false"
//                               data-bs-display="static"
//                             >
//                               <i>
//                                 <img src={FilterIcon} alt="filter icon" />
//                               </i>
//                               <span>Filter</span>
//                               <i
//                                 className="fa fa-angle-down down-arrow-right"
//                                 aria-hidden="true"
//                               ></i>
//                             </p>
//                             <div
//                               className="dropdown-menu dropdown-menu-lg-end condition-filter"
//                               aria-labelledby="dropdownMenuButton1"
//                             >
//                               <ConditionFilter
//                                 parentCallback={this.handleCallback}
//                                 dropdownData={this.state.dropdownDatas}
//                                 metaData={this.state.metaData}
//                               />
//                             </div>
//                           </div>

//                           <div className="filter-item column-link-container dropdownContent">
//                             <p
//                               className="menu bookself"
//                               id="dropdownMenuButton2"
//                               data-bs-toggle="dropdown"
//                               data-bs-auto-close="outside"
//                               aria-expanded="false"
//                               data-bs-display="static"
//                             >
//                               <i>
//                                 <img src={PauseIcon} alt="filter icon" />
//                               </i>
//                               <span>Columns</span>
//                               <i
//                                 className="fa fa-angle-down down-arrow-right"
//                                 aria-hidden="true"
//                               ></i>
//                             </p>
//                             <div
//                               className="dropdown-menu dropdown-menu-lg-start"
//                               aria-labelledby="dropdownMenuButton1"
//                             >
//                               <form>
//                                 <div className="set-max-height-400">
//                                   <ColumnFilter
//                                     columnList={this.state.dropdownDatas}
//                                     hideColumn={this.updateHideColumn}
//                                   />
//                                 </div>
//                                 <hr />
//                                 <div className="footer">
//                                   <button
//                                     type="button"
//                                     className="btn btn-default"
//                                     onClick={this.cancelDropdown}
//                                   >
//                                     Cancel
//                                   </button>
//                                   <button
//                                     type="button"
//                                     className="btn btn-primary"
//                                     onClick={this.applyColumns}
//                                   >
//                                     Apply
//                                   </button>
//                                 </div>
//                               </form>
//                             </div>
//                           </div>
//                           <div className="filter-item export-link-container">
//                             <p>
//                               <i>
//                                 <img
//                                   src={ExportIcon}
//                                   alt="filter icon"
//                                   onClick={this.handleExportData}
//                                 />
//                               </i>
//                               {this.state.exporting ? (
//                                 <span>Exporting...</span>
//                               ) : (
//                                 <span onClick={this.handleExportData}>
//                                   Export
//                                 </span>
//                               )}
//                             </p>
//                           </div>
//                           <div
//                             className="filter-item export-link-container last"
//                             onClick={this.manageHandler}
//                             data-bs-toggle="modal"
//                             data-bs-target="#addTagModel4"
//                           >
//                             <p>
//                               <i>
//                                 <img src={TagIcon} alt="filter icon" />
//                               </i>
//                               <span>Manage Tags</span>
//                             </p>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//               <div className="dashboard-container padding-lr-30">
//                 <Row>
//                   <Col>
//                     <BookshelfTable
//                       checkBox={false}
//                       tabName="Marketplaces"
//                       filterData={this.state.globalFilterFromData}
//                       searchKey={this.state.searchKeyFilter}
//                       filterDateRange={this.state.globalFilterDateRange}
//                       perPage={this.state.perPage}
//                       currPage={this.state.currPage}
//                       parentCallback={this.handleCallbackTotalData}
//                       parentSlectedRowCallBack={this.handleSelectedRowCallBack}
//                       reloadTableData={this.state.apiLoading}
//                       daatareloading={this.state.DataLoading}
//                       hideColumn={this.state.hiddenColumns}
//                       isLoading={this.state.isLoading}
//                     />
//                   </Col>
//                 </Row>
//               </div>
//               <div className="custom-table-footer mx-3">
//                 <Row>
//                   <Col md={5}>
//                     <form className="table-footer-left">
//                       <span>Show </span>
//                       <label>
//                         <select
//                           className="form-select"
//                           defaultValue={this.state.perPage}
//                           onChange={(event) => this.applyDataLength(event)}
//                         >
//                           <option value="50">50</option>
//                           <option value="100">100</option>
//                           <option value="150">150</option>
//                           <option value="200">200</option>
//                         </select>
//                       </label>
//                       <span> of {this.state.total} total entries</span>
//                     </form>
//                   </Col>
//                   <Col md={7}>
//                     <div className="table-footer-right">
//                       <Stack spacing={2}>
//                         <Pagination
//                           count={this.state.lastPage}
//                           variant="outlined"
//                           shape="rounded"
//                           onChange={this.handleChange}
//                         />
//                       </Stack>
//                     </div>
//                   </Col>
//                 </Row>

//                 <div
//                   className="modal fade modal-width-540"
//                   id="addTagModel2"
//                   aria-hidden="true"
//                   aria-labelledby="exampleModalToggleLabel3"
//                 >
//                   <div className="modal-dialog ">
//                     <div className="modal-content addTagModel">
//                       <Row className="addTagInputContainer">
//                         <Col className="addTagModelContainers">
//                           <h4 style={{ fontSize: "24px" }}>Set Target AcoS</h4>
//                           <p>
//                             You will add this target acos to the{" "}
//                             {this.state.countSelected} product
//                             {this.state.countSelected > 1 ? <>s</> : <></>} that
//                             you have selected.
//                           </p>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="enter target AcoS here"
//                             onChange={(e) =>
//                               this.setState({ setTagacos: e.target.value })
//                             }
//                             value={this.state.setTagacos}
//                           />
//                           <div className="addTagBtnContainer">
//                             <button
//                               className="addTagCancell"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             >
//                               Cancel
//                             </button>
//                             <button
//                               // abc
//                               className="addTagSave"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                               //onClick={patchTagData}
//                               onClick={this.handleAdds}
//                             >
//                               Save
//                             </button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className="modal fade modal-width-540"
//                   id="addTagModel3"
//                   aria-hidden="true"
//                   aria-labelledby="exampleModalToggleLabel3"
//                 >
//                   <div className="modal-dialog ">
//                     <div className="modal-content addTagModel">
//                       <Row className="addTagInputContainer">
//                         <Col className="addTagModelContainers">
//                           <h4>Add Expenses</h4>
//                           <p>
//                             You will add this expenses to the{" "}
//                             {this.state.countSelected} product
//                             {this.state.countSelected > 1 ? <>s</> : <></>} that
//                             you have selected.
//                           </p>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="enter expenses here"
//                             // onChange={(e) => tagInputValue(e)}
//                             onChange={(e) =>
//                               this.setState({ setexpenses: e.target.value })
//                             }
//                             value={this.state.setexpenses}
//                           />
//                           <div className="addTagBtnContainer">
//                             <button
//                               className="addTagCancell"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             >
//                               Cancel
//                             </button>
//                             <button
//                               // abc
//                               className="addTagSave"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                               onClick={this.handleAddExpenses}
//                             >
//                               Save
//                             </button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className="modal fade modal-width-540"
//                   id="addTagModel1"
//                   aria-hidden="true"
//                   aria-labelledby="exampleModalToggleLabel3"
//                 >
//                   <div className="modal-dialog ">
//                     <div className="modal-content addTagModel">
//                       <Row className="addTagInputContainer ">
//                         <Col className="addTagModelContainers">
//                           <h4> Add Tag</h4>
//                           <p>
//                             You will add this tag to the{" "}
//                             {this.state.countSelected} product
//                             {this.state.countSelected > 1 ? <>s</> : <></>} that
//                             you have selected.
//                           </p>
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Add tag here"
//                             // onChange={(e) => tagInputValue(e)}
//                             onChange={(e) =>
//                               this.setState({ SetTagsAdd: e.target.value })
//                             }
//                             value={this.state.SetTagsAdd}
//                           />
//                           <div className="addTagBtnContainer">
//                             <button
//                               className="addTagCancell"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             >
//                               Cancel
//                             </button>
//                             <button
//                               // abc
//                               className="addTagSave"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                               onClick={this.handleAddTags}
//                             >
//                               Save
//                             </button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className="modal fade addTagModel4"
//                   id="addTagModel4"
//                   aria-hidden="true"
//                   aria-labelledby="exampleModalToggleLabel3"
//                 >
//                   <div className="modal-dialog ">
//                     <div className="modal-content addTagModels">
//                       <Row className="addTagInputContainer">
//                         <Col className="addTagModelContainers">
//                           <h4>Manage Tags</h4>
//                           <p>You can edit and delete all tags here.</p>
//                           {manageTagsListEdit}
//                           <div className="addTagBtnContainer">
//                             <button
//                               className="addTagCancell"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             >
//                               Cancel
//                             </button>
//                             <button
//                               className="addTagSave"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                               onClick={this.handleEditTags}
//                             >
//                               Save Changes
//                             </button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </React.Fragment>
//         ) : (
//           <div className="no-products">
//             <div>
//               <img
//                 src={require("../../assets/images/icons/shopping.png")}
//                 alt="empty"
//               />
//               <div>
//                 <Link to={`/my-account/amazon-connection`} className="amazon-connection-btn">
//                   <i className="connection"></i>
//                   connect your amazon seller or vendor account
//                 </Link>
//               </div>
//             </div>
//           </div>
//         )}
//       </DashboardLayout>
//     );
//   }
// }

// export default Bookshelfs;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Row, Col } from "react-bootstrap";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ColumnFilter from "../../components/Filters/cloumn-filter";
import ConditionFilter from "../../components/Filters/condition-filter";

import BookshelfTable from "../../components/CusDataTable/bookshelf-table";
import FilterIcon from "../../assets/images/icons/filter-icon.svg";
import PauseIcon from "../../assets/images/icons/pause.svg";
import ExportIcon from "../../assets/images/icons/export.svg";
import TagIcon from "../../assets/images/icons/tag-icon.svg";
import Moment from "moment";
import { toast } from "react-toastify";
import MasterDataService from "../../services/MasterDataService";
import { confirmAlert } from "react-confirm-alert";
import NotificationIcon from "../../assets/images/icons/tag-del-notification.png";
import { Link } from "react-router-dom";

class Bookshelfs extends Component {
  filterData: any = [];
  state = {
    manageTagsInitial: [],
    isConnection: false,
    // isLoading: false,
    manageTags: [{ tag: "", initialTag: "", count: "" }],
    manageTagsEdit: [],
    globalFilterFromData: [],
    searchKeyFilter: "",
    globalFilterDateRange: [],
    filterOptionData: [],
    paginationList: [],
    perPage: 50,
    total: 0,
    currPage: 1,
    lastPage: 0,
    nextPage: 0,
    prevPage: 0,
    dropdownDatas: [],
    metaData: [],
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    selectedTab: "Status",
    statusTarget: "",
    targeted: "",
    datainput: "",
    selectedRowid: [],
    marketplace: "",
    selctedunit: "",
    setTagacos: "",

    setexpenses: "",
    SetTagsAdd: "",
    apiLoading: true,
    DataLoading: true,
    countSelected: 0,
    tablePageRefresh: false,
    hideColumn: [],
    hiddenColumns: [],
    exporting: false,
  };

  constructor(props) {
    super(props);
    this.onChangeDateRangeSubmitGlobal =
      this.onChangeDateRangeSubmitGlobal.bind(this);
    console.log(props);

    // this.child = React.createRef();
  }
  componentDidMount() {
    this.getMetaData();
    this.manageHandler();
  }

  handleNvEnter = (event) => {
    console.log("Nv Enter:", event);
  };
  handleCallback = (childData) => {
    console.log(childData);

    this.setState({ globalFilterFromData: childData });
    this.filterData = childData;
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.setState({ searchKeyFilter: event.target.value });
      event.preventDefault();
    }
  };
  updateHideColumn = (hideArr) => {
    console.log("Book Shelf Hide Column: ", hideArr);
    this.setState({ hideColumn: hideArr });
  };
  applyColumns = () => {
    this.setState({ hiddenColumns: this.state.hideColumn });
    this.cancelDropdown();
  };

  onChangeDateRangeSubmitGlobal(e) {
    console.log(Moment(e[0]).format("YYYY-MM-DD"));
    console.log(e[1]);
    let dateRange = e;
    if (dateRange.length > 0) {
      this.setState({ globalFilterDateRange: dateRange });
    }
  }

  applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    this.setState({ perPage: parseInt(e.target.value) });
  };

  handleCallbackTotalData = (childData) => {
    console.log("Child Bookshelf Table data: ", childData);
    this.setState({ total: childData.total });
    this.setState({ currPage: childData.currPage });
    this.setState({ lastPage: childData.lastPage });
    this.setState({ nextPage: childData.nextPage });
    this.setState({ perPage: childData.perPage });
    this.setState({ prevPage: childData.prevPage });
    this.setState({ dropdownDatas: childData.dropdownDatas });
  };
  handleSelectedRowCallBack = (rowIds) => {
    console.log("Selected Row Id's: ", rowIds);
    this.setState({ selectedRowid: rowIds });
    console.log("Selected Row Id's" + rowIds.length);
    this.setState({ countSelected: rowIds.length });
  };

  applyPagination = (e, pageNo: any) => {
    console.log("pagination no: ", pageNo);
    this.setState({ currPage: pageNo });
  };
  handleChange = (event, value: number) => {
    this.setState({ currPage: value });
  };

  getMetaData = async () => {
    try {
      const metaData = await MasterDataService.getMetaData();
      this.setState({ metaData: metaData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    for (let i = 0; i < eleRm.length; i++) {
      eleRm[i].classList.remove("show");
    }
  };

  selectTab = (tabName) => {
    this.setState({ selectedTab: tabName });
  };
  handleBulkOperationStaus = (e) => {
    console.log("status" + e.target.value);
    this.setState({ statusTarget: e.target.value });
  };
  handleDailyoperation = (e) => {
    console.log("Datastatus" + e.target.value);
    this.setState({ targeted: e.target.value });
  };
  handleDailyoperation1 = (val) => {
    console.log("Datastatus" + val.target.value);
    this.setState({ datainput: val.target.value });
  };
  handleDailyoperation2 = (e) => {
    console.log("Datastatus" + e.target.value);
    this.setState({ datainput: e.target.value });
  };
  handleExportData = async () => {
    this.setState({ exporting: true });
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    try {
      const response = await fetch(
        "https://api.lotusppc.com/BookShelf/Export",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            globalFilters: {
              searchText: this.state.searchKeyFilter,
              advancedFilters: this.state.globalFilterFromData,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to export data");
        this.setState({ exporting: false });
      }
      this.setState({ exporting: false });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "BookShelf exported_data.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // Handle success
      console.log("Export successful");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  handleAdds = async () => {
    console.log("handleAdds=" + this.state.selectedRowid);

    // if (!this.state.selectedRowid) {
    //   return toast.info("Select rows to update");
    // }
    // if (!this.state.setTagacos) {
    //   return toast.info("Select the Asin values");
    // }
    if (this.state.selectedRowid.length > 0 && this.state.setTagacos)
      console.log("dataghsts=" + this.state.selectedRowid);
    // this.setState({ setTagacos: "" });

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch(
      "https://api.lotusppc.com/BookShelf/Bulkoperation",

      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({
          asin: this.state.selectedRowid,
          tags: [],
          targetAcos: parseInt(this.state.setTagacos),
          addAdExpenses: null,
        }),
        // abc
      }
    );
    const responceData = await response;
    console.log("best    =s    pathh ", responceData);
    if (responceData.status) {
      let result = responceData;
      toast("Record updated successfully");

      // fetchData();
    } else {
      toast("Unable to update");
    }
    this.setState({ apiLoading: !this.state.apiLoading });
    this.setState({ setTagacos: "" });
  };

  handleAddExpenses = async () => {
    console.log("handleAddExpenses" + this.state.selectedRowid);
    if (this.state.selectedRowid.length > 0 && this.state.setexpenses) {
      // this.setState({ setTagacos: "" });
      // if (!this.state.setexpenses) {
      //   return toast.info("Select rows to update");
      // }
      // if (!this.state.selectedRowid) {
      //   return toast.info("Select the Asin values");
      // }

      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      const response = await fetch(
        "https://api.lotusppc.com/BookShelf/Bulkoperation",
        //"https://api.lotusppc.com/BookShelf/" + clickedId + "/Tag",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            asin: this.state.selectedRowid,
            tags: [],
            targetAcos: null,
            addAdExpenses: parseInt(this.state.setexpenses),
          }),
          // abc
        }
      );
      const responceData = await response;
      console.log("best    =s    pathh ", responceData);
      if (responceData.status) {
        let result = responceData;
        toast("Record Updated Successfully");
        this.setState({ apiLoading: false });
        // fetchData();
      } else {
        toast("Unable to update");
      }
    }
  };
  handleAddTags = async () => {
    console.log("hi");

    console.log("handleAddTags" + this.state.selectedRowid);
    this.setState({ tagDataLoading: true });
    if (this.state.selectedRowid.length > 0 && this.state.SetTagsAdd) {
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      const response = await fetch(
        "https://api.lotusppc.com/BookShelf/Bulkoperation",
        //"https://api.lotusppc.com/BookShelf/" + clickedId + "/Tag",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({
            asin: this.state.selectedRowid,
            tags: [this.state.SetTagsAdd],
            targetAcos: null,
            addAdExpenses: null,
          }),
          // abc
        }
      );
      const responceData = await response;
      console.log("best    =s    pathh ", responceData);
      if (responceData.status) {
        toast("Record Updated Successfully");
        // fetchData();
        this.setState({ apiLoading: false });
      } else {
        toast("Unable to update");
      }
      this.setState({ DataLoading: false });
    }
  };
  manageHandler = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch("https://api.lotusppc.com/Tag", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });
    const responceData = await response.json();
    const initialTags = responceData.result.map((tag) => ({
      tag: tag.tag,
      initialTag: tag.tag,
      count: tag.count,
    }));
    this.setState({ manageTags: initialTags });
    this.setState({ DataLoading: false });
  };
  removeManageTag = async (item: any, index: any) => {
    var element: any = document.getElementById(index);
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const response = await fetch("https://api.lotusppc.com/Tag", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        tag: item,
      }),
    });

    if (!response.ok) {
      toast("Unable to Delete");
    } else {
      element.parentNode.removeChild(element);
      toast("Record Deleted Successfully");
    }

    // this.setState({ apiLoading: true });
    // this.setState({ isLoading: !this.state.isLoading });
  };

  // remove = async (item: any, index: any) => {
  //   confirmAlert({
  //     customUI: ({ onClose }) => (
  //       <div className="custom-ui">
  //         <div className="logo-del-tag">
  //           <img
  //             src={NotificationIcon}
  //             alt="Notification Icon"
  //             style={{ width: "80px", height: "80px" }}
  //           />
  //         </div>
  //         <h1>Are you sure you want to delete "{item}" tag?</h1>
  //         <p>If you delete this, you will no longer be able to restore it.</p>
  //         <div className="buttons-container">
  //           <button onClick={onClose}>Cancel</button>
  //           <button
  //             onClick={() => {
  //               this.removeManageTag(item, index);
  //               onClose();
  //             }}
  //           >
  //             Delete
  //           </button>
  //         </div>
  //       </div>
  //     ),
  //   });
  // };
  refresh = () => {
    // this.setState({ isLoading: !this.state.isLoading });
  };
  handleEditTags = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    const requestBody = {
      item: this.state.manageTags.map(({ initialTag, tag }) => ({
        oldTag: initialTag,
        tags: tag,
      })),
    };
    const response = await fetch("https://api.lotusppc.com/Tag", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        item: requestBody.item,
      }),
    });

    if (!response.ok) {
      toast("Unable to Updated");
    } else {
      toast("Record Updated Successfully");
      // this.setState({ isLoading: !this.state.isLoading });
    }
  };
  render() {
    const manageTagsListEdit = this.state.manageTags.map((item, index) => {
      return (
        <div className="row-item-container" id={index.toString()}>
          <div className="d-flex align-items-start">
            <input
              type="text"
              className="form-control mb-1"
              onChange={(e) => handleTagInputChange(e, index)}
              value={item.tag}
            />
            <button
              type="button"
              className="btn btn-box"
              onClick={() => this.removeManageTag(item.tag, index)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>{" "}
          <p className="m-0 mb-3 p-0">this tag is {item.count}x Used</p>
        </div>
      );
    });
    const handleTagInputChange = (e, index) => {
      const updatedTagsList = [...this.state.manageTags];
      const newValue = e.target.value;
      updatedTagsList[index].tag = newValue;
      this.setState({ manageTags: updatedTagsList });
    };

    return (
      <DashboardLayout>
        <React.Fragment>
          <div className="main-cont-header bookself-container">
            <Row className="page-header">
              <Col>
                <div className="main-con-page-title-container">
                  <div className="title">
                    <h3 className="page-title">Products</h3>
                  </div>
                </div>
              </Col>
              {/* <Col className="text-end last-sync justify-content-end">
          <span className="last-sync">
            <div>Last App Sync</div>
            <div className="lastspan">
              {" "}
              {this.state.lastUpdatedDate || "Not available"}
            </div>
          </span>
        </Col> */}
            </Row>
          </div>
          <div className="main-content-container">
            <hr />
            <div className="dashboard-container padding-lr-30 bookshelf-sticky">
              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={4}>
                      <form>
                        <div className="search-filter-container d-flex align-items-center">
                          <i className="fa fa-search search-icon-fa"></i>
                          <input
                            type="text"
                            placeholder="Search"
                            id="globalSearch"
                            name="globalSearch"
                            onKeyDown={this.handleKeyDown}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col md={4}>
                      <div className="bulk-operation-container width-200 mx-2">
                        <div className="dropdownContent width-200">
                          <p
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Bulk Operation
                            <i
                              className="fa fa-angle-down down-arrow-right mx-2"
                              aria-hidden="true"
                            ></i>
                            {/* <i
                        className="fa fa-angle-up up-arrow-right"
                        aria-hidden="true"
                      ></i> */}
                          </p>
                          <ul
                            className="dropdown-menu shadow rounded"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {/* <li>Add Tags</li> */}
                            <li>
                              <button
                                className="changePassword btnBackgoundRemove disableInput"
                                data-bs-toggle="modal"
                                data-bs-target="#addTagModel1"
                              >
                                {" "}
                                Add Tags
                              </button>
                            </li>
                            <li>
                              <button
                                className="changePassword btnBackgoundRemove disableInput"
                                data-bs-toggle="modal"
                                data-bs-target="#addTagModel2"
                              >
                                {" "}
                                Set Target ACoS
                              </button>
                            </li>
                            <li>
                              <button
                                className="changePassword btnBackgoundRemove disableInput"
                                data-bs-toggle="modal"
                                data-bs-target="#addTagModel3"
                              >
                                {" "}
                                Add Expenses
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div className="filter-container">
                    <Row>
                      <Col md={12} className="padding-lr-10">
                        <div className="filter-item filter-link-container dropdownContent">
                          <p
                            className="menu bookself"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            data-bs-display="static"
                          >
                            <i>
                              <img src={FilterIcon} alt="filter icon" />
                            </i>
                            <span>Filter</span>
                            <i
                              className="fa fa-angle-down down-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className="dropdown-menu dropdown-menu-lg-end condition-filter"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <ConditionFilter
                              parentCallback={this.handleCallback}
                              dropdownData={this.state.dropdownDatas}
                              metaData={this.state.metaData}
                            />
                          </div>
                        </div>

                        <div className="filter-item column-link-container dropdownContent">
                          <p
                            className="menu bookself"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            data-bs-display="static"
                          >
                            <i>
                              <img src={PauseIcon} alt="filter icon" />
                            </i>
                            <span>Columns</span>
                            <i
                              className="fa fa-angle-down down-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <div
                            className="dropdown-menu dropdown-menu-lg-start"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <form>
                              <div className="set-max-height-400">
                                <ColumnFilter
                                  columnList={this.state.dropdownDatas}
                                  hideColumn={this.updateHideColumn}
                                />
                              </div>
                              <hr />
                              <div className="footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={this.cancelDropdown}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.applyColumns}
                                >
                                  Apply
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="filter-item export-link-container">
                          <p>
                            <i>
                              <img
                                src={ExportIcon}
                                alt="filter icon"
                                onClick={this.handleExportData}
                              />
                            </i>
                            {this.state.exporting ? (
                              <span>Exporting...</span>
                            ) : (
                              <span onClick={this.handleExportData}>
                                Export
                              </span>
                            )}
                          </p>
                        </div>
                        <div
                          className="filter-item export-link-container last"
                          onClick={this.manageHandler}
                          data-bs-toggle="modal"
                          data-bs-target="#addTagModel4"
                        >
                          <p>
                            <i>
                              <img src={TagIcon} alt="filter icon" />
                            </i>
                            <span>Manage Tags</span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dashboard-container padding-lr-30">
              <Row>
                <Col>
                  <BookshelfTable
                    checkBox={false}
                    tabName="Marketplaces"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    perPage={this.state.perPage}
                    currPage={this.state.currPage}
                    parentCallback={this.handleCallbackTotalData}
                    parentSlectedRowCallBack={this.handleSelectedRowCallBack}
                    reloadTableData={this.state.apiLoading}
                    daatareloading={this.state.DataLoading}
                    hideColumn={this.state.hiddenColumns}
                    // isLoading={this.state.isLoading}
                  />
                </Col>
              </Row>
            </div>
            <div className="custom-table-footer mx-3">
              <Row>
                <Col md={5}>
                  <form className="table-footer-left">
                    <span>Show </span>
                    <label>
                      <select
                        className="form-select"
                        defaultValue={this.state.perPage}
                        onChange={(event) => this.applyDataLength(event)}
                      >
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </select>
                    </label>
                    <span> of {this.state.total} total entries</span>
                  </form>
                </Col>
                <Col md={7}>
                  <div className="table-footer-right">
                    <Stack spacing={2}>
                      <Pagination
                        count={this.state.lastPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={this.handleChange}
                      />
                    </Stack>
                  </div>
                </Col>
              </Row>

              <div
                className="modal fade modal-width-540"
                id="addTagModel2"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel3"
              >
                <div className="modal-dialog ">
                  <div className="modal-content addTagModel">
                    <Row className="addTagInputContainer">
                      <Col className="addTagModelContainers">
                        <h4 style={{ fontSize: "24px" }}>Set Target AcoS</h4>
                        <p>
                          You will add this target acos to the{" "}
                          {this.state.countSelected} product
                          {this.state.countSelected > 1 ? <>s</> : <></>} that
                          you have selected.
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="enter target AcoS here"
                          onChange={(e) =>
                            this.setState({ setTagacos: e.target.value })
                          }
                          value={this.state.setTagacos}
                        />
                        <div className="addTagBtnContainer">
                          <button
                            className="addTagCancell"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                          <button
                            // abc
                            className="addTagSave"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            //onClick={patchTagData}
                            onClick={this.handleAdds}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div
                className="modal fade modal-width-540"
                id="addTagModel3"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel3"
              >
                <div className="modal-dialog ">
                  <div className="modal-content addTagModel">
                    <Row className="addTagInputContainer">
                      <Col className="addTagModelContainers">
                        <h4>Add Expenses</h4>
                        <p>
                          You will add this expenses to the{" "}
                          {this.state.countSelected} product
                          {this.state.countSelected > 1 ? <>s</> : <></>} that
                          you have selected.
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="enter expenses here"
                          // onChange={(e) => tagInputValue(e)}
                          onChange={(e) =>
                            this.setState({ setexpenses: e.target.value })
                          }
                          value={this.state.setexpenses}
                        />
                        <div className="addTagBtnContainer">
                          <button
                            className="addTagCancell"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                          <button
                            // abc
                            className="addTagSave"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={this.handleAddExpenses}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div
                className="modal fade modal-width-540"
                id="addTagModel1"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel3"
              >
                <div className="modal-dialog ">
                  <div className="modal-content addTagModel">
                    <Row className="addTagInputContainer ">
                      <Col className="addTagModelContainers">
                        <h4> Add Tag</h4>
                        <p>
                          You will add this tag to the{" "}
                          {this.state.countSelected} product
                          {this.state.countSelected > 1 ? <>s</> : <></>} that
                          you have selected.
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add tag here"
                          // onChange={(e) => tagInputValue(e)}
                          onChange={(e) =>
                            this.setState({ SetTagsAdd: e.target.value })
                          }
                          value={this.state.SetTagsAdd}
                        />
                        <div className="addTagBtnContainer">
                          <button
                            className="addTagCancell"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                          <button
                            // abc
                            className="addTagSave"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={this.handleAddTags}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div
                className="modal fade addTagModel4"
                id="addTagModel4"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel3"
              >
                <div className="modal-dialog ">
                  <div className="modal-content addTagModels">
                    <Row className="addTagInputContainer">
                      <Col className="addTagModelContainers">
                        <h4>Manage Tags</h4>
                        <p>You can edit and delete all tags here.</p>
                        {manageTagsListEdit}
                        <div className="addTagBtnContainer">
                          <button
                            className="addTagCancell"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                          <button
                            className="addTagSave"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={this.handleEditTags}
                          >
                            Save Changes
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

        {/* <div className="no-products">
            <div>
              <img
                src={require("../../assets/images/icons/shopping.png")}
                alt="empty"
              />
              <div>
                <Link to={`/my-account/amazon-connection`} className="amazon-connection-btn">
                  <i className="connection"></i>
                  connect your amazon seller or vendor account
                </Link>
              </div>
            </div>
          </div> */}
      </DashboardLayout>
    );
  }
}

export default Bookshelfs;
