import React, { useState, useEffect } from "react";
import "./ProfileSelector.css";

const ProfileSelector = ({ dataSource, value, onChange }) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState(value);
  const [isDataSource, setIsDataSource] = useState(dataSource);
  let subscribe = localStorage.getItem("planName");
  //   const handleSelectAll = (event) => {
  //     const isChecked = event.target.checked;
  //     const updatedSelectedItems = isChecked ? [...dataSource] : [];
  //     setSelectedItems(updatedSelectedItems);
  //     onChange(updatedSelectedItems);
  //     setIsCheckAll(isChecked);
  //   };

  const handleOnChange = (event, id) => {
    let updatedArray;

    setIsDataSource((prev) => {
      const updated = prev.map((item) => {
        if (item.profileId === id) {
          return { ...item, isChecked: !item.isChecked };
        } else if (item.isChecked && subscribe === "FreePlan") {
          return { ...item, isChecked: false };
        }
        // console.log();
        return item;
      });
      updatedArray = updated;
      return updatedArray;
    });

    setSelectedItems((prev) => {
      const updatedItems = updatedArray?.filter(
        (item, index) => item.isChecked
      );
      onChange(updatedItems);
      return updatedItems;
    });
  };

  return (
    <div className="ProfileSelector">
      <table className="simple-table">
        <thead>
          <tr>
            <th>
              {/* <input
                type="checkbox"
                className="form-check-input"
                name="profile-selector-select-all"
                checked={isCheckAll}
                onChange={handleSelectAll}
              /> */}
            </th>
            <th>Country</th>
            <th>Id</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {isDataSource &&
            isDataSource.length > 0 &&
            isDataSource.map((item, i) => (
              <tr key={"profile-selector-item-" + i}>
                <td>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="profile-selector-checkbox"
                    checked={item.isChecked}
                    onChange={(event) => handleOnChange(event, item.profileId)}
                  />
                </td>
                <td>
                  <span>
                    <img
                      className="image-circle-20"
                      src={`../assets/flags/${item.countryCode}.png`}
                      alt={item.countryCode}
                    />
                  </span>
                  <span>{item.countryCode}</span>
                </td>
                <td>
                  <span>{item?.profileId}</span>
                </td>
                <td>
                  <span>{item.type}</span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProfileSelector;
