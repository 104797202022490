import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useLocation } from "react-router";
import axios from "axios";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const OuthConnection: React.FunctionComponent = () => {
  const location = useLocation();
  console.log(location);

  const region = localStorage.getItem("regionName");
  const [error, setError] = useState(false);
  const [id, setId] = useState("");
  const [spError, setSpError] = useState(false);
  const [vpError, setVpError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Show, setShow] = useState("");
  const userId = localStorage.getItem("userId")
  const usertoken = localStorage.getItem("userToken")

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const scope = searchParams.get("scope");
    const state = searchParams.get("state");
    if (state) {
      const part = state.split("_");
      const Id = part[1];
      setId(Id);
      console.log(Id);
      localStorage.setItem("userId" , Id);
    } else {
      console.warn("State is null or undefined.");
    }

    if (code !== null && state !== null && scope !== null) {
      setShow("Ads");
      console.log(region);
      const callbackData = {
        scope: scope,
        oAuthCode: code,
        userId: userId,
        state: state,
        name: region || "",
      };
      axios
        .post(
          "https://api.lotusppc.com/api/Amazon/AdsCallback",
          callbackData,
          {
            headers: {
                Authorization: `Bearer ${usertoken}`
              }
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            setError(false);
            setLoading(false);
            const profile = res.data.result;
            localStorage.setItem("adsConnected" , "true");
        
            toast("You have successfully connected")
          } else {
            notification.warning({
              message: "Warning",
              description: res.data.message,
              duration: 1,
            });
            setError(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          notification.error({
            message: "Error",
            description: "Something went wrong",
            duration: 1,
          });

          console.log(error);
        });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong",
        duration: 1,
      });

      setShow("Nothing");
      setLoading(false);
    }
  }, [location.search]);
  return (
    <DashboardLayout>
      <div
        className="oauth-container main-cont-header align-content-center"
        style={{ height: "70vh" }}
      >
        {Show === "Ads" ? (
          <div className="text-center">
            {!error ? (
              <h4>
                You have successfully connected your Amazon PPC (ADS) account.
              </h4>
            ) : (
              <h4>
                Your Amazon PPC (ADS) account is not connected. Please retry.
              </h4>
            )}
            <div>
              Go to the Connection Page to select your profiles for report sync {" "}
              <Link to={"/my-account/amazon-connection"}>Click here</Link>
            </div>
          </div>
        ) : Show === "Nothing" ? (
          <div className="text-center">
            <h4>Somthing went wrong. Please retry.</h4>
            <div>
              Go to the Connection Page to select your profiles for report sync {" "}
              <Link to={"/my-account/amazon-connection"}>Click here</Link>
            </div>
          </div>
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default OuthConnection;
